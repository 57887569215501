import { docIcon } from "assets/icons";
import React from "react";
import "./DocUploader.scss";
const DocUpload = ({ fileName, onChange, accept }) => {
  return (
    <>
      <div className="doc_uploader">
        <label htmlFor="file_uploader">
          <input accept={accept} type="file" id="file_uploader" onChange={onChange} />
          <img src={docIcon} alt="" />

          <h5>{fileName ?? "Upload your resume"}</h5>
        </label>
      </div>
    </>
  );
};

export default DocUpload;
