import { callIcon, whatsAppIcon } from "assets/icons";
import { cancelImg } from "assets/images";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const DeactivatedAccount = () => {

  return (
    <>
      <div className="auth otp deactivated">
        <img src={cancelImg} alt="" className="cancelIcon" />
        <div className="bottom_content">
        <h3>your account is deactivated</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur. Morbi nunc arcu morbi mollis
            duis at dignissim. Sit in libero nunc volutpat sagittis tortor.
          </p>
          <ul className="get_touch">
            <li>
              <a href="tel:+916542345">
                <img src={callIcon} alt="" />
              </a>
            </li>
            <li>
              <Link to="" target="_blank">
                <img src={whatsAppIcon} alt="" />
              </Link>
            </li>
          </ul>
          <ul>
            <li>
              <span>720778331</span>{" "}
            </li>
            <li>
              <span>720778331</span>{" "}
            </li>
            <li>
              <span>720778331</span>{" "}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default DeactivatedAccount;
