import { cartIcon, logoutIcon, notificationIcon } from "assets/icons";
import LogoutModal from "components/modals/LogoutModal";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { AuthService, ClassService } from "services";
import useSnackbar from "hooks/useSnackbar";
import useUser from "hooks/useUser";
import {
  updateCart,
  updateCartCount,
  updatePurchasedClass,
  useAppSelector,
} from "../../redux";
import { CartService } from "services/cart";
import { useDispatch } from "react-redux";
import Notification from "./Notification";

const Avatar = () => {
  const { logout } = useUser();
  const dispatch = useDispatch();
  const { snackbar } = useSnackbar();
  const { getCartList } = CartService();
  const { getMyClassList } = ClassService();
  const { logout: logoutApi } = AuthService(false);
  const [logoutState, setLogoutState] = useState(false);
  const cartState = useAppSelector((state) => state.cartSlice);

  // cart list
  useQuery({
    queryKey: ["cart-list", cartState.totalCartItemCount],
    queryFn: async () =>
      getCartList({
        token: localStorage.getItem("fg_token"),
      }),
    onSuccess: (data) => {
      const classList = data?.data?.cartList?.map((ele) => ele.classDetail);
      dispatch(updateCartCount(data?.data?.total));
      dispatch(updateCart(classList));
    },
  });

  // My Class List
  useQuery({
    queryKey: ["my-class", cartState.totalCartItemCount],
    enabled: cartState.totalCartItemCount === 0,
    queryFn: async () =>
      getMyClassList({
        token: localStorage.getItem("fg_token"),
      }),
    onSuccess: (data) => {
      const list = data.data.data?.map((ele) => ele._id);
      dispatch(updatePurchasedClass(list));
    },
  });

  const handleLogout = useMutation(
    () => {
      return logoutApi({ token: localStorage.getItem("fg_token") });
    },
    {
      onSuccess: (data) => {
        snackbar(data?.message || "", "info");
        logout();
        setLogoutState(false);
      },
      onError: (err) => {
        snackbar(err.data.message || "");
      },
    }
  );

  return (
    <>
      <div className="avatar">
        <Link to="/cart" className="avatar_button  _card">
          <span className="_card_content">
            <img src={cartIcon} alt="" />
          </span>
          {cartState?.totalCartItemCount ? (
            <span className="item_count">{cartState?.totalCartItemCount}</span>
          ) : null}
        </Link>
        <Notification />
        <button
          type="button"
          className="logout_button"
          onClick={() => setLogoutState(true)}
        >
          <span>
            Logout <img src={logoutIcon} alt="" />
          </span>
        </button>
      </div>

      <LogoutModal
        show={logoutState}
        handleClose={() => setLogoutState(false)}
        action={handleLogout.mutate}
      />
    </>
  );
};

export default Avatar;
