import Joi from "joi";

export const donation80G = Joi.object({
    amount: Joi
        .string()
        .required()
        .label("Amount")
        .messages({
            "string.empty": "{{#label}} is required"
        }),
    name: Joi
        .string()
        .required()
        .label("Name")
        .messages({
            "string.empty": "{{#label}} is required"
        }),
    phone: Joi
        .string()
        .required()
        .min(10)
        .label("Phone Number")
        .messages({
            "string.empty": "{{#label}} is required"
        }),
    email: Joi
        .string()
        .required()
        .email({ tlds: { allow: false } })
        .label("Email")
        .messages({
            "string.empty": "{{#label}} is required"
        }),
    panAadharPassport: Joi
        .string()
        .required()
        .label("PAN/Aadhaar/Passport")
        .messages({
            "string.empty": "{{#label}} is required"
        }),
    address: Joi
        .string()
        .required()
        .label("Address")
        .messages({
            "string.empty": "{{#label}} is required"
        }),
    pincode: Joi
        .string()
        .required()
        .label("Address")
        .messages({
            "string.empty": "{{#label}} is required"
        })
});

export const donationAnonymous = Joi.object({
    amount: Joi
        .string()
        .required()
        .label("Amount")
        .messages({
            "string.empty": "{{#label}} is required"
        })
});