import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [], totalCartItemCount: 0 }

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    updateCart: (state, action) => {
      return { ...state, data: action.payload }
    },
    updateCartCount: (state, action) => {
      return { ...state, totalCartItemCount: action.payload }
    },
    removeCart: (state, action) => {
      const filtered = state.data.filter(ele => ele?._id !== action.payload);
      return { ...state, data: filtered }
    }
  },
})

// Action creators are generated for each case reducer function
export const { updateCart, removeCart, updateCartCount } = cartSlice.actions;

export default cartSlice.reducer;