import HttpService from "services/http";

const AuthService = () => {
    const { httpRequest, httpFormRequest } = HttpService();

    const sendOtp = (payload) => new Promise((resolve, reject) => {
        httpRequest("POST", "user/verify/sendOtp", payload)
            .then(resolve)
            .catch(reject);
    });

    const resendOtp = (payload) => new Promise((resolve, reject) => {
        httpRequest("POST", "user/verify/resendOtp", payload)
            .then(resolve)
            .catch(reject);
    });

    const verifyOtp = (payload) => new Promise((resolve, reject) => {
        httpRequest("POST", "user/verify/verifyOtp", payload)
            .then(resolve)
            .catch(reject);
    });

    const logout = (payload) => new Promise((resolve, reject) => {
        httpRequest("POST", "user/auth/logout", payload)
            .then(resolve)
            .catch(reject);
    });

    const register = (payload) => new Promise((resolve, reject) => {
        httpFormRequest("POST", "user/auth/register", payload)
            .then(resolve)
            .catch(reject);
    });

    return { sendOtp, resendOtp, verifyOtp, register, logout };
}

export { AuthService };