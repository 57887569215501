import React, { useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import { FaRegTrashAlt } from "react-icons/fa";
import { HiDotsHorizontal } from "react-icons/hi";
import {
  IoBanOutline,
  IoStarOutline,
  IoTrashOutline,
  IoWarningOutline,
} from "react-icons/io5";
import { MdReply } from "react-icons/md";
import useUser from "hooks/useUser";
import Text from "./Text";
import { timeFormat } from "utils/common";

const Message = ({
  message = {},
  selectedClass = {},
  deleteHandler,
  reportHandler,
}) => {
  const { user, isTeacher } = useUser();

  const classNameHandler = useMemo(() => {
    if (user?._id === message?.senderId?._id) {
      return "chat_sender";
    }

    return "chat_receive";
  }, [user?._id, message?.senderId?._id]);

  return (
    <div className={`${classNameHandler}_wrap`}>
      <div className={`${classNameHandler}`}>
        {/* text message */}
        {message.mediaType === "text" && (
          <Text {...{ message, selectedClass }} />
        )}

        <Dropdown className="custom_dropdown">
          <Dropdown.Toggle id="dropdown-basic">
            <HiDotsHorizontal />
          </Dropdown.Toggle>
          {/* teacher action btn */}

          {isTeacher && (
            <>
              {classNameHandler === "chat_receive" && (
                <Dropdown.Menu>
                  <div className="_card">
                    <div className="_card_content">
                      <Dropdown.Item as={"span"}>
                        <IoStarOutline />
                        Important
                      </Dropdown.Item>
                      <Dropdown.Item as={"span"}>
                        <MdReply />
                        Reply
                      </Dropdown.Item>
                      <Dropdown.Item as={"span"}>
                        <IoBanOutline />
                        Block Student
                      </Dropdown.Item>
                      <Dropdown.Item as={"span"}>
                        <IoTrashOutline />
                        Delete
                      </Dropdown.Item>
                    </div>
                  </div>
                </Dropdown.Menu>
              )}

              {classNameHandler === "chat_sender" && (
                <Dropdown.Menu>
                  <div className="_card">
                    <div className="_card_content">
                      <Dropdown.Item as={"span"}>
                        <IoStarOutline />
                        Important
                      </Dropdown.Item>
                      <Dropdown.Item as={"span"}>
                        <MdReply />
                        Reply
                      </Dropdown.Item>
                      <Dropdown.Item as={"span"}>
                        <IoBanOutline />
                        Block Student
                      </Dropdown.Item>
                      <Dropdown.Item as={"span"}>
                        <IoTrashOutline />
                        Delete
                      </Dropdown.Item>
                    </div>
                  </div>
                </Dropdown.Menu>
              )}
            </>
          )}

          {/* student action btn */}
          {!isTeacher && (
            <>
              {classNameHandler === "chat_receive" && (
                <Dropdown.Menu>
                  <div className="_card">
                    <div className="_card_content">
                      <Dropdown.Item as={"span"} onClick={reportHandler}>
                        <IoWarningOutline />
                        Report
                      </Dropdown.Item>
                      <Dropdown.Item as={"span"}>
                        <MdReply />
                        Reply
                      </Dropdown.Item>
                    </div>
                  </div>
                </Dropdown.Menu>
              )}

              {classNameHandler === "chat_sender" && (
                <Dropdown.Menu>
                  <div className="_card">
                    <div className="_card_content">
                      <Dropdown.Item as={"span"}>
                        <MdReply />
                        Reply
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={"span"}
                        className="delete"
                        onClick={deleteHandler}
                      >
                        <FaRegTrashAlt />
                        Delete
                      </Dropdown.Item>
                    </div>
                  </div>
                </Dropdown.Menu>
              )}
            </>
          )}

          <span className="time">{timeFormat(message?.createdAt)}</span>
        </Dropdown>
      </div>
    </div>
  );
};

export default Message;
