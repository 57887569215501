import { studentWorksImg } from "assets/images";
import NoData from "components/no-data";
import React from "react";
import { FaCaretRight } from "react-icons/fa";
import { useQuery } from "react-query";
import Slider from "react-slick";
import { SettingService } from "services";
import { isDateBefore25 } from "utils/helper";

const settings2 = {
  dots: true,
  arrows: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 2000,
  infinite: false,
};

const StudentWorks = ({ isLoggedIn }) => {
  const { getStudentWorks } = SettingService();

  const { data } = useQuery({
    queryKey: ["student_work"],
    queryFn: async () =>
      getStudentWorks({
        token: localStorage.getItem("fg_token"),
        type: "work",
      }),
  });

  const workList = data?.data?.list || [];
  console.log("🌹 ~ StudentWorks ~ workList:", workList);

  return (
    <>
      {!isDateBefore25() && isLoggedIn ? null : (
        <div className="student_works spacing">
          <div className="container">
            <div className="page_title">
              <h3>Students Works</h3>
            </div>

            <div className="student_works_slider">
              {workList?.length > 0 ? (
                <Slider {...settings2}>
                  {workList?.map((it) => {
                    return (
                      <div className="item" key={it.id}>
                        <img src={it?.work?.imageUrl} alt="" />
                        <button
                          type="button"
                          className="video-play-button"
                          onClick={() => window.open(it?.work?.youtubeUrl)}
                        >
                          <FaCaretRight />
                        </button>

                        <span className="name_tag">{it?.work?.name}</span>
                        <span className="subject_tag">{it?.work?.subject}</span>
                      </div>
                    );
                  })}
                </Slider>
              ) : (
                <NoData desc="No Student Works Available" />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StudentWorks;
