import { joiResolver } from "@hookform/resolvers/joi";
import { nameIcon } from "assets/icons";
import { addProfilePic, ProfileImg } from "assets/images";
import { BackButton, Input } from "components/form";
import Helpline from "components/helpline/Helpline";
import CustomModal from "components/modals/CustomModal";
import DeleteModal from "components/modals/DeleteModal";
import NoData from "components/no-data";
import Skeleton from "components/skeleton";
import useSnackbar from "hooks/useSnackbar";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { ManageProfileService } from "services";
import { generateFormDataPayload } from "utils/helper";
import { updateManageProfileValidation } from "validations";

const ManageProfile = () => {
  const { snackbar } = useSnackbar();
  const { getUserProfile, UpdateUserProfile } = ManageProfileService();
  const [editProfileModal, setEditProfileModal] = useState({
    type: "add",
    _id: undefined,
    show: false,
    deleteWarning: false,
  });
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(updateManageProfileValidation),
    defaultValues: {
      name: "",
    },
  });
  const profile = watch("profile");

  const {
    data: profileList,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["manage-profile-list"],
    queryFn: async () =>
      getUserProfile({
        token: localStorage.getItem("fg_token"),
      }),
  });

  const updateProfile = useMutation(
    (data) => {
      const payload = generateFormDataPayload(
        {
          ...data,
          id: editProfileModal._id,
          token: localStorage.getItem("fg_token"),
        },
        "profile"
      );
      return UpdateUserProfile(payload);
    },
    {
      onSuccess: () => {
        setEditProfileModal((prev) => ({
          ...prev,
          show: false,
          _id: undefined,
        }));
        refetch();
        reset();
      },
      onError: (err) => {
        snackbar(err?.data?.message || "");
      },
    }
  );

  return (
    <>
      <div className="manage_profile spacing">
        <div className="inner_container">
          <div className="_card">
            <div className="_card_content">
              <BackButton />
              <h5 className="sub_title">Manage Profile</h5>

              <div className="profile_card_wrap">
                {isLoading ? (
                  <>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton button />
                  </>
                ) : profileList?.data?.list?.length ? (
                  profileList?.data?.list?.map((profile) => {
                    return (
                      <div className="item">
                        <div className="_card">
                          <div className="_card_content">
                            <div className="profile_img">
                              <img
                                src={
                                  profile?.profile?.length
                                    ? profile?.profile
                                    : ProfileImg
                                }
                                alt="profile image"
                              />
                            </div>
                            <h5>{profile.name}</h5>

                            <div className="button_wrap">
                              <button
                                type="button"
                                className="button delete"
                                onClick={() => {
                                  setEditProfileModal((prev) => ({
                                    ...prev,
                                    deleteWarning: true,
                                    _id: profile._id,
                                  }));
                                }}
                              >
                                Delete
                              </button>
                              <button
                                type="button"
                                className="button edit"
                                onClick={() => {
                                  setEditProfileModal((prev) => ({
                                    ...prev,
                                    show: true,
                                    type: "update",
                                    _id: profile._id,
                                  }));
                                  setValue("name", profile.name);
                                  setValue("profile", profile.profile);
                                }}
                              >
                                Edit{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <NoData desc={"Please add user profile"} />
                )}
              </div>

              <div className="button_wrap add_profile_button">
                <button
                  type="button"
                  className="button"
                  onClick={() =>
                    setEditProfileModal((prev) => ({
                      ...prev,
                      show: true,
                      type: "add",
                    }))
                  }
                >
                  Add Profile
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Helpline />

      {/* Add Profile */}

      <CustomModal
        show={editProfileModal.show}
        handleClose={() => {
          setEditProfileModal((prev) => ({
            ...prev,
            show: false,
            _id: undefined,
          }));
          reset();
        }}
        className={"editProfileModal auth singUp "}
      >
        <h4>
          {editProfileModal?.type === "update" ? "Edit Profile" : "Add Profile"}
        </h4>
        <form onSubmit={handleSubmit(updateProfile.mutate)}>
          <div>
            <div className="profilePic">
              <img
                src={
                  profile?.length
                    ? profile
                    : profile?.name
                    ? URL.createObjectURL(profile)
                    : ProfileImg
                }
                alt=""
                className="profile"
              />
              <label htmlFor="upload_profile" type="button">
                <img src={addProfilePic} alt="" className="add_img" />
                <input
                  type="file"
                  id="upload_profile"
                  className="uploadInput"
                  onChange={(e) => setValue("profile", e.target.files[0])}
                />
              </label>
              <span
                className={`${
                  errors.profile?.message ? "info-feedback" : "invalid-feedback"
                }`}
              >
                {errors.profile?.message}
              </span>
            </div>
          </div>
          <div className="form_field ">
            <Input
              placeholder=" Enter Name"
              name="name"
              control={control}
              error={errors.name?.message}
            />
            <span className="country">
              <img src={nameIcon} alt="" />
            </span>
          </div>

          <div className="button_wrap button_center">
            <button
              type="submit"
              className="button"
              disabled={updateProfile.isLoading}
            >
              Save
            </button>
          </div>
        </form>
      </CustomModal>

      {/* Delete Profile  */}
      <DeleteModal
        show={editProfileModal.deleteWarning}
        content="Are you sure you want to delete this Profile?"
        handleClose={() =>
          setEditProfileModal((prev) => ({
            ...prev,
            deleteWarning: false,
            _id: undefined,
          }))
        }
        handleSubmit={() => {
          updateProfile.mutate({ isDelete: true });
          setEditProfileModal((prev) => ({
            ...prev,
            deleteWarning: false,
            _id: undefined,
          }));
        }}
      />
    </>
  );
};

export default ManageProfile;
