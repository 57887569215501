import { BackButton } from "components/form";
import React from "react";
import "./TermsCondition.scss";
import Helpline from "components/helpline/Helpline";

const AboutUs = () => {
  return (
    <>
      <div className="notification_list spacing">
        <div className="inner_container">
          <div className="_card">
            <div className="_card_content">
              <div className="cms_content">
                <h5 className="sub_title">About Us</h5>
                <div className="cms_content">
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Scelerisque enim
                    nisi vivam us facilisis. Mauris fermentum dictumst viverra
                    adipiscing at. Hac e gestas praesent facilisi quis lobortis
                    nam dolor risus. Rutrum inter dum sit etiam in quam amet sed
                    integer.
                  </p>
                  <ul>
                    <li>
                      Lorem ipsum dolor sit amet consectetur. Scelerisque enim
                      nisi vivam
                    </li>
                    <li>
                      us facilisis. Mauris fermentum dictumst viverra adipiscing
                      at. Hac e
                    </li>
                    <li>
                      gestas praesent facilisi quis lobortis nam dolor risus.
                      Rutrum inter
                    </li>
                    <li>dum sit etiam in quam amet sed integer.</li>
                  </ul>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Scelerisque enim
                    nisi vivam us facilisis. Mauris fermentum dictumst viverra
                    adipiscing at. Hac e gestas praesent facilisi quis lobortis
                    nam dolor risus. Rutrum inter dum sit etiam in quam amet sed
                    integer.
                  </p>
                  <ul>
                    <li>
                      Lorem ipsum dolor sit amet consectetur. Scelerisque enim
                      nisi vivam
                    </li>
                    <li>
                      us facilisis. Mauris fermentum dictumst viverra adipiscing
                      at. Hac e
                    </li>
                    <li>
                      gestas praesent facilisi quis lobortis nam dolor risus.
                      Rutrum inter
                    </li>
                    <li>dum sit etiam in quam amet sed integer.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Helpline />
    </>
  );
};

export default AboutUs;
