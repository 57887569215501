import HttpService from "services/http";

const AttendanceService = () => {
    const { httpRequest, httpFormRequest } = HttpService();

    const getAttendanceList = (search) => new Promise((resolve, reject) => {
        httpRequest("GET", "user/class/attendance_list", {}, search)
            .then(resolve)
            .catch(reject);
    });

    const addAttendance = (payload) => new Promise((resolve, reject) => {
        httpFormRequest("POST", "user/class/mark_attendance", payload)
            .then(resolve)
            .catch(reject);
    });

    return { getAttendanceList, addAttendance };
}

export { AttendanceService };