import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { UserService } from "services";
import { BackButton, Input } from "components/form";
import { generateFormDataPayload } from "utils/helper";
import Helpline from "components/helpline/Helpline";
import useSnackbar from "hooks/useSnackbar";
import useUser from "hooks/useUser";
import { allowAlphaNumSomeSpecial, provideOnlyNumbers } from "utils/common";

const AddTeacherAddress = () => {
  const { user, setUser } = useUser();
  const { snackbar } = useSnackbar();
  const navigate = useNavigate();
  const { updateTeacherDetail } = UserService();

  const [formData, setFormData] = useState({
    street: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
  });

  const [error, setError] = useState({});

  const updateAddress = useMutation(
    (data) => {
      return updateTeacherDetail(
        generateFormDataPayload({
          ...data,
          type: "address",
          token: localStorage.getItem("fg_token"),
        })
      );
    },
    {
      onSuccess: (data, requestPayload) => {
        const { ...other } = requestPayload;
        setUser({
          ...user,
          address: {
            ...user?.address,
            ...other,
          },
        });
        snackbar(data?.message || "", "info");
        navigate("/profile/teacher/address");
      },
      onError: (err) => {
        snackbar(err.data.message || "");
      },
    }
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (["street", "state", "city"].includes(name)) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: allowAlphaNumSomeSpecial(value),
      }));
    } else if (name === "postalCode") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: provideOnlyNumbers(value),
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  // validation
  const handleValidation = () => {
    let errorMessage = {};
    let status = true;

    if (!formData.street) {
      errorMessage[`street`] = `"Street" is required`;
      status = false;
    }

    if (!formData.city) {
      errorMessage[`city`] = `"City" is required`;
      status = false;
    }

    if (!formData.state) {
      errorMessage[`state`] = `"State" is required`;
      status = false;
    }

    if (!formData.postalCode) {
      errorMessage[`postalCode`] = `"Postal Code" is required`;
      status = false;
    }

    setError(errorMessage);
    return status;
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      updateAddress.mutate(formData);
    }
  };

  useEffect(() => {
    const detailExist = user?.address?.state;
    if (user && detailExist) {
      const { state, city, country, street, postalCode } = user?.address;
      setFormData({
        street,
        city,
        state,
        country,
        postalCode,
      });
    }
  }, [user]);

  return (
    <>
      <div className="teacher_my_profile ">
        <div className="inner_container spacing">
          <div className="_card">
            <form className="_card_content" onSubmit={formSubmitHandler}>
              <BackButton />
              <div className="sub_title">Add Address</div>

              <div className="edit_from_details">
                <div className="form_field">
                  <Input
                    type="input"
                    className="input2"
                    placeholder="Enter Street"
                    label="Street"
                    name="street"
                    value={formData.street}
                    onChange={handleInputChange}
                    error={error.street}
                  />
                </div>
                <div className="form_field">
                  <Input
                    type="input"
                    className="input2"
                    placeholder="Enter City"
                    label="City"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    error={error.city}
                  />
                </div>
                <div className="form_field">
                  <Input
                    type="input"
                    className="input2"
                    placeholder="Enter State"
                    label="State"
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                    error={error.state}
                  />
                </div>
                <div className="form_field">
                  <Input
                    type="number"
                    maxLength={6}
                    className="input2"
                    placeholder="Enter Postal Code"
                    label="Postal Code"
                    name="postalCode"
                    value={formData.postalCode}
                    onChange={handleInputChange}
                    error={error.postalCode}
                  />
                </div>
                {/* <div className="form_field">
                  <Input
                    type="input"
                    className="input2"
                    placeholder="Enter Country"
                    label="Country"
                    name="country"
                    control={control}
                    error={errors.country?.message}
                  />
                </div> */}
                <div className="button_wrap mb-3">
                  <button
                    type="submit"
                    className="button"
                    disabled={updateAddress.isLoading}
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <Helpline />
      </div>
    </>
  );
};

export default AddTeacherAddress;
