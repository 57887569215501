import {
  facebookIcon,
  instagramIcon,
  phone_callingGIF,
  whatsAppIcon,
  youtubeIcon,
} from "assets/icons";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setHelplineData } from "../../redux/slices/helpline";
import { SettingService } from "services";

const Helpline = () => {
  const { getTeachers } = SettingService();

  const dispatch = useDispatch();

  const { data: teachersData } = useQuery({
    queryKey: ["teachers"],
    queryFn: async () =>
      getTeachers({
        token: localStorage.getItem("fg_token"),
      }),
  });

  const helplineList = teachersData?.data?.helpline || [];

  useEffect(() => {
    dispatch(setHelplineData(teachersData?.data?.helpline));
  }, [teachersData?.data?.helpline, dispatch]);
  return (
    <>
      <div className="helpline spacing">
        <div className="container">
          <div className="page_title">
            <h3>Need Help, Call To HelpLine</h3>
          </div>

          <div className="helpline_content">
            <p className="contact_info">
              <img src={phone_callingGIF} alt="" />
              {helplineList?.map((it) => {
                return <span key={it._id}>{it.number} </span>;
              })}
            </p>

            <ul className="social_icons">
              <li>
                <Link to="" target="_blank">
                  <img src={whatsAppIcon} alt="" />
                </Link>
              </li>
              <li>
                <Link to="" target="_blank">
                  <img src={youtubeIcon} alt="" />
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.youtube.com/@freegurukul?sub_confirmation=1"
                  target="_blank"
                >
                  <img src={facebookIcon} alt="" />
                </Link>
              </li>
              <li>
                <Link to="https://www.facebook.com/freegurukul" target="_blank">
                  <img src={instagramIcon} alt="" />
                </Link>
              </li>

              {/* <li>
                <Link to="" target="_blank">
                  <img src={likeIcon} alt="" />
                </Link>
              </li> */}
              {/* <li>
                <Link to="https://www.instagram.com/freegurukulofficial" target="_blank">
                  <img src={shareIcon} alt="" />
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Helpline;
