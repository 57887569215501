import { ProfileImg } from "assets/images";
import { BackButton, LoadingButton } from "components/form";
import "../MyClasses.scss";
import React, { useEffect, useState } from "react";
import Helpline from "components/helpline/Helpline";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { generateFormDataPayload } from "utils/helper";
import { ManageProfileService } from "services";
import Skeleton from "components/skeleton";
import { useLocation, useNavigate } from "react-router-dom";

const ChangeProfileCourse = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const classId = location.state?.classId;
  const profileId = location.state?.profileId;
  const [selectedProfile, setSelectedProfile] = useState(null);
  const { getUserProfile, changeUserProfile } = ManageProfileService();

  useEffect(() => {
    if (!classId) {
      navigate("/my-classes");
    } else {
      setSelectedProfile(profileId);
    }
  }, []);

  const { data: profileList, isLoading } = useQuery({
    queryKey: ["manage-profile-lis"],
    queryFn: async () =>
      getUserProfile({
        token: localStorage.getItem("fg_token")
      })
  });

  const addToCartMutation = useMutation(() => {
    const payload = generateFormDataPayload({
      profileId: selectedProfile,
      classId: location.state?.classId,
      token: localStorage.getItem("fg_token")
    })
    return changeUserProfile(payload);
  }, {
    onSuccess: (data) => {
      navigate("/my-classes");
    }
  });

  return (
    <>
      <div className="change_profile_course spacing">
        <div className="inner_container">
          <div className="_card">
            <div className="_card_content">
              <BackButton />

              <div className="sub_title">Choose profile for course</div>

              <div className="course_list">
                <ul>
                  <li>
                    <b className="_profile">Profile</b>
                    <b>Name</b>
                    <b></b>
                  </li>

                  {
                    isLoading
                      ?
                      <>
                        <Skeleton />
                        <Skeleton />
                      </>
                      :
                      profileList?.data?.list?.map((item, index) => {
                        return (
                          <li key={index}>
                            <label>
                              <img src={ProfileImg} alt="" />
                              <span className="_name">{item.name}</span>
                              <input type="radio" checked={(selectedProfile === item._id)} onChange={(e) => setSelectedProfile(item._id)} />
                            </label>
                          </li>
                        )
                      })
                  }

                </ul>
                <div className="button_wrap">
                <LoadingButton  loading={addToCartMutation.isLoading} onClick={() => addToCartMutation.mutate()}>Change Profile</LoadingButton>
              </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>

      <Helpline />
    </>
  );
};

export default ChangeProfileCourse;
