import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  helplineList: [],
  loading: false,
};

export const helplineSlice = createSlice({
  name: "helpline",
  initialState,
  reducers: {
    setHelplineData: (state, action) => {
      state.helplineList = action.payload || initialState.helplineList;
    },
  },
});

export const { setHelplineData } = helplineSlice.actions;

export default helplineSlice.reducer;
