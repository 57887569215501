import React, { useEffect, useState } from "react";

import "../../TeacherProfile.scss";
import useUser from "hooks/useUser";
import { ProfileImg } from "assets/images";
import {
  aboutMeIcon,
  bankIcon,
  contactIcon,
  ExperienceIcon,
  FormBatchesIcon,
  FormExperienceIcon,
  FormStudentsIcon,
  FormTailoringIcon,
  hideEye,
  IdIcon,
  laptopIcon,
  phoneHolderIcon,
  postalAddressIcon,
  powerBackupIcon,
  profileApplyJobIcon,
  profileCertificatesIcon,
  profileDeleteIcon,
  profileEditIcon,
  profileHelpIcon,
  profileLogoutIcon,
  profileManageIcon,
  profileTransactionIcon,
  showEye,
  signatureIcon,
  studentsIcon,
  teacherTagIcon,
  teacherTrophyIcon,
  verifiedIcon,
  whiteBoardIcon,
  wifiIcon,
} from "assets/icons";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import Helpline from "components/helpline/Helpline";
import CustomModal from "components/modals/CustomModal";
import { Button, Input, Select, SwitchButton } from "components/form";
import { Link } from "react-router-dom";
import GradientCircularProgressbar from "components/CircularProgressbar/GradientCircularProgressbar ";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { SettingService, UserService } from "services";
import { generateFormDataPayload } from "utils/helper";
import useSnackbar from "hooks/useSnackbar";

const TeacherMyProfile = () => {
  const { user, logout } = useUser();
  const { snackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { updateTeacherDetail } = UserService();
  const { getReasonsList, deleteAccount } = SettingService();
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [showMobile, setShowMobile] = useState(false);
  const [state, setState] = useState({
    reason: "",
    reasonError: "",
    showReasonField: false,
    reasonFieldText: "",
    reasonFieldTextError: "",
  });
  const percentage = 50;

  useEffect(() => {
    if (!deleteAccountModal) {
      setState((prev) => ({ ...prev, reason: "", reasonError: "" }));
    }
  }, [deleteAccountModal]);

  const { data: reasonsList } = useQuery({
    queryKey: ["reasons-list"],
    queryFn: async () =>
      getReasonsList({
        token: localStorage.getItem("fg_token"),
      }),
  });

  const deleteAccountMutation = useMutation(
    () => {
      if (!state.reason.length) {
        setState((prev) => ({ ...prev, reasonError: "Please select reason" }));
        return;
      }

      if (state.reason === "other" && !state.reasonFieldText.length) {
        setState((prev) => ({
          ...prev,
          reasonFieldTextError: "Please enter reason",
        }));
        return;
      }

      const payload = {
        reason: state.reason === "other" ? state.reasonFieldText : state.reason,
        token: localStorage.getItem("fg_token"),
      };
      return deleteAccount(payload);
    },
    {
      onSuccess: (data) => {
        if (data) {
          logout();
          snackbar(data?.message || "");
        }
      },
      onError: (err) => {
        snackbar(err?.data?.message || "");
      },
    }
  );

  const updateDetail = useMutation(
    (data) => {
      const payload = generateFormDataPayload({
        ...user.associateDevices,
        ...data,
        type: "associate",
        token: localStorage.getItem("fg_token"),
      });
      return updateTeacherDetail(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("userProfile");
      },
    }
  );

  return (
    <>
      <div className="teacher_my_profile">
        <div className="student_Access container">
          <div className="my_profile spacing">
            <div className="profile_image spacing">
              <img
                src={user.profile.length ? user.profile : ProfileImg}
                alt="User Name"
                className="user_img"
              />
              <img src={teacherTrophyIcon} alt="" className="teacher_trophy" />
              <p>
                <img src={teacherTagIcon} alt="" className="teacher_tag" />{" "}
                <b>{user?.name}</b> <img src={verifiedIcon} alt="" />
              </p>
            </div>

            <div className="content_wrap">
              <div className="content_left">
                <div className="contact_info">
                  <ul>
                    <li>
                      <img src={IdIcon} alt="" />
                      <p className="_card">
                        <span>
                          {user.roles?.includes("student")
                            ? `${user?.studentNumber}/${user?.teacherNumber}`
                            : ""}
                        </span>
                      </p>
                    </li>
                    <li>
                      <img src={contactIcon} alt="" />
                      <p className="_card">
                        <span>
                          {showMobile
                            ? user?.mobile
                            : "xxxxxxx" + user?.mobile.slice(-2)}
                          <img
                            src={showMobile ? showEye : hideEye}
                            alt=""
                            onClick={() => setShowMobile((prev) => !prev)}
                          />
                        </span>
                      </p>
                    </li>
                    <li>
                      <img src={FormTailoringIcon} alt="" />
                      <p className="_card">
                        <span>{user?.subject}</span>
                      </p>
                    </li>
                    <li>
                      <img src={FormExperienceIcon} alt="" />
                      <p className="_card">
                        <span>{user?.experience} years Experience</span>
                      </p>
                    </li>
                    <li>
                      <img src={FormBatchesIcon} alt="" />
                      <p className="_card">
                        <span>{user?.batches} Batches</span>
                      </p>
                    </li>
                    {user?.students ? (
                      <li>
                        <img src={FormStudentsIcon} alt="" />
                        <p className="_card">
                          <span>{user?.students} Students Trained</span>
                        </p>
                      </li>
                    ) : null}
                  </ul>
                </div>

                <div className="allow_permissions spacing">
                  <ul>
                    <li className="_card">
                      <div className="_card_content">
                        <p>
                          <img src={wifiIcon} alt="" /> <b>Wifi</b>
                        </p>
                        <SwitchButton
                          checked={user?.associateDevices?.wifi}
                          onChange={(e) =>
                            updateDetail.mutate({ wifi: e.target.checked })
                          }
                        />
                      </div>
                    </li>
                    <li className="_card">
                      <div className="_card_content">
                        <p>
                          <img src={laptopIcon} alt="" /> <b>Laptop</b>
                        </p>
                        <SwitchButton
                          checked={user?.associateDevices?.laptop}
                          onChange={(e) =>
                            updateDetail.mutate({ laptop: e.target.checked })
                          }
                        />
                      </div>
                    </li>
                    <li className="_card">
                      <div className="_card_content">
                        <p>
                          <img src={whiteBoardIcon} alt="" /> <b>WhiteBoard</b>
                        </p>
                        <SwitchButton
                          checked={user?.associateDevices?.whiteBoard}
                          onChange={(e) =>
                            updateDetail.mutate({
                              whiteBoard: e.target.checked,
                            })
                          }
                        />
                      </div>
                    </li>

                    <li className="_card">
                      <div className="_card_content">
                        <p>
                          <img src={phoneHolderIcon} alt="" />{" "}
                          <b>Phone Holder</b>
                        </p>
                        <SwitchButton
                          checked={user?.associateDevices?.phoneHolder}
                          onChange={(e) =>
                            updateDetail.mutate({
                              phoneHolder: e.target.checked,
                            })
                          }
                        />
                      </div>
                    </li>
                    <li className="_card">
                      <div className="_card_content">
                        <p>
                          <img src={powerBackupIcon} alt="" />{" "}
                          <b>Power Backup/UPS</b>
                        </p>
                        <SwitchButton
                          checked={user?.associateDevices?.powerBackup}
                          onChange={(e) =>
                            updateDetail.mutate({
                              powerBackup: e.target.checked,
                            })
                          }
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="content_right">
                <div className="card_wrap spacing">
                  <div className="_card">
                    <div className="_card_content">
                      <h4>Total Registered</h4>
                      <div className="progress_wrap">
                        <GradientCircularProgressbar
                          id={"one"}
                          percentageValue={66}
                          textValue={66}
                          color1={"#473DCE"}
                          color2={"#5B9FF3"}
                          color3={"#5B9FF3"}
                          textColor={"#3B82F6"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="_card">
                    <div className="_card_content">
                      <h4>Total Completed</h4>
                      <div className="progress_wrap">
                        <GradientCircularProgressbar
                          id={"two"}
                          percentageValue={30}
                          textValue={30}
                          color1={"#B0FF4B"}
                          color2={"#11876B"}
                          color3={"#11876B"}
                          textColor={"#11876B"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="_card">
                    <div className="_card_content">
                      <h4>Total Inprogress</h4>
                      <div className="progress_wrap">
                        <GradientCircularProgressbar
                          id={"three"}
                          percentageValue={40}
                          textValue={46}
                          color1={"#B0FF4B"}
                          color2={"#11876B"}
                          color3={"#11876B"}
                          textColor={"#11876B"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="_card">
                    <div className="_card_content">
                      <h4>Total Certified</h4>
                      <div className="progress_wrap">
                        <GradientCircularProgressbar
                          id={"four"}
                          percentageValue={55}
                          textValue={33}
                          color1={"#B0FF4B"}
                          color2={"#11876B"}
                          color3={"#11876B"}
                          textColor={"#11876B"}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="action_card spacing">
                  <div className="_card purple">
                    <div className="_card_content">
                      <Link to="edit-profile"></Link>
                      <h4>Edit Profile</h4>
                      <img src={profileEditIcon} alt="" />
                    </div>
                  </div>
                  <div className="_card red">
                    <div className="_card_content">
                      <Link to="bank-detail"></Link>
                      <h4>Bank Details</h4>
                      <img src={bankIcon} alt="" />
                    </div>
                  </div>
                  <div className="_card orange">
                    <div className="_card_content">
                      <Link to="address"></Link>
                      <h4>Postal Address</h4>
                      <img src={postalAddressIcon} alt="" />
                    </div>
                  </div>
                  <div className="_card blue">
                    <div className="_card_content">
                      <Link to="experience-certificate"></Link>
                      <h4>Experience Certificate</h4>
                      <img src={ExperienceIcon} alt="" />
                    </div>
                  </div>
                  <div className="_card">
                    <div className="_card_content">
                      <Link to={`/profile/teacher/certificates`}></Link>
                      <h4>Certificates</h4>
                      <img src={profileCertificatesIcon} alt="" />
                    </div>
                  </div>
                  <div className="_card green">
                    <div className="_card_content">
                      <Link to="about-me"></Link>
                      <h4>About Me</h4>
                      <img src={aboutMeIcon} alt="" />
                    </div>
                  </div>
                  <div className="_card orange">
                    <div className="_card_content">
                      <Link to="signature"></Link>
                      <h4>Signature for Certificate</h4>
                      <img src={signatureIcon} alt="" />
                    </div>
                  </div>
                  <div className="_card blue">
                    <div className="_card_content">
                      <Link to="students-feedbacks"></Link>
                      <h4>Students Feedbacks</h4>
                      <img src={studentsIcon} alt="" />
                    </div>
                  </div>
                  <div className="_card blue">
                    <div className="_card_content">
                      <Link to="/jobs"></Link>
                      <h4>Apply for Job</h4>
                      <img src={profileApplyJobIcon} alt="" />
                    </div>
                  </div>
                  <div className="_card">
                    <div className="_card_content">
                      <Link to="manage-profile"></Link>
                      <h4>Manage Profile</h4>
                      <img src={profileManageIcon} alt="" />
                    </div>
                  </div>
                  <div className="_card purple">
                    <div className="_card_content">
                      <Link to="/profile/donation/transaction"></Link>
                      <h4>Transaction History</h4>
                      <img src={profileTransactionIcon} alt="" />
                    </div>
                  </div>
                  <div className="_card purple">
                    <div className="_card_content">
                      <Link to="/profile/teacher/privacy-policy"></Link>
                      <h4>Privacy Policy</h4>
                      <img src={profileTransactionIcon} alt="" />
                    </div>
                  </div>
                  <div className="_card purple">
                    <div className="_card_content">
                      <Link to="/profile/teacher/terms-condition"></Link>
                      <h4>Terms and Conditions</h4>
                      <img src={profileTransactionIcon} alt="" />
                    </div>
                  </div>

                  <div className="_card help">
                    <div className="_card_content">
                      <Link to="/profile/help"></Link>
                      <h4>Help</h4>
                      <img src={profileHelpIcon} alt="" />
                    </div>
                  </div>

                  <div
                    className="_card logout"
                    onClick={() => setLogoutModal(true)}
                  >
                    <div className="_card_content">
                      <h4>Logout</h4>
                      <img src={profileLogoutIcon} alt="" />
                    </div>
                  </div>
                  <div
                    className="_card"
                    onClick={() => setDeleteAccountModal(true)}
                  >
                    <div className="_card_content">
                      <h4>Delete Account</h4>
                      <img src={profileDeleteIcon} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="action_card spacing">
             
            </div> */}
            <Helpline />
          </div>
        </div>
      </div>

      {/* Delete Account */}
      <CustomModal
        show={deleteAccountModal}
        handleClose={() => setDeleteAccountModal(false)}
        className={" editProfileModal deleteAccountModal"}
      >
        <h3>Delete Account</h3>
        <p>Are You Sure, You Want To Delete Account?</p>
        <div className="form_field">
          <Select
            label="Select Reason"
            onChange={(e) => {
              if (String(e.target.value).toLocaleLowerCase() === "other") {
                setState((prev) => ({
                  ...prev,
                  reason: String(e.target.value).toLocaleLowerCase(),
                  showReasonField: true,
                }));
              } else {
                setState((prev) => ({
                  ...prev,
                  reason: e.target.value,
                  showReasonField: false,
                }));
              }
            }}
          >
            <option>Select Reason</option>
            {reasonsList?.data?.reasonList?.map((reason) => {
              return <option value={reason.title}>{reason.title}</option>;
            })}
            <option value="other">Other, Please explain</option>
          </Select>
          {!state.reason?.length ? <span>{state.reasonError}</span> : null}
        </div>
        {state.showReasonField ? (
          <div className="form_field">
            <Input
              name="reasonFieldText"
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  reasonFieldText: e.target.value,
                }))
              }
              value={state.reasonFieldText}
              placeholder="Reason for deleting account"
              maxLength={100}
            />

            {!state.reasonFieldText?.length ? (
              <span>{state.reasonFieldTextError}</span>
            ) : null}
          </div>
        ) : null}
        <p className="note">
          Note:{" "}
          <span>
            If you delete this account, all associated data{" "}
            <span style={{ color: "#FF3B30" }}>and balance(if you have)</span>{" "}
            will be <span style={{ color: "#FF3B30" }}>removed.</span>
          </span>
        </p>
        <div className="button_wrap button_center">
          <Button
            onClick={deleteAccountMutation.mutate}
            isDisabled={deleteAccountMutation.isLoading}
          >
            Delete
          </Button>
        </div>
      </CustomModal>

      {/* Logout Modal */}
      <CustomModal
        show={logoutModal}
        handleClose={() => setLogoutModal(false)}
        className={"logoutModal"}
      >
        <img src={profileLogoutIcon} alt="" />
        <h4>Are you sure you want to logout?</h4>

        <duv className="button_wrap button_center">
          <Button onClick={logout}>Yes, Exit</Button>
        </duv>
      </CustomModal>
    </>
  );
};

export default TeacherMyProfile;
