
import React from "react";

const ZoomLink = () => {
  return (
    <>
      <div className="zoomLink">
        <div className="zoom_link_list">
          <ul>
            <li className="_title">
              <p>Name</p>{" "}
              <div className="right_content">
                <b>Zoom</b>
                <b>Chat</b>
              </div>
            </li>
            <li>
              <p>April2024-Drawing - 4pm - Mohan</p>{" "}
              <div className="right_content">
                <b className="_link">Link 19</b>
                <b className="_chat">C021</b>
              </div>
            </li>
            <li>
              <p>April2024-Drawing - 4pm - Mohan</p>{" "}
              <div className="right_content">
                <b className="_link">Link 19</b>
                <b className="_chat">C021</b>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ZoomLink;
