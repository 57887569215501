import React from "react";
import "../../TeacherProfile.scss";
import { BackButton } from "components/form";
import Helpline from "components/helpline/Helpline";
import { certificateImage, signatureImg } from "assets/images";
import useUser from "hooks/useUser";
import NoData from "components/no-data";
import { useMutation } from "react-query";
import { UserService } from "services";
import { generateFormDataPayload } from "utils/helper";
import Spinner from 'react-bootstrap/Spinner';
import { useQueryClient } from 'react-query';
import { type } from "@testing-library/user-event/dist/type";


const Signature = () => {
  const queryClient = useQueryClient();
  const { user } = useUser();
  const { updateProfile } = UserService();

  const uploadSignature = useMutation(data => {
    const payload = generateFormDataPayload({
      ...data,
      type: "sign",
      token: localStorage.getItem("fg_token")
    }, "sign")
    return updateProfile(payload);
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('userProfile');
    }
  });

  return (
    <>
      <div className="signature">
        <div className="inner_container spacing">
          <div className="_card">
            <div className="_card_content">
              <BackButton />
              <div className="sub_title">Signature for Certificate</div>

              <div className="signature_preview">
                {
                  user?.sign
                    ?
                    <div className="image_preview">
                      <img src={user?.sign} alt="" />
                    </div>
                    :
                    <NoData desc="Upload your signature to show here" />
                }
                <p>
                  {" "}
                  <label htmlFor="signatureImg">
                    <input type="file" id="signatureImg" onChange={e => uploadSignature.mutate({ sign: e.target.files[0] })} />
                    Update Signature
                  </label>

                  {
                    uploadSignature.isLoading
                      ?
                      <Spinner animation="border" role="status" variant="primary" />
                      :
                      null
                  }

                </p>
              </div>
            </div>
          </div>
        </div>
        <Helpline />
      </div>
    </>
  );
};

export default Signature;
