import { BackButton } from "components/form";
import React from "react";
import "./Notifications.scss";
import Helpline from "components/helpline/Helpline";
import { NotificationService } from "services";
import { useMutation, useQuery } from "react-query";
import { formatDateTime } from "utils/helper";
import NoData from "components/no-data";
const NotificationsList = () => {
  const { getNotifications, clearAllNotifications } = NotificationService();

  const { data, refetch } = useQuery({
    queryKey: ["notifications-list"],
    queryFn: async () =>
      getNotifications({
        token: localStorage.getItem("fg_token"),
      }),
  });

  const cleanNotifications = useMutation(
    () => {
      const payload = {
        token: localStorage.getItem("fg_token"),
      };
      return clearAllNotifications(payload);
    },
    {
      onSuccess: (response) => {
        console.log({ response });
        refetch();
      },
    }
  );

  const notificationList = data?.data?.list;
  return (
    <>
      <div className="notification_list spacing">
        <div className="inner_container">
          <div className="_card">
            <div className="_card_content">
              <BackButton />
              <h5 className="sub_title">
                Notifications{" "}
                <span
                  className="clear_button"
                  onClick={() => cleanNotifications.mutate()}
                >
                  Clear All
                </span>
              </h5>
              {notificationList && notificationList?.length ? (
                notificationList?.map((item, index) => {
                  return (
                    <div
                      className={`notification ${index === 0 && "cancel"}`}
                      key={index}
                    >
                      <div className="_card">
                        <div className="_card_content">
                          <h4>{item?.title}</h4>
                          <p>
                            <span>{item?.message}</span>
                            <strong>{formatDateTime(item?.createdAt)}</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <NoData desc="Do some activity to get notifications" />
              )}
            </div>
          </div>
        </div>
      </div>

      <Helpline />
    </>
  );
};

export default NotificationsList;
