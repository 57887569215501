import "./styles.scss";
import React from "react";

const NoData = ({ height, desc, title }) => {
  return (
    <div className="no-data" style={{ height }}>
      <div className="title">{title ? title : "No data found!"}</div>
      <div className="desc">
        { desc
            ? desc
            : "Oops! We couldn't find any results."
          }
      </div>
    </div>
  );
};

export default NoData;
