import NoData from "components/no-data";
import React from "react";
import { GoStarFill } from "react-icons/go";
import Slider from "react-slick";
const settings = {
  dots: true,
  arrows: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 2000,
  infinite: false,
};

const TeacherSlider = ({ teacherList = [] }) => {
  return (
    <>
      <div className="student_works teacher_slider  spacing">
        <div className="container">
          <div className="page_title">
            <h3>Teachers</h3>
          </div>

          <div className="teacher_slider_slider">
            {teacherList?.length > 0 ? (
              <Slider {...settings}>
                {teacherList?.map((it) => {
                  return (
                    <div className="item" key={it._id}>
                      <div className="teacher_card">
                        <img src={it?.profile} alt={it?.name} />
                        <h3>{it?.name}</h3>
                        <p>
                          Exp. {it?.experience}{" "}
                          {+it?.experience > 1 ? "years" : "year"}
                        </p>
                        <div className="_rating">
                          <span>{it?.ratings?.toFixed(1)}</span>
                          <GoStarFill />
                          <span>({it?.reviews})</span>
                        </div>
                        <span className="subject">{it?.subject}</span>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <NoData desc="No Teachers Available" />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherSlider;
