import React from "react";
import "./myCart.scss";
import Helpline from "components/helpline/Helpline";
import { correctCircleIcon, playButton, rejectIcon } from "assets/icons";
import { Link } from "react-router-dom";

const CartRegistration = () => {
  return (
    <>
      <div className="my_cart spacing">
        <div className="inner_container">
          <div className="_card">
            <div className="_card_content">
              <div className="rejection_content">
                <img src={correctCircleIcon} alt="" className="success_icons" />
                <h5>Successfully Registered</h5>
                <p className="info">
                  <img src={rejectIcon} alt="" /> If the class required
                  Tools/Material, please buy it.
                </p>

                <div className="button_wrap">
                  <Link to="/my-classes">
                    To View Registerd Classes Go To <span>My Classes</span>
                  </Link>
                </div>
                <p className="">
                  If you are New to FREE GURUKUL online classes,{" "}
                  <b style={{color:"#080eed"}} >Play Video</b> to know about “How to join into
                  Class”
                </p>

                <button type="button" className="pay_button">
                  <img src={playButton} alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Helpline />
    </>
  );
};

export default CartRegistration;
