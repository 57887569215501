import React, { useEffect, useState } from "react";
import ClassList from "./ClassList";
import MessageSection from "./MessageSection";
import { io } from "socket.io-client";
import "./Chat.scss";
import { useDispatch, useSelector } from "react-redux";
import { setSocket } from "../../redux/slices/chat";

const Chats = () => {
  var socket = io(process.env.REACT_APP_SOCKET_URL, {
    auth: {
      token: localStorage.getItem("fg_token"),
    },
  });

  const { selectedClass } = useSelector((state) => state.chat);

  const [fetchAgain, setFetchAgain] = useState(false);
  const [isConnected, setIsConnected] = useState(socket.connected);

  const dispatch = useDispatch();

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
      dispatch(setSocket(socket));
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="chat_container_wrap">
      <div className="container">
        <div className="chat_container_wrap">
          <ClassList
            {...{
              fetchAgain,
              socket,
              isConnected,
            }}
          />

          {selectedClass?.roomId && (
            <MessageSection {...{ fetchAgain, setFetchAgain, socket }} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Chats;
