import { searchIcon } from "assets/icons";
import useDebounce from "hooks/useDebounce";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, updateSearch, useAppSelector } from "../../redux";

const HeaderMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const searchText = useAppSelector(state => state.sharedSlice.search?.text);
  const [search, setSearch] = useState("");

  const searchDebounce = useDebounce(search);
  useEffect(() => {
    dispatch(updateSearch({ text: searchDebounce, pathname: location.pathname }));
  }, [searchDebounce]);

  useEffect(() => {
    setSearch(searchText);
  }, [searchText])

  const enabledRoutes = ["my-classes"];
  const menu = location.pathname?.split("/")[1];

  return (
    <div className="container">
      <div className="student_Access">
        <div className="tab_button_wrap has_search" >
          <ul>
            <li>
              <button
                type="button"
                className={
                  !["my-classes", "chat", "profile"].includes(menu) && "active"
                }
                onClick={() => navigate("/")}
              >
                <span>All Classes</span>
              </button>
            </li>
            <li>
              <button
                type="button"
                className={menu === "my-classes" && "active"}
                onClick={() => navigate("/my-classes")}
              >
                <span>My Classes</span>
              </button>
            </li>
            <li>
              <button
                type="button"
                className={menu === "chat" && "active"}
                onClick={() => navigate("/chat")}
              >
                <span>Chat</span>
              </button>
            </li>
            <li>
              <button
                type="button"
                className={menu === "profile" && "active"}
                onClick={() => navigate("/profile")}
              >
                <span>Profile</span>
              </button>
            </li>
          </ul>

          {
            enabledRoutes.includes(menu)
              ?
              <div className="search_wrap">
                <input
                  type="search"
                  placeholder="Search for class name"
                  onChange={e => setSearch(e.target.value)}
                />
                <img src={searchIcon} alt="" />
              </div>
              : null
          }
        </div>
      </div>
    </div>
  );
};

export default HeaderMenu;
