import HttpService from "services/http";

const ClassService = () => {
    const { httpRequest, httpFormRequest } = HttpService();

    // protected API 
    const purchaseClass = (payload) => new Promise((resolve, reject) => {
        httpFormRequest("POST", "user/class/purchase", payload)
            .then(resolve)
            .catch(reject);
    });

    const purchaseClassMembers = (search) => new Promise((resolve, reject) => {
        httpRequest("Get", "user/class/purchase_member", {}, search)
            .then(resolve)
            .catch(reject);
    });

    const getMyClassList = (search) => new Promise((resolve, reject) => {
        httpRequest("GET", "user/class/my_class", {}, search)
            .then(resolve)
            .catch(reject);
    });

    const changeClassApi = (payload) => new Promise((resolve, reject) => {
        httpFormRequest("POST", "user/class/change", payload)
            .then(resolve)
            .catch(reject);
    });

    const updateStudentPassFailStatus = (payload) => new Promise((resolve, reject) => {
        httpFormRequest("PUT", "user/class/pass_fail", payload)
            .then(resolve)
            .catch(reject);
    });

    const notifyClass = (payload) => new Promise((resolve, reject) => {
        httpFormRequest("POST", "user/class/notify", payload)
            .then(resolve)
            .catch(reject);
    });

    // public API 
    const getClassList = (search) => new Promise((resolve, reject) => {
        httpRequest("GET", "user/class/list_web", {}, search)
            .then(resolve)
            .catch(reject);
    });

    const getClassDetail = (search) => new Promise((resolve, reject) => {
        httpRequest("GET", "user/class/detail_web", {}, search)
            .then(resolve)
            .catch(reject);
    });
    const getCategoryList = (search) => new Promise((resolve, reject) => {
        httpRequest("GET", "master/category", {}, search)
            .then(resolve)
            .catch(reject);
    });

    const getGroupList = (search) => new Promise((resolve, reject) => {
        httpRequest("GET", "master/group", {}, search)
            .then(resolve)
            .catch(reject);
    });

    const getClassTeacherDetail = (search) => new Promise((resolve, reject) => {
        httpRequest("GET", "user/class/about_class_web", {}, search)
            .then(resolve)
            .catch(reject);
    });

    return {
        getClassList,
        getClassDetail,
        getCategoryList,
        getGroupList,
        getClassTeacherDetail,
        purchaseClass,
        getMyClassList,
        changeClassApi,
        purchaseClassMembers,
        updateStudentPassFailStatus,
        notifyClass
    };
}

export { ClassService };