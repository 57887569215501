import React from "react";
import "../../TeacherProfile.scss";
import { BackButton, RatingStar } from "components/form";
import Helpline from "components/helpline/Helpline";
import { ProfileImg } from "assets/images";
import "../../TeacherProfile.scss";
import { useQuery } from "react-query";
import { RatingService } from "services";
import useUser from "hooks/useUser";
import Skeleton from "components/skeleton";
import { formatDate } from "utils/helper";
const StudentsFeedback = () => {
  const { user } = useUser();
  const { getRatings } = RatingService();

  const rating = useQuery({
    queryKey: ["cart-list"],
    queryFn: async () =>
      getRatings({
        token: localStorage.getItem("fg_token"),
        teacherId: user?._id,
      }),
  });
  console.log("🌹 ~ StudentsFeedback ~ rating:", rating);

  const ratings = rating.data?.data?.reviews;
  const reviewData = rating?.data?.data;
  return (
    <>
      <div className="feedback_listing">
        <div className="inner_container spacing">
          <div className="_card">
            <div className="_card_content">
              <BackButton />
              <div className="sub_title">Students Feedbacks</div>
              <div className="students_feedbacks">
                <h4>
                  Reviews <span>({reviewData?.totalReviews ?? 0})</span>
                </h4>
                <div className="rating_wrap">
                  <RatingStar
                    readonly
                    rating={reviewData?.averageRating ?? 0}
                    user={`${reviewData?.totalReviews ?? 0} Reviews`}
                  />
                </div>

                {rating.isLoading ? (
                  <div className="rating_card_wrap">
                    <div className="rating_card">
                      <Skeleton />
                      <Skeleton />
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="rating_card_wrap">
                  {ratings?.map((ele, index) => {
                    return (
                      <div className="rating_card" key={index}>
                        <div className="head">
                          <div className="content_left">
                            <img
                              src={
                                ele?.studentId?.profile
                                  ? ele?.studentId?.profile
                                  : ProfileImg
                              }
                              alt=""
                            />
                            <p>
                              <b>{ele?.studentId?.name}</b>
                              <span>Student</span>
                            </p>
                          </div>
                          <div className="content_right">
                            <RatingStar initialRating={ele?.rating} />
                            <span className="date">
                              {formatDate(ele?.createdAt)}
                            </span>
                          </div>
                        </div>
                        <p>{ele?.comment}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Helpline />
      </div>
    </>
  );
};

export default StudentsFeedback;
