import HeaderMenu from "components/header-menu";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const Layout = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const menu = location.pathname?.split("/")[1];

    return <>
        <HeaderMenu />
        <Outlet />
    </>
}

export default Layout;