import React from "react";

const RequiredTools = () => {
  return (
    <>
      <div className="">
        <h1>RequiredTools </h1>
      </div>
    </>
  );
};

export default RequiredTools;
