import Rating from "react-rating";
import { FaRegStar, FaStar } from "react-icons/fa";
import { forwardRef } from "react";
import { LuCalendarDays } from "react-icons/lu";
import ReactDatePicker from "react-datepicker";
import MultiSelect from "react-select";
import { components } from "react-select";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { backIcon } from "assets/icons";
// import { placeholder2 } from "../../assets/images";

export const RatingStar = ({ user, rating, onChange, ...rest }) => {
  return (
    <>
      <div className="rating">
        {rating && <span className="_rating">{rating}</span>}
        <Rating
          className="rating_star"
          emptySymbol={<FaRegStar />}
          fullSymbol={<FaStar />}
          initialRating={rating}
          onChange={onChange}
          {...rest}
        />
        {user && <span className="user">{user}</span>}
      </div>
    </>
  );
};

export const Button = ({ type, className, isDisabled, children, ...rest }) => {
  return (
    <>
      <button
        disabled={isDisabled}
        type={type ? type : "button"}
        className={`button ${className}`}
        {...rest}
      >
        {children}
      </button>
    </>
  );
};

export const LoadingButton = ({
  children,
  loading,
  className = "",
  ...rest
}) => {
  return (
    <button className={`button has_loader ${className}`} {...rest}>
      {loading ? (
        <>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="24px"
            height="30px"
            viewBox="0 0 24 30"
          // style="enable-background:new 0 0 50 50;"
          >
            <rect x="0" y="13" width="4" height="5" fill="#fff">
              <animate
                attributeName="height"
                attributeType="XML"
                values="5;21;5"
                begin="0s"
                dur="0.6s"
                repeatCount="indefinite"
              />
              <animate
                attributeName="y"
                attributeType="XML"
                values="13; 5; 13"
                begin="0s"
                dur="0.6s"
                repeatCount="indefinite"
              />
            </rect>
            <rect x="10" y="13" width="4" height="5" fill="#fff">
              <animate
                attributeName="height"
                attributeType="XML"
                values="5;21;5"
                begin="0.15s"
                dur="0.6s"
                repeatCount="indefinite"
              />
              <animate
                attributeName="y"
                attributeType="XML"
                values="13; 5; 13"
                begin="0.15s"
                dur="0.6s"
                repeatCount="indefinite"
              />
            </rect>
            <rect x="20" y="13" width="4" height="5" fill="#fff">
              <animate
                attributeName="height"
                attributeType="XML"
                values="5;21;5"
                begin="0.3s"
                dur="0.6s"
                repeatCount="indefinite"
              />
              <animate
                attributeName="y"
                attributeType="XML"
                values="13; 5; 13"
                begin="0.3s"
                dur="0.6s"
                repeatCount="indefinite"
              />
            </rect>
          </svg>
        </>
      ) : (
        children
      )}
    </button>
  );
};

export const Input = forwardRef(
  (
    {
      name,
      label,
      error,
      subText,
      required,
      onChange,
      control,
      type,
      value = "",
      className = "",
      infoErr = false,
      optional = false,
      isDisabled = false,
      extraError = false,
      ...rest
    },
    ref
  ) => {
    const classGenerator = () => {
      if (infoErr && extraError) {
        return "is-invalid";
      }
      if (infoErr) {
        return "info";
      }
      return ((!value && error) || extraError) && "is-invalid";
    };
    return (
      <>
        {label && (
          <label className="label">
            {label}
            {required && <span className="required mx-1"> *</span>}
            {optional && <em className="optional">{"(optional)"}</em>}
            {subText && <span className="subText">{subText}</span>}
          </label>
        )}
        {control ? (
          <Controller
            control={control}
            name={name}
            render={({ field: { onChange, onBlur, value } }) => (
              <input
                ref={ref}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                disabled={isDisabled}
                className={`form-control input ${className} ${classGenerator()}`}
                onKeyDown={(event) => {
                  if (type === "number") {
                    const allowedKeys = [
                      "Backspace",
                      "ArrowLeft",
                      "ArrowRight",
                      "Enter"
                    ]; // Allow navigation and backspace

                    // Allow Ctrl+C (copy) and Ctrl+V (paste)
                    if (
                      (event.ctrlKey || event.metaKey) &&
                      (event.key === "c" || event.key === "v")
                    ) {
                      return;
                    }

                    // Allow numeric keys and allowedKeys (e.g., backspace, arrows)
                    if (
                      !/[0-9]/.test(event.key) &&
                      !allowedKeys.includes(event.key)
                    ) {
                      event.preventDefault();
                    }
                  }
                }}
                type={type === "number" ? "string" : type}
                {...rest}
              />
            )}
          />
        ) : (
          <input
            ref={ref}
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            disabled={isDisabled}
            className={`form-control input ${className} ${classGenerator()}`}
            {...rest}
          />
        )}
        {error && (
          <span className={`${infoErr ? "info-feedback" : "invalid-feedback"}`}>
            {error}
          </span>
        )}
      </>
    );
  }
);

export const Select = forwardRef(
  (
    {
      label,
      onChange,
      value,
      error,
      className = "",
      children,
      required,
      optional,
      ...rest
    },
    ref
  ) => {
    return (
      <>
        {label && (
          <label className="label">
            {label}
            {required && <span className="required mx-1"> *</span>}
            {optional && <em className="optional">{"(optional)"}</em>}
          </label>
        )}
        <select
          onChange={onChange}
          value={value}
          className={`form-control input ${className} ${!value && error && "is-invalid"
            }`}
          ref={ref}
          {...rest}
        >
          {children}
        </select>
        {error && <span className="invalid-feedback">{error}</span>}
      </>
    );
  }
);

export const TextArea = forwardRef(
  ({ value, onChange, label, className = "", control, name, error, ...rest }, ref) => {
    return (
      <>
        {
          control
            ?
            <Controller
              control={control}
              name={name}
              render={({ field: { onChange, onBlur, value } }) => (
                <textarea
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  className={`form-control input ${className} ${!value && error && "is-invalid"
                    }`}
                  ref={ref}
                  {...rest}
                />
              )}
            />
            :
            <>
              {label && <label className="my-1 label">{label}</label>}
              <textarea
                value={value}
                onChange={onChange}
                className={`form-control input ${className} ${!value && error && "is-invalid"
                  }`}
                ref={ref}
                {...rest}
              />
            </>
        }
        {error && <span className="invalid-feedback">{error}</span>}
      </>
    );
  }
);

export const DatePicker = forwardRef(
  (
    {
      value,
      onChange,
      className = "",
      label,
      error,
      required,
      extraError = false,
      infoErr = false,
      optional = false,
      placeholder,
      ...rest
    },
    ref
  ) => {
    const classGenerator = () => {
      if (infoErr && extraError) {
        return "is-invalid";
      }
      if (infoErr) {
        return "info";
      }
      return ((!value && error) || extraError) && "is-invalid";
    };

    return (
      <>
        {label && (
          <label className="label">
            {label}
            {required && <span className="required mx-1"> *</span>}
            {optional && <em className="optional">{"(optional)"}</em>}
          </label>
        )}
        <ReactDatePicker
          ref={ref}
          className={`form-control input date_picker ${className} ${classGenerator()}`}
          showIcon
          icon={<LuCalendarDays />}
          selected={value}
          onChange={onChange}
          dateFormat={"dd-MMM-yyyy"}
          showMonthDropdown
          useShortMonthInDropdown
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={300}
          onChangeRaw={(e) => e.preventDefault()}
          {...rest}
          placeholderText={placeholder}
        />
        {!value && error && (
          <span
            className={`${infoErr ? "info-feedback" : "invalid-feedback"
              } d-block`}
          >
            {error}
          </span>
        )}
      </>
    );
  }
);

export const YearPicker = forwardRef(
  (
    {
      value,
      onChange,
      className = "",
      label,
      error,
      required,
      extraError = false,
      infoErr = false,
      optional = false,
      ...rest
    },
    ref
  ) => {
    const classGenerator = () => {
      if (infoErr && extraError) {
        return "is-invalid";
      }
      if (infoErr) {
        return "info";
      }
      return ((!value && error) || extraError) && "is-invalid";
    };

    return (
      <>
        {label && (
          <label className="label">
            {label}
            {required && <span className="required mx-1"> *</span>}
            {optional && <em className="optional">{"(optional)"}</em>}
          </label>
        )}
        <ReactDatePicker
          ref={ref}
          className={`form-control input ${className} ${classGenerator()}`}
          showIcon
          icon={<LuCalendarDays />}
          selected={value}
          onChange={onChange}
          dateFormat="yyyy"
          // useShortMonthInDropdown
          // showYearDropdown
          // scrollableYearDropdown
          showYearPicker
          onChangeRaw={(e) => e.preventDefault()}
          {...rest}
          yearDropdownItemNumber={300}
        />
        {!value && error && (
          <span
            className={`${infoErr ? "info-feedback" : "invalid-feedback"
              } d-block`}
          >
            {error}
          </span>
        )}
      </>
    );
  }
);

export const CommonSelect = ({
  label,
  onChange,
  value,
  error,
  className = "",
  options = [],
  isMulti = false,
  isSearchable = false,
  noOptionsMessage = () => "No options found",
  required = false,
  loading = false,
  isDisabled = false,
  placeholder = "Select",

  ...rest
}) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected
        ? "linear-gradient(180deg, #01b4a7 0%, #00aace 100%)"
        : "#fff", // Change background color of selected option
      color: state.isSelected
        ? "linear-gradient(180deg, #01b4a7 0%, #00aace 100%)"
        : "black", // Change text color of selected option
      "&:hover": {
        background: "linear-gradient(180deg, #01b4a7 0%, #00aace 100%)", // Change background color of option on hover
        color: "#fff", // Change text color of option on hover
      },
    }),
    loadingIndicator: (base, state) => ({
      ...base,
      color: "#FF8989", // Set the color to red
    }),
  };

  const LoadingIndicator = (props) => {
    return (
      <components.LoadingIndicator {...props}>
        {/* <ClipLoader size={20} color="#999" loading={true} />
         */}
        <p>Loading...</p>
      </components.LoadingIndicator>
    );
  };

  const handleChange = (selectedOptions) => {
    onChange(selectedOptions);
  };
  return (
    <div>
      {label && <label className="label">{label}</label>}
      {required && <span className="text-danger"> *</span>}

      <MultiSelect
        onChange={handleChange}
        value={value}
        className={`form-control multi-select ${className} ${!value && error && "is-invalid"
          }`}
        options={options}
        isMulti={isMulti}
        placeholder={placeholder}
        isSearchable={isSearchable}
        noOptionsMessage={noOptionsMessage}
        isLoading={loading}
        styles={customStyles}
        isDisabled={isDisabled}
        classNamePrefix="react_select"
        // placeholderText={placeholder}
        components={{ LoadingIndicator }}
        {...rest}
      />
      {/* {leftIcon} */}
      {error && <div className="invalid-feedback d-flex">{error}</div>}
    </div>
  );
};

// Switch Button //
export const SwitchButton = ({
  className = "",
  status = false,
  disabled,
  ...rest
}) => {
  return (
    <>
      <label
        className={`switch_button ${className} ${disabled ? "disabled" : ""}`}
      >
        <input type="checkbox" checked={status} disabled={disabled} {...rest} />
        <span className="slider round"></span>
      </label>
    </>
  );
};

export const Tooltip = ({ content }) => {
  return (
    <>
      <p className="_tooltip">
        <IoMdInformationCircleOutline />
        <span>{content}</span>
      </p>
    </>
  );
};

export const FileUpload = ({ id, label, onChange, error, accept, value }) => {
  return (
    <>
      <label className="label" htmlFor={id}>
        {label}
      </label>
      <div className="file_uploader">
        <input type="file" accept={accept} id={id} onChange={onChange} />
        <label
          className={`form-control input ${error ? "is-invalid" : ""}`}
          htmlFor={id}
        >
          <span className="fileTag">Choose file</span>
          <span>{value?.name ? value?.name : "No file Chosen"}</span>
        </label>
        <span className="invalid-feedback">{error}</span>
      </div>
    </>
  );
};

export const BackButton = ({ route, state }) => {
  return (
    <>
      <Link to={route ? route : -1} state={state} className="back_button _card">
        <span className="_card_content">
          <img src={backIcon} alt="back-icon" />
        </span>
      </Link>
    </>
  );
};
