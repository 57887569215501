import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { joiResolver } from "@hookform/resolvers/joi";
import { BackButton, Input, LoadingButton, TextArea } from "components/form";
import { contactIcon2, emailIcon, nameIcon } from "assets/icons";
import React, { useEffect } from "react";

import { applyJobValidation } from "validations";
import DocUpload from "components/docUploader/DocUploader";
import { JobService } from "services";
import { generateFormDataPayload } from "utils/helper";
import { useNavigate, useLocation } from "react-router-dom";
import useSnackbar from "hooks/useSnackbar";
import useUser from "hooks/useUser";

const ApplyForJob = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { snackbar } = useSnackbar();
  const { applyJob } = JobService();
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(applyJobValidation),
    defaultValues: {
      name: "",
      email: "",
      mobile: "",
      coverLetter: "",
    },
  });
  const resume = watch("resume");

  useEffect(()=>{
    if(user){
      setValue("name", user.name);
      setValue("mobile", user.mobile);
    }
  }, [user])

  useEffect(() => {
    if (!location.state?.vacancyId) {
      navigate("/jobs");
    }
  }, [location]);

  const jobApply = useMutation(
    (data) => {
      const payload = generateFormDataPayload(
        {
          ...data,
          vacancyId: location.state?.vacancyId,
          token: localStorage.getItem("fg_token"),
        },
        "resume"
      );

      return applyJob(payload);
    },
    {
      onSuccess: (data) => {
        navigate("/");
        snackbar(data?.message || "", "info");
      },
      onError: (err) => {
        snackbar(err.data?.message || "");
      },
    }
  );

  return (
    <>
      <div className="apply_job">
        <div className="inner_container">
          <div className="_card">
            <div className="_card_content">
              <BackButton />
              <div className="sub_title">Apply for job</div>
              <form
                className="edit_from_details"
                onSubmit={handleSubmit(jobApply.mutate)}
              >
                <div className="form_field ">
                  <Input
                    type="text"
                    placeholder="Enter Name"
                    name="name"
                    disabled
                    control={control}
                    error={errors?.name?.message}
                  />
                  <span className="country">
                    <img src={nameIcon} alt="" />
                  </span>
                </div>
                <div className="form_field ">
                  <Input
                    type="email"
                    placeholder="Email address"
                    name="email"
                    control={control}
                    error={errors?.email?.message}
                  />
                  <span className="country">
                    <img src={emailIcon} alt="" />
                  </span>
                </div>
                <div className="form_field ">
                  <Input
                    placeholder="Mobile number"
                    name="mobile"
                    disabled
                    control={control}
                    error={errors?.mobile?.message}
                    maxLength={"10"}
                  />
                  <span className="country">
                    <img src={contactIcon2} alt="" />
                  </span>
                </div>
                <div className="form_field ">
                  <TextArea
                    placeholder="Write here..."
                    label={"Description"}
                    name="coverLetter"
                    control={control}
                    error={errors?.coverLetter?.message}
                  />
                </div>
                <div className="form_field ">
                  <label className="label">Upload Resume</label>
                  <DocUpload
                    fileName={resume?.name}
                    onChange={(e) => setValue("resume", e.target.files[0])}
                  />

                  {errors?.resume?.message ? (
                    <span className={"info-feedback"}>
                      {errors?.resume?.message}
                    </span>
                  ) : null}
                </div>
                <div className="button_wrap button_center">
                  <LoadingButton
                    type="submit"
                    className="button"
                    loading={jobApply.isLoading}
                  >
                    Submit
                  </LoadingButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplyForJob;
