import Joi from "joi";

export const loginValidation = Joi.object({
    mobile: Joi
        .string()
        .required()
        .label("Mobile Number")
        .min(10)
        .max(10)
        .messages({
            "string.empty": "{{#label}} is required"
        })
});

export const signUpValidation = Joi.object({
    profile: Joi
        .object()
        .label("Profile")
        .optional()
        .allow(""),
        name: Joi
        .string()
        .pattern(/^[a-zA-Z0-9\s.-]*$/) // Allows letters, numbers, spaces, dots, and hyphens
        .required()
        .label("Name")
        .messages({
            "string.empty": "{{#label}} is required",
            "string.pattern.base": "{{#label}} must only contain letters, numbers, spaces, dots, and hyphens" 
        }),
    mobile: Joi
        .string()
        .required()
        .label("Mobile Number")
        .min(10)
        .messages({
            "string.empty": "{{#label}} is required"
        })
});