import { Button, DatePicker, Input } from "components/form";
import React, { useState } from "react";

const ApplyLeave = () => {
  const [tab, setTab] = useState("leave");
  return (
    <>
      <div className="apply_leave">
        <ul className="tab">
          <li>
            <button
              type="button"
              className={tab === "leave" ? "active" : ""}
              onClick={() => setTab("leave")}
            >
              Apply Leave{" "}
            </button>
          </li>
          <li>
            <button
              type="button"
              className={tab === "Status" ? "active" : ""}
              onClick={() => setTab("Status")}
            >
              Leave Status
            </button>
          </li>
        </ul>
        {tab === "leave" && (
          <form>
            <div className="form_field">
              <DatePicker placeholder="Start Date" />
            </div>
            <div className="form_field">
              <DatePicker placeholder="End Date" />
            </div>
            <div className="form_field">
              <Input type="text" placeholder="Type Reason..." />
            </div>
            <div className="form_field"></div>

            <div className="form_field">
              <label className="label">Do you compensate?</label>
              <DatePicker placeholder="Start Date" />
            </div>
            <div className="form_field">
              <DatePicker placeholder="End Date" />
            </div>
            <div className="button_wrap">
              <Button>Apply leave</Button>
            </div>
          </form>
        )}

        {tab === "Status" && (
          <div className="leave_list">
            <div className="leave_card">
              <ul>
                <li>
                  <strong>Start Date</strong> <span>5th Apr 2024</span>
                </li>
                <li>
                  <strong>End Date</strong> <span>6th Apr 2024</span>
                </li>
                <li>
                  <strong>Compensate</strong> <span>Yes</span>
                </li>
                <li>
                  <strong>Start Date</strong> <span>5th Apr 2024</span>
                </li>
                <li>
                  <strong>End Date</strong> <span>6th Apr 2024</span>
                </li>
                <li>
                  <strong>Reason</strong> <span>Health Issue</span>
                </li>
                <li>
                  <p>Submitted On: 4th Apr 2024 at 5pm</p>
                </li>
              </ul>
              <span className="tag pending">Pending</span>
            </div>
            <div className="leave_card">
              <ul>
                <li>
                  <strong>Start Date</strong> <span>5th Apr 2024</span>
                </li>
                <li>
                  <strong>End Date</strong> <span>6th Apr 2024</span>
                </li>
                <li>
                  <strong>Compensate</strong> <span>Yes</span>
                </li>
                <li>
                  <strong>Start Date</strong> <span>5th Apr 2024</span>
                </li>
                <li>
                  <strong>End Date</strong> <span>6th Apr 2024</span>
                </li>
                <li>
                  <strong>Reason</strong> <span>Health Issue</span>
                </li>
                <li>
                  <p>Submitted On: 4th Apr 2024 at 5pm</p>
                </li>
              </ul>
              <span className="tag approved">Approved</span>
            </div>
            <div className="leave_card">
              <ul>
                <li>
                  <strong>Start Date</strong> <span>5th Apr 2024</span>
                </li>
                <li>
                  <strong>End Date</strong> <span>6th Apr 2024</span>
                </li>
                <li>
                  <strong>Compensate</strong> <span>Yes</span>
                </li>
                <li>
                  <strong>Start Date</strong> <span>5th Apr 2024</span>
                </li>
                <li>
                  <strong>End Date</strong> <span>6th Apr 2024</span>
                </li>
                <li>
                  <strong>Reason</strong> <span>Health Issue</span>
                </li>
                <li>
                  <p>Submitted On: 4th Apr 2024 at 5pm</p>
                </li>
              </ul>
              <span className="tag rejected">Rejected</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ApplyLeave;
