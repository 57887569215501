import CryptoJS from "crypto-js";
import moment from "moment";

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

const encryptData = (data) => {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
    return encryptedData;
};

const decryptData = (encryptedData) => {
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    const decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
};

const generateFormDataPayload = (sendData, imgKey) => {
    const form = new FormData();

    form.append("reqData", encryptData(sendData));
    if (imgKey && sendData[imgKey]) {
        form.append(imgKey, sendData[imgKey]);
    }
    return form;
}

const capitalizeString = (input) => {
    if (typeof input !== 'string') return '';

    return input
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

const isDateBefore25 = () => {
    const date25th = moment().startOf("month").add(24, "days");
    const dateBefore25 = moment().isBefore(date25th);
    return dateBefore25;
}


const get12HourTime = (time24) => {
    const time12 = moment(time24, "HH:mm").format("hh:mm a");

    const [time, period] = time12.split(" ");
    const [hours, minutes] = time.split(":");

    if (minutes === "00") {
        return `${parseInt(hours)}${period}`;
    } else {
        return time12;
    }
};

const formatDate = (date) => {
    const dateString = moment(date).format("LL");
    return dateString;
};

const formatDateTime = (date) => {
    const dateString = moment(date).format("LLL");
    return dateString;
};

const base64ToFile = (url, name) => {
    let arr = url.split(',')
    // console.log(arr)
    let mime = arr[0].match(/:(.*?);/)[1]
    let data = arr[1]
  
    let dataStr = atob(data)
    let n = dataStr.length
    let dataArr = new Uint8Array(n)
  
    while (n--) {
      dataArr[n] = dataStr.charCodeAt(n)
    }
  
    let file = new File([dataArr], name, { type: mime })
  
    return file
  }

export {
    encryptData,
    decryptData,
    capitalizeString,
    generateFormDataPayload,
    isDateBefore25,
    get12HourTime,
    formatDate,
    formatDateTime,
    base64ToFile
};