import React, { useEffect, useState } from "react";
import { BackButton, Input } from "components/form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { UserService } from "services";
import { generateFormDataPayload } from "utils/helper";
import Helpline from "components/helpline/Helpline";
import useSnackbar from "hooks/useSnackbar";
import useUser from "hooks/useUser";
import {
  allowAlphaNumDotUnderscore,
  provideIfscString,
  provideOnlyNumbers,
  provideOnlyStrings,
  verifyEmail,
} from "utils/common";

const TeacherAddBankDetails = () => {
  const { user, setUser } = useUser();
  const { snackbar } = useSnackbar();
  const navigate = useNavigate();
  const { updateTeacherDetail: updateBankDetailApi } = UserService();

  const [formData, setFormData] = useState({
    accountHolderName: "",
    accountNumber: "",
    ifscCode: "",
    bankName: "",
    email: "",
  });

  const [error, setError] = useState({});

  const updateBankDetail = useMutation(
    (data) => {
      return updateBankDetailApi(
        generateFormDataPayload({
          ...data,
          type: "bank",
          token: localStorage.getItem("fg_token"),
        })
      );
    },
    {
      onSuccess: (data, requestPayload) => {
        const { accountHolderName, ...other } = requestPayload;
        setUser({
          ...user,
          bankDetails: {
            ...user?.bankDetails,
            ...other,
            holderName: accountHolderName,
          },
        });
        snackbar(data?.message || "", "info");
        navigate("/profile/teacher/bank-detail");
      },
      onError: (err) => {
        snackbar(err.data.message || "");
      },
    }
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "accountHolderName" || name === "bankName") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: provideOnlyStrings(value),
      }));
    } else if (name === "accountNumber") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: provideOnlyNumbers(value),
      }));
    } else if (name === "ifscCode") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: provideIfscString(value).toUpperCase(),
      }));
    } else if (name === "email") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: allowAlphaNumDotUnderscore(value).toLowerCase(),
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  // validation
  const handleValidation = () => {
    let errorMessage = {};
    let status = true;

    if (!formData.accountHolderName) {
      errorMessage[`accountHolderName`] = `"Account Holder Name" is required`;
      status = false;
    }

    if (!formData.accountNumber) {
      errorMessage[`accountNumber`] = `"Account Number" is required`;
      status = false;
    }

    if (!formData.ifscCode) {
      errorMessage[`ifscCode`] = `"IFSC Code" is required`;
      status = false;
    }

    if (!formData.bankName) {
      errorMessage[`bankName`] = `"Bank Name" is required`;
      status = false;
    }

    if (!formData.email) {
      errorMessage[`email`] = `"Email" is required`;
      status = false;
    } else if (!verifyEmail(formData.email)) {
      errorMessage["email"] = "Please Enter Valid Email";
      errorMessage["emailErr"] = true;
      status = false;
    }

    setError(errorMessage);
    return status;
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      updateBankDetail.mutate(formData);
    }
  };

  useEffect(() => {
    const bankDetailExist = user?.bankDetails?.accountNumber;
    if (user && bankDetailExist) {
      const { accountNumber, bankName, ifscCode, holderName, email } =
        user?.bankDetails;

      setFormData({
        accountHolderName: holderName,
        accountNumber,
        ifscCode,
        bankName,
        email,
      });
    }
  }, [user]);

  return (
    <>
      <div className="teacher_my_profile ">
        <div className="inner_container spacing">
          <div className="_card">
            <div className="_card_content">
              <BackButton route={"/profile/teacher/bank-detail"} />
              <div className="sub_title">Add Bank Details</div>

              <form className="edit_from_details" onSubmit={formSubmitHandler}>
                <div className="form_field">
                  <Input
                    type="input"
                    className="input2"
                    placeholder="Enter Account Holder Name"
                    name="accountHolderName"
                    value={formData.accountHolderName}
                    onChange={handleInputChange}
                    error={error.accountHolderName}
                  />
                </div>
                <div className="form_field">
                  <Input
                    type="number"
                    className="input2"
                    placeholder="Enter Account number"
                    name="accountNumber"
                    value={formData.accountNumber}
                    onChange={handleInputChange}
                    error={error.accountNumber}
                  />
                </div>
                <div className="form_field">
                  <Input
                    type="input"
                    className="input2"
                    placeholder="Enter IFSC Code"
                    name="ifscCode"
                    value={formData.ifscCode}
                    onChange={handleInputChange}
                    error={error.ifscCode}
                  />
                </div>
                <div className="form_field">
                  <Input
                    type="input"
                    className="input2"
                    placeholder="Enter Bank Name"
                    name="bankName"
                    value={formData.bankName}
                    onChange={handleInputChange}
                    error={error.bankName}
                  />
                </div>
                <div className="form_field">
                  <Input
                    type="input"
                    className="input2"
                    placeholder="Enter Email Id"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    error={error.email}
                    extraError={error?.emailErr}
                  />
                </div>
                <div className="button_wrap mb-3">
                  <button
                    type="submit"
                    className="button"
                    disabled={updateBankDetail.isLoading}
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Helpline />
      </div>
    </>
  );
};

export default TeacherAddBankDetails;
