import React, { useEffect, useState } from "react";
import {
  arrowRightIcon,
  calendarIcon,
  calenderCheckedIcon,
  chatIcon,
  languageIcon,
  medalIcon,
  monthIcon,
  searchIcon,
  userIcon,
  videoCameraIcon,
  watchIcon,
} from "assets/icons";
import { GoStarFill } from "react-icons/go";
import { courseImg } from "assets/images";
import { useMutation, useQuery } from "react-query";
import moment from "moment";

import { TOKEN, ClassService } from "services";
import { useLocation, useNavigate } from "react-router-dom";
import useDebounce from "hooks/useDebounce";
import Skeleton from "components/skeleton";
import NoData from "components/no-data";
import TypographyLimit from "components/TypographyLimit";
import { generateFormDataPayload, get12HourTime, isDateBefore25 } from "utils/helper";
import useUser from "hooks/useUser";
import { useAppDispatch, useAppSelector, updateCartCount } from "../../../../redux";
import { CartService } from "services/cart";

const defaultCategoryStart = [
  {
    _id: "bySeats",
    title: "By Seats"
  },
  {
    _id: "byTime",
    title: "By Time"
  }
]
const defaultCategoryEnd = [
  {
    _id: "new",
    title: "New"
  },
  {
    _id: "advanced",
    title: "Advanced",
  },
  {
    _id: "full",
    title: "Full"
  },
  {
    _id: "free",
    title: "Free",
  },
];

const AllClassesCategories = ({ currentClassId, title = false, handleSelectedClassDetail }) => {
  const { isLoggedIn, user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const cartState = useAppSelector(state => state.cartSlice?.data);
  const classState = useAppSelector(state => state.classSlice);
  const { getClassList, getClassDetail, getCategoryList } = ClassService();
  const { addToCart } = CartService();
  const [state, setState] = useState({
    selectedClass: "",
    selectedCategory: "all",
    search: "",
  });

  // Class List
  const search = useDebounce(state.search, 1000);
  const { data: classList } = useQuery({
    queryKey: ["class", search, state.selectedCategory],
    queryFn: async () =>
      getClassList({
        TOKEN,
        classId: currentClassId,
        ...(search?.length && { search }),
        ...(state.selectedCategory?.length &&
          state.selectedCategory !== "all" && {
          categoryId: state.selectedCategory,
        }),
      }),
  });

  // Categories List
  const { data: categoryList } = useQuery({
    queryKey: ["category"],
    queryFn: async () =>
      getCategoryList({
        TOKEN,
        type: "user",
        sortField: "title",
      }),
  });

  // Class Detail
  const { data: classDetail, isLoading: isClassDetailLoading } = useQuery({
    queryKey: ["classDetail", state.selectedClass],
    queryFn: async () =>
      getClassDetail({
        TOKEN,
        classId: state.selectedClass,
      }),
    enabled: state.selectedClass?.length ? true : false,
  });

  // Add to cart 
  const addToCartMutation = useMutation(data => {
    const payload = generateFormDataPayload({
      ...data,
      token: localStorage.getItem("fg_token")
    })
    return addToCart(payload);
  }, {
    onSuccess: (data) => {
      dispatch(updateCartCount(data?.data?.cartItems?.totalCartItemCount));
    }
  });

  useEffect(() => {
    if (classList?.data?.data?.length) {
      setState((prev) => ({
        ...prev,
        selectedClass: classList?.data?.data[0]?._id,
      }));
    }
  }, [classList]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      search: "",
    }));
  }, [isLoggedIn]);

  const formatRegistrationDate = (startDate, endDate) => {
    const startDay = moment(startDate).format("DD");
    const endDay = moment(endDate).format("DD");
    const month = moment(startDate).format("MMM");

    return <>Register between {startDay}<sup>th</sup> - {endDay}<sup>th</sup></>;
  };

  const getTrainerName = (teachers) => {
    if (Array.isArray(teachers) && teachers?.length) {
      const teacher = teachers.find((teacher) => teacher.isPrimary)?.name;
      return teacher;
    }

    return null;
  };

  const handleNavigation = (search) => {
    navigate(
      {
        pathname: isLoggedIn ? "/my-classes/about-teacher" : "about-teacher",
      },
      {
        state: {
          ...search,
          classId: state.selectedClass,
          backUrl: location.pathname,
        },
      }
    );
  };

  const selectedClassesIds = cartState.map(ele => ele._id);
  const classDetailData = classDetail?.data?.classDetail;
  let sortedCategoriesList = categoryList?.data?.list
    ? [
      ...defaultCategoryStart,
      ...categoryList?.data?.list,
      ...defaultCategoryEnd,
    ]
    : [];

  return (
    <>
      <div className="categories spacing">
        <div className="">
          {title && (
            <div className="page_title">
              <h3>Categories</h3>
            </div>
          )}

          <div className="categories_list">
            <ul>
              <li>
                <button
                  type="button"
                  className={state.selectedCategory === "all" && "active"}
                  onClick={() =>
                    setState((prev) => ({ ...prev, selectedCategory: "all" }))
                  }
                >
                  <span>All</span>
                </button>
              </li>
              {sortedCategoriesList
                ? sortedCategoriesList?.map((category) => {
                  return (
                    <li key={category._id}>
                      <button
                        type="button"
                        className={
                          state.selectedCategory === category._id && "active"
                        }
                        onClick={() =>
                          setState((prev) => ({
                            ...prev,
                            selectedCategory: category._id,
                          }))
                        }
                      >
                        {" "}
                        <span>{category.title}</span>
                      </button>
                    </li>
                  );
                })
                : null}
            </ul>
          </div>

          {/* {
            (classList?.data?.data?.length || search?.length)
              ? */}

          <div className="category_detail">
            <div className="class_list_wrap">
              <div className="class_list">
                <div className="_title">
                  <h5>Classes List</h5>
                  <div className="tag">
                    <b>6 Classes | 600/-</b>
                    <b className="color">View Cart</b>
                  </div>
                  <div className="search_wrap">
                    <input
                      type="search"
                      placeholder="Search for class name"
                      value={state.search}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          search: e.target.value,
                        }))
                      }
                    />
                    <img src={searchIcon} alt="" />
                  </div>
                </div>
                <ul>
                  {classList?.data?.data?.length ? (
                    classList?.data?.data?.map((classDetail) => {
                      return (
                        <li
                          key={classDetail._id}
                          className={
                            classDetail._id === state.selectedClass
                              ? "active"
                              : ""
                          }
                          onClick={() =>
                            setState((prev) => ({
                              ...prev,
                              selectedClass: classDetail._id,
                            }))
                          }
                        >
                          <span className="class_name">
                            <TypographyLimit
                              text={classDetail.classTitleEng}
                              limit={15}
                            />
                          </span>
                          <p>
                            <span className="class_time">
                              {get12HourTime(classDetail.time)}
                            </span>
                            <span className="class_type">
                              {classDetail?.isAdvanced ? "Advanced" : "Basic"}
                            </span>
                            <span className="class_for">
                              { }
                              <TypographyLimit
                                text={classDetail.group}
                                limit={5}
                              />
                            </span>
                            <span className="class_duration">
                              {classDetail.durationDay
                                ? `${classDetail.durationDay} Month`
                                : ""}
                            </span>
                            <span className="class_fee">
                              {classDetail.fees
                                ? `${classDetail.fees}/-`
                                : "Free"}
                            </span>
                            {isDateBefore25() ? null : (
                              <span className="class_seat">
                                {classDetail?.seats
                                  ? `${classDetail?.seats} Seats`
                                  : ""}
                              </span>
                            )}
                            <img src={arrowRightIcon} alt="Right arrow" />
                          </p>
                        </li>
                      );
                    })
                  ) : (
                    <NoData title={"We couldn't find any results"} desc={" "} />
                  )}
                </ul>
              </div>
            </div>

            <div
              className={
                isClassDetailLoading || !classList?.data?.data?.length
                  ? "class_detail_skeleton_wrap"
                  : "class_detail_wrap"
              }
            >
              {classList?.data?.data?.length ? (
                isClassDetailLoading ? (
                  <>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton button />
                  </>
                ) : (
                  <div className="class_detail">
                    <ul className="class_Feature">
                      <li className="blue">
                        {get12HourTime(classDetailData?.time)}
                      </li>
                      <li className="green">
                        {moment(classDetailData?.batchInfo?.batchStart).format(
                          "MMMM YYYY"
                        )}
                      </li>
                      {classDetailData?.new ? (
                        <li className="red">New</li>
                      ) : null}
                      {classDetailData?.isAdvanced ? (
                        <li className="purple">Advanced</li>
                      ) : null}
                      <li className="pink">{classDetailData?.group}</li>
                    </ul>
                    <div className="course_name">
                      <img
                        src={
                          classDetailData?.icon?.length
                            ? classDetailData.icon
                            : courseImg
                        }
                        alt=""
                      />
                      <div className="content">
                        <h4>{classDetailData?.classTitleEng}</h4>
                        <p>{classDetailData?.tagline}</p>
                        <ul>
                          <li className="_rating">
                            <span>4.9</span>
                            <GoStarFill />
                            <span>(127)</span>
                          </li>
                          {getTrainerName(classDetailData?.teachers) ? (
                            <li className="trainer">
                              <span>Trainer: </span>{" "}
                              <b>
                                {" "}
                                {getTrainerName(classDetailData?.teachers)}
                              </b>
                            </li>
                          ) : null}
                        </ul>
                      </div>
                    </div>
                    <ul className="course_info">
                      <li>
                        <img src={userIcon} alt="" />
                        {classDetailData?.age} yrs
                      </li>
                      <li>
                        <img src={calendarIcon} alt="" />
                        {
                          classDetailData?.days.map((day) => moment(day, "dddd").format("ddd"))
                            .join(", ")
                        }
                      </li>
                      <li>
                        <img src={medalIcon} alt="" />
                        Certificate
                      </li>
                      {
                        classDetailData?.language
                          ?
                          <li>
                            <img src={languageIcon} alt="" />
                            {classDetailData?.language ? classDetailData?.language?.join("/") : ""}
                          </li>
                          :
                          null
                      }
                      <li>
                        <img src={calenderCheckedIcon} alt="" />
                        {moment(classDetailData?.batchInfo?.batchStart).format(
                          "Do MMMM"
                        )}
                      </li>
                      <li>
                        <img src={monthIcon} alt="" />
                        Month
                      </li>
                      <li>
                        <img src={videoCameraIcon} alt="" />
                      </li>
                      <li>
                        <img src={watchIcon} alt="" />
                        {get12HourTime(classDetailData?.time)}
                      </li>
                      <li>
                        <img src={chatIcon} alt="" />
                        Chat
                      </li>
                    </ul>
                    <ul className="registration">
                      <li>
                        <b>
                          {classDetailData?.fees > 0
                            ? `${classDetailData?.fees}/-`
                            : "Free"}
                        </b>{" "}
                        <span className="availability">
                          {classDetailData?.initialSeats} Seats Available
                        </span>
                      </li>
                      <li>
                        {moment(new Date()).isSameOrAfter(
                          classDetailData?.batchInfo?.registrationStart
                        ) &&
                          moment(new Date()).isSameOrBefore(
                            classDetailData?.batchInfo?.registrationClose
                          ) ?
                          // classState?.purchasedClasses?.includes(currentClassId)
                          currentClassId === classDetailData?._id
                            ?
                            <></>
                            // <button type="button" className="button" onClick={() => handleSelectedClassDetail(classDetailData)}>
                            //   Change
                            // </button>
                            :
                            <>
                              <button type="button" className="button" onClick={() => handleSelectedClassDetail(classDetailData)}>
                                Change
                              </button>
                            </>
                          :
                          <span className="date">
                            {formatRegistrationDate(
                              classDetailData?.batchInfo?.registrationStart,
                              classDetailData?.batchInfo?.registrationClose
                            )}
                          </span>
                        }
                      </li>
                    </ul>
                    <ul className="urls">
                      {classDetailData?.teachers?.length ? (
                        <li
                          onClick={() =>
                            handleNavigation({
                              currentTab: "teacher",
                            })
                          }
                          style={{ cursor: "pointer" }}
                        >
                          About Teacher
                        </li>
                      ) : null}
                      {classDetailData?.syllabus?.isSyllabus ? (
                        <li
                          onClick={() =>
                            handleNavigation({
                              currentTab: "Syllabus",
                            })
                          }
                          style={{ cursor: "pointer" }}
                        >
                          Syllabus
                        </li>
                      ) : null}
                      {classDetailData?.studentWorkes?.isStudentWorkes ? (
                        <li
                          onClick={() =>
                            handleNavigation({
                              currentTab: "Student",
                            })
                          }
                          style={{ cursor: "pointer" }}
                        >
                          Student Works
                        </li>
                      ) : null}
                      {classDetailData?.requiredTools?.isRequiredTools ? (
                        <li
                          onClick={() =>
                            handleNavigation({
                              currentTab: "Required",
                            })
                          }
                          style={{ cursor: "pointer" }}
                        >
                          Required Tools
                        </li>
                      ) : null}
                    </ul>
                  </div>
                )
              ) : (
                <NoData title={"We couldn't find any results"} desc={" "} />
              )}
            </div>
          </div>

        </div>
      </div >
    </>
  );
};

export default AllClassesCategories;
