import { certificateImage } from "assets/images";
import { BackButton } from "components/form";
import React from "react";
import "./CertificateList.scss";
const CertificatesList = () => {
  return (
    <div className="certificate spacing">
      <div className="inner_container">
        <div className="_card">
          <div className="_card_content">
            <BackButton />
            <div className="sub_title">Certificates</div>
            <div className="certificate_listing">
              <div className="certificate_card">
                <img src={certificateImage} alt="" />
                <ul>
                  <li>
                    <span>Class Name</span>
                    <b>DRAWING</b>
                  </li>
                  <li>
                    <span>Batch</span>
                    <b>Jan 2024 to Fab 2024</b>
                  </li>
                </ul>

                <button type="button" className="button">
                  Download
                </button>
              </div>
              <div className="certificate_card">
                <img src={certificateImage} alt="" />
                <ul>
                  <li>
                    <span>Class Name</span>
                    <b>DRAWING</b>
                  </li>
                  <li>
                    <span>Batch</span>
                    <b>Jan 2024 to Fab 2024</b>
                  </li>
                </ul>

                <button type="button" className="button">
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CertificatesList;
