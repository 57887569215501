import React, { useState } from "react";
import "./Donation.scss";
import Helpline from "components/helpline/Helpline";
import { Link } from "react-router-dom";
import { backIcon } from "assets/icons";
import { BackButton } from "components/form";

const DonationTransaction = () => {
  return (
    <>
      <div className="donation_form spacing">
        <div className="inner_container">
          <div className="_card">
            <div className="_card_content">
              {/* <Link to={-1} className="back_button">
                <img src={backIcon} alt="back-icon" />
              </Link> */}
              <BackButton/>
              <div className="transaction_list">
                <h5 className="sub_title">Transaction History</h5>

                <ul>
                  <li>
                    <b>Transaction ID</b>
                    <b>Type</b>
                    <b>Amount</b>
                    <b>Date and Time</b>
                    <b>Status</b>
                  </li>
                  <li>
                    <span>FG001234</span>
                    <span>Donation</span>
                    <span>500</span>
                    <span>500</span>
                    <span>Success</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Helpline />
    </>
  );
};

export default DonationTransaction;
