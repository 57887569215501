import { searchIcon } from "assets/icons";
import FlatList from "components/FlatList";
import useDebounce from "hooks/useDebounce";
import React, { useEffect, useState } from "react";
import { Placeholder } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedClass } from "../../redux/slices/chat";
import { textLimit, timeFormat } from "utils/common";

const ClassList = ({ fetchAgain, socket, isConnected }) => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const debouncedValue = useDebounce(search, 400);

  const { selectedClass } = useSelector((state) => state.chat);

  const dispatch = useDispatch();

  const [classList, setClassList] = useState([]);

  const fetchClassList = () => {
    setLoading(true);
    socket.emit("getSidebarData", {
      searchQuery: debouncedValue,
    });
    socket.on("sidebarData", (data) => {
      setClassList(data || []);
      setLoading(false);
    });
  };

  // selection handler
  const classSelectionHandler = (params = {}) => {
    const { roomId, chatName, chatNumber, chatType, is_online } = params;
    dispatch(
      setSelectedClass({ roomId, chatName, chatNumber, chatType, is_online })
    );
  };

  // its runs only once
  useEffect(() => {
    if (isConnected) {
      fetchClassList();
    }
    // eslint-disable-next-line
  }, [isConnected, fetchAgain, debouncedValue]);

  // useEffect(() => {
  //   if (isConnected) {
  //     fetchUsers();
  //   }
  //   // eslint-disable-next-line
  // }, []);

  return (
    <>
      <div className="chatNav">
        <div className="chat_search_container">
          <div className="search_wrap">
            <input
              type="search"
              placeholder="Search for class name"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <img src={searchIcon} alt="" />
          </div>
        </div>
        <div className="_card">
          <div className="_card_content">
            <FlatList
              data={classList}
              renderItem={(it) => {
                return (
                  <div
                    className={`list_name ${
                      it.roomId === selectedClass.roomId
                    }`}
                    key={it._id}
                    onClick={() => classSelectionHandler(it)}
                  >
                    <div className="_card">
                      <div className="_card_content">
                        <div className="name_width_chat">
                          <div className="profile_dp">
                            {/* <img src="" alt="" /> */}
                            <span className="profileName">
                              {it.chatName?.at(0)}
                            </span>
                            {+it.is_online === 1 && (
                              <span className="active_Icon"></span>
                            )}
                          </div>
                          <p>
                            <b>{textLimit(it.chatName, 18)}</b>
                            <span>{textLimit(it.latestMessage, 18)}</span>
                          </p>
                        </div>
                        <div className="unread_number">
                          <span className="time">
                            {timeFormat(it.updatedAt)}
                          </span>
                          {it.unreadCount > 0 && (
                            <span className="unread">{it.unreadCount}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
              placeholder={<h4>No Data Found</h4>}
              loading={loading}
              loadingItem={(i) => {
                return (
                  <div className={`list_name loading`} key={i}>
                    <div className="_card">
                      <div className="_card_content">
                        <div className="name_width_chat">
                          <div className="profile_dp">
                            <span className="profileName">
                              <Placeholder />
                            </span>
                          </div>
                          <p>
                            <Placeholder />
                            <Placeholder />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ClassList;
