import React, { useState } from "react";

const TypographyLimit = ({ text, limit, enableReadMore }) => {
  const [showFullText, setShowFullText] = useState(false);

  return showFullText ? (
    <>{text}</>
  ) : text?.length > limit ? (
    <>
      {text?.slice(0, limit)}...
      {enableReadMore && (
        <button
          style={{
            lineHeight: "4px",
            color: "#252525",
            fontWeight: 400,
            lineHeight: 1.5,
            letterSpacing: "0.7px",
          }}
          onClick={() => setShowFullText((prev) => !prev)}
        >
          Read More
        </button>
      )}
    </>
  ) : (
    <>{text}</>
  );
};

export default TypographyLimit;
