import {
  contactIcon,
  hideEye,
  IdIcon,
  profileApplyJobIcon,
  profileCertificatesIcon,
  profileDeleteIcon,
  profileEditIcon,
  profileHelpIcon,
  profileLogoutIcon,
  profileManageIcon,
  profileTransactionIcon,
  showEye,
  verifiedIcon,
} from "assets/icons";
import { ProfileImg } from "assets/images";
import { Button, Input, Select } from "components/form";
import Helpline from "components/helpline/Helpline";
import CustomModal from "components/modals/CustomModal";
import useUser from "hooks/useUser";
import React, { useEffect, useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { Link } from "react-router-dom";

import UpdateProfile from "./UpdateProfile";
import GradientCircularProgressbar from "components/CircularProgressbar/GradientCircularProgressbar ";
import { useMutation, useQuery } from "react-query";
import { SettingService } from "services";
import useSnackbar from "hooks/useSnackbar";

const MyProfile = () => {
  const percentage = 66;
  const { user, logout } = useUser();
  const { snackbar } = useSnackbar();
  const { getReasonsList, deleteAccount } = SettingService();
  const [editProfileModal, setEditProfileModal] = useState(false);
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [showMobile, setShowMobile] = useState(false);
  const [state, setState] = useState({
    reason: "",
    reasonError: "",
    showReasonField: false,
    reasonFieldText: "",
    reasonFieldTextError: "",
  });

  useEffect(() => {
    if (!deleteAccountModal) {
      setState((prev) => ({ ...prev, reason: "", reasonError: "" }));
    }
  }, [deleteAccountModal]);

  const { data: reasonsList } = useQuery({
    queryKey: ["reasons-list"],
    queryFn: async () =>
      getReasonsList({
        token: localStorage.getItem("fg_token"),
      }),
  });

  const deleteAccountMutation = useMutation(
    () => {
      if (!state.reason.length) {
        setState((prev) => ({ ...prev, reasonError: "Please select reason" }));
        return;
      }

      if (state.reason === "other" && !state.reasonFieldText.length) {
        setState((prev) => ({
          ...prev,
          reasonFieldTextError: "Please enter reason",
        }));
        return;
      }

      const payload = {
        reason: state.reason === "other" ? state.reasonFieldText : state.reason,
        token: localStorage.getItem("fg_token"),
      };
      return deleteAccount(payload);
    },
    {
      onSuccess: (data) => {
        if (data) {
          logout();
          snackbar(data?.message || "");
        }
      },
      onError: (err) => {
        snackbar(err?.data?.message || "");
      },
    }
  );

  return (
    <>
      <div className="student_Access container">
        <div className="my_profile spacing">
          <div className="profile_image">
            <img
              src={user.profile.length ? user.profile : ProfileImg}
              alt="User Name"
              className="user_img"
            />
            <p>
              <b>{user?.name}</b> <img src={verifiedIcon} alt="" />
            </p>
          </div>
          <div className="contact_info">
            <ul>
              <li>
                <img src={IdIcon} alt="" />
                <p className="_card">
                  <span>
                    {user.roles?.includes("student") ? user.studentNumber : ""}
                  </span>
                </p>
              </li>
              <li>
                <img src={contactIcon} alt="" />
                <p className="_card">
                  <span>
                    {showMobile
                      ? user?.mobile
                      : "xxxxxxxx" + user?.mobile.slice(-2)}
                    <img
                      src={showMobile ? showEye : hideEye}
                      alt=""
                      onClick={() => setShowMobile((prev) => !prev)}
                    />
                  </span>
                </p>
              </li>
            </ul>
          </div>

          <div className="card_wrap spacing">
            <div className="_card">
              <div className="_card_content">
                <h4>Total Registered</h4>
                <div className="progress_wrap">
                  <GradientCircularProgressbar
                    id={"one"}
                    percentageValue={66}
                    textValue={66}
                    color1={"#473DCE"}
                    color2={"#5B9FF3"}
                    color3={"#5B9FF3"}
                    textColor={"#3B82F6"}
                  />
                </div>
              </div>
            </div>
            <div className="_card">
              <div className="_card_content">
                <h4>Total Completed</h4>
                <div className="progress_wrap">
                  <GradientCircularProgressbar
                    id={"two"}
                    percentageValue={30}
                    textValue={30}
                    color1={"#B0FF4B"}
                    color2={"#11876B"}
                    color3={"#11876B"}
                    textColor={"#11876B"}
                  />
                </div>
              </div>
            </div>
            <div className="_card">
              <div className="_card_content">
                <h4>Total Inprogress</h4>
                <div className="progress_wrap">
                  <GradientCircularProgressbar
                    id={"three"}
                    percentageValue={40}
                    textValue={46}
                    color1={"#B0FF4B"}
                    color2={"#11876B"}
                    color3={"#11876B"}
                    textColor={"#11876B"}
                  />
                </div>
              </div>
            </div>
            <div className="_card">
              <div className="_card_content">
                <h4>Total Certified</h4>
                <div className="progress_wrap">
                  <GradientCircularProgressbar
                    id={"four"}
                    percentageValue={55}
                    textValue={55}
                    color1={"#B0FF4B"}
                    color2={"#11876B"}
                    color3={"#11876B"}
                    textColor={"#11876B"}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="action_card spacing">
            <div className="_card" onClick={() => setEditProfileModal(true)}>
              <div className="_card_content">
                <h4>Edit Profile</h4>
                <img src={profileEditIcon} alt="" />
              </div>
            </div>
            <div className="_card">
              <div className="_card_content _manage">
                <Link to="manage-profile"></Link>
                <h4>Manage Profile</h4>
                <img src={profileManageIcon} alt="" />
              </div>
            </div>
            <div className="_card">
              <div className="_card_content">
                <Link to={`/profile/student/certificates`}></Link>
                <h4>Certificates</h4>
                <img src={profileCertificatesIcon} alt="" />
              </div>
            </div>
            <div className="_card">
              <div className="_card_content _help">
                <Link to="/profile/help"></Link>
                <h4>Help</h4>
                <img src={profileHelpIcon} alt="" />
              </div>
            </div>
            <div className="_card" onClick={() => setLogoutModal(true)}>
              <div className="_card_content _logout">
                <h4>Logout</h4>
                <img src={profileLogoutIcon} alt="" />
              </div>
            </div>
            <div className="_card" onClick={() => setDeleteAccountModal(true)}>
              <div className="_card_content">
                <h4>Delete Account</h4>
                <img src={profileDeleteIcon} alt="" />
              </div>
            </div>
            <div className="_card">
              <div className="_card_content _job">
                <Link to="/jobs"></Link>
                <h4>Apply for Job</h4>
                <img src={profileApplyJobIcon} alt="" />
              </div>
            </div>
            <div className="_card">
              <div className="_card_content">
                <Link to="/donation/transaction"></Link>
                <h4>Transaction History</h4>
                <img src={profileTransactionIcon} alt="" />
              </div>
            </div>
          </div>
          <Helpline />
        </div>
      </div>

      {/* Delete Account */}
      <CustomModal
        show={deleteAccountModal}
        handleClose={() => setDeleteAccountModal(false)}
        className={" editProfileModal deleteAccountModal"}
      >
        <h3>Delete Account</h3>
        <p>Are You Sure, You Want To Delete Account?</p>
        <div className="form_field">
          <Select
            label="Select Reason"
            onChange={(e) => {
              console.log({ e: e.target.value });

              if (String(e.target.value).toLocaleLowerCase() === "other") {
                setState((prev) => ({
                  ...prev,
                  reason: String(e.target.value).toLocaleLowerCase(),
                  showReasonField: true,
                }));
              } else {
                setState((prev) => ({
                  ...prev,
                  reason: e.target.value,
                  showReasonField: false,
                }));
              }
            }}
          >
            <option value={""}>Select Reason</option>
            {reasonsList?.data?.reasonList?.map((reason) => {
              return <option value={reason.title}>{reason.title}</option>;
            })}
            <option value="other">Other, Please explain</option>
          </Select>
          {!state.reason?.length ? <span>{state.reasonError}</span> : null}
        </div>
        {state.showReasonField ? (
          <div className="form_field">
            <Input
              name="reasonFieldText"
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  reasonFieldText: e.target.value,
                }))
              }
              value={state.reasonFieldText}
              placeholder="Reason for deleting account"
              maxLength={100}
            />

            {!state.reasonFieldText?.length ? (
              <span>{state.reasonFieldTextError}</span>
            ) : null}
          </div>
        ) : null}
        <p className="note">
          Note:{" "}
          <span>
            If you delete this account, all associated data{" "}
            <span style={{ color: "#FF3B30" }}>and balance(if you have)</span>{" "}
            will be <span style={{ color: "#FF3B30" }}>removed.</span>
          </span>
        </p>
        <div className="button_wrap button_center">
          <Button
            onClick={deleteAccountMutation.mutate}
            isDisabled={deleteAccountMutation.isLoading}
          >
            Delete
          </Button>
        </div>
      </CustomModal>

      {/* Logout Modal */}
      <CustomModal
        show={logoutModal}
        handleClose={() => setLogoutModal(false)}
        className={"logoutModal"}
      >
        <img src={profileLogoutIcon} alt="" />
        <h4>Are you sure you want to logout?</h4>

        <duv className="button_wrap button_center">
          <Button onClick={logout}>Yes, Exit</Button>
        </duv>
      </CustomModal>

      <UpdateProfile
        editProfileModal={editProfileModal}
        setEditProfileModal={setEditProfileModal}
      />
    </>
  );
};

export default MyProfile;
