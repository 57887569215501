import React from "react";

const Text = ({ message = {}, selectedClass = {} }) => {
  return (
    <>
      <div className="profile_dp">
        <span className="profileName">{selectedClass?.chatName?.at(0)}</span>
      </div>
      <div className="_card">
        <div className="_card_content">
          <b>
            {message?.senderId?.studentNumber} - {message?.senderId?.name} -
            {message?.senderId?.roles?.map((role, i, arr) => {
              return `${role} ${i === arr.length - 1 ? "" : " | "}`;
            })}
          </b>
          <p>{message.message}</p>
        </div>
      </div>
    </>
  );
};

export default Text;
