import useUser from "hooks/useUser";
import "./FAQs.scss";
import React from "react";
import { Accordion } from "react-bootstrap";
import { useQuery } from "react-query";

import { SettingService } from "services";

const Faqs = ({ screenType, type }) => {
  const { getFaqs } = SettingService();
  const { data } = useQuery({
    queryKey: ["faqs"],
    queryFn: async () =>
      getFaqs({
        token: localStorage.getItem("fg_token"),
        screenType: screenType ?? "allClasses",
        type: type ?? "student"
      }),
  });

  const faqs = data?.data?.faq;

  return (
    <>
      {
        faqs?.length
          ?
          < div className="faq spacing">
            <div className="container">
              <div className="page_title">
                <h3>Frequently Asked Questions (FAQs)</h3>
              </div>
              <div className="accordion_wrap">
                <Accordion>
                  {
                    faqs?.map((faq, i) => {
                      return <div key={i} className="accordion-item-wrap _card">
                        <Accordion.Item eventKey={i}>
                          <Accordion.Header>
                            {faq?.title}
                          </Accordion.Header>
                          <Accordion.Body>
                            {faq?.description}
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>
                    })
                  }
                </Accordion>
              </div>
            </div>
          </div >
          :
          null
      }
    </>
  );
};

export default Faqs;
