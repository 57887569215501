import HttpService from "services/http";

const CartService = () => {
    const { httpRequest, httpFormRequest } = HttpService();

    const getCartList = (search) => new Promise((resolve, reject) => {
        httpRequest("GET", "user/cart/list", {}, search)
            .then(resolve)
            .catch(reject);
    });

    const addToCart = (payload) => new Promise((resolve, reject) => {
        httpFormRequest("POST", "user/cart/add", payload)
            .then(resolve)
            .catch(reject);
    });

    return { addToCart, getCartList };
}

export { CartService };