import { heartIcon } from "assets/icons";
import { QRCodeImg } from "assets/images";
import { Button, Input } from "components/form";
import React from "react";
import { joiResolver } from "@hookform/resolvers/joi";
import { useForm } from 'react-hook-form';
import { useMutation } from "react-query";

import { donationAnonymous } from "validations";
import { DonationService } from "services";
import useSnackbar from "hooks/useSnackbar";
import { generateFormDataPayload } from "utils/helper";

const DonationAnonymous = () => {
  const { snackbar } = useSnackbar();
  const { createDonation } = DonationService();
  const { control, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: joiResolver(donationAnonymous),
    defaultValues: {
      amount: "",
    }
  });

  const handleDonation = useMutation(data => {
    const payload = {
      donationType: "Anonymous",
      platformType: "web",
      token: localStorage.getItem("fg_token"),
      ...data
    }
    return createDonation(generateFormDataPayload(payload))
  }, {
    onSuccess: (data) => {
      snackbar((data?.message || ""), "info");
      reset();
    },
    onError: (err) => {
      snackbar((err.data.message || ""));
    }
  });

  return (
    <>
      <form onSubmit={handleSubmit(handleDonation.mutate)}>
        <Input placeholder="Amount(In Rupees)" type="number" name="amount" control={control} error={errors.amount?.message} />
        <span className="or">(OR)</span>
        <img src={QRCodeImg} alt="" className="qrCode" />
        <span className="bankName">Banking Name : K R Ramesh Reddy</span>
        <div className="button_wrap button_center">
          <Button type="submit">
            <img src={heartIcon} alt="" />
            DONATE
          </Button>
        </div>
      </form>
    </>
  );
};

export default DonationAnonymous;
