import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const GradientCircularProgressbar = ({
  percentageValue,
  color1,
  color2,
  color3,
  textColor,
  textSize,
  id,
  offset1,
  offset2,
  offset3,
  textValue
}) => {
  // const percentage = percentageValue;

  return (
    <div className="circleProgress">
      <svg style={{ height: 0 }}>
        <defs>
          <linearGradient id={id} x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset={offset1 ? offset1 : "10%"} stopColor={color1} />
            <stop offset={offset2 ? offset2 : "100%"} stopColor={color2} />
            <stop offset={offset3 ? offset3 : "-10%"} stopColor={color3} />
          </linearGradient>
        </defs>
      </svg>

      <CircularProgressbar
        value={percentageValue}
        text={textValue}
        styles={buildStyles({
          pathColor: `url(#${id})`,
          textColor: textColor,
          textSize: textSize,
          strokeLinecap: "round",
          trailColor: "#eee",
        })}
      />
    </div>
  );
};

export default GradientCircularProgressbar;
