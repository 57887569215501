import React, { useEffect, useState } from "react";
import { contactIcon, FormExperienceIcon, FormTailoringIcon, IdIcon, nameIcon } from "assets/icons";
import { addProfilePic, ProfileImg } from "assets/images";
import { BackButton, Input } from "components/form";
import { useForm } from 'react-hook-form';
import { joiResolver } from "@hookform/resolvers/joi";
import { useMutation } from "react-query";

import useUser from 'hooks/useUser';
import AuthOTP from "pages/auth/AuthOTP";
import useSnackbar from 'hooks/useSnackbar';
import CustomModal from "components/modals/CustomModal";
import { updateTeacherProfileValidation } from 'validations';
import { generateFormDataPayload } from "utils/helper";
import { AuthService, UserService } from 'services';
import { useNavigate } from "react-router-dom";
import ImageCropper from "components/image-cropper";

const TeacherEditProfile = () => {
  const { user, setUser } = useUser();
  const navigate = useNavigate();
  const { sendOtp } = AuthService();
  const { snackbar } = useSnackbar();
  const { updateProfile: updateProfileApi } = UserService();
  const { control, handleSubmit, setValue, watch, formState: { errors } } = useForm({
    resolver: joiResolver(updateTeacherProfileValidation),
    defaultValues: {
      name: "",
      mobile: "",
      experience: "",
      subject: "",
    }
  });
  const [state, setState] = useState({
    name: "",
    mobile: "",
    profile: "",
    experience: "",
    subject: "",
    showVerifyOtpModal: false
  });

  const profile = watch("profile");
  const updateProfile = useMutation(data => {
    const { numberVerify, ...other } = data;

    const payload = generateFormDataPayload({
      token: localStorage.getItem("fg_token"),
      countryCode: "+91",
      ...other
    }, "profile");

    if (data.mobile !== user.mobile && !numberVerify) {
      setState(prev => ({
        ...prev,
        ...data,
        countryCode: "+91",
        showVerifyOtpModal: true
      }))
      return sendOtp({
        countryCode: "+91",
        mobile: data.mobile
      });
    }

    return updateProfileApi(payload);
  }, {
    onSuccess: (data) => {
      if (data) {
        const { name, mobile, profile, subject, experience } = data.data.userData;
        setUser({ name, mobile, profile, subject, experience })
        snackbar((data?.message || ""), "info");
        navigate("/profile");
      }
    },
    onError: (err) => {
      snackbar((err.data.message || ""));
    }
  });

  useEffect(() => {
    if (user?._id?.length) {
      setValue("name", user?.name);
      setValue("mobile", user?.mobile);
      setValue("experience", user?.experience);
      setValue("subject", user?.subject);
    }
  }, [user]);

  return (
    <>
      <div className="teacher_edit_profile spacing">
        <div className="inner_container">
          <div className="_card">
            <div className="_card_content">
              <BackButton />
              <div className="sub_title">Edit Profile</div>

              <div className="edit_from_details">
                <form onSubmit={handleSubmit(data => updateProfile.mutate({ ...data, numberVerify: false }))}>
                  {/* <div className="profilePic">
                    <img src={profile?.name ? URL.createObjectURL(profile) : user.profile?.length ? user.profile : ProfileImg} alt="" className="profile" />
                    <label htmlFor="upload_profile" type="button">
                      <img src={addProfilePic} alt="" className="add_img" />
                      <input
                        type="file"
                        id="upload_profile"
                        className="uploadInput"
                        onChange={e => setValue("profile", e.target.files[0])}
                      />
                    </label>
                  </div> */}

                  <ImageCropper
                    prevImage={
                      profile?.name
                        ? profile
                        : user.profile?.length
                          ? user.profile
                          : null
                    }
                    imageSelect={e => setValue("profile", e)}
                  />

                  <div className="form_field ">
                    <Input
                      placeholder=" Enter Name"
                      name="name"
                      control={control}
                      error={errors?.name?.message}
                    />
                    <span className="country">
                      <img src={nameIcon} alt="" />
                    </span>
                  </div>
                  <div className="form_field ">
                    <Input placeholder="Enter Id" value={(`${user?.studentNumber}/${user?.teacherNumber}`)} disabled />
                    <span className="country">
                      <img src={IdIcon} alt="" />
                    </span>
                  </div>
                  <div className="form_field">
                    <Input
                      placeholder=" Enter India  Mobile Number"
                      name="mobile"
                      control={control}
                      error={errors?.mobile?.message}
                      maxLength={"10"}
                    />
                    <span className="country">
                      <img src={contactIcon} alt="" />
                    </span>
                  </div>

                  <div className="form_field">
                    <Input
                      placeholder=" 2 Year Experience"
                      type="number"
                      name="experience"
                      disabled
                      maxLength={2}
                      control={control}
                      error={errors?.experience?.message}
                    />
                    <span className="country">
                      <img src={FormExperienceIcon} alt="" />
                    </span>
                  </div>

                  <div className="form_field">
                    <Input
                      placeholder="Tailoring"
                      name="subject"
                      control={control}
                      error={errors?.subject?.message}
                    />
                    <span className="country">
                      <img src={FormTailoringIcon} alt="" />
                    </span>
                  </div>

                  <div className="button_wrap button_center">
                    <button type="submit" className="button w-100" disabled={updateProfile.isLoading}>
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* verify Otp  */}
      <CustomModal
        show={state.showVerifyOtpModal}
        handleClose={() => setState(prev => ({ ...prev, showVerifyOtpModal: false }))}
        className={"authModal"}
      >
        <AuthOTP
          closeAuthModel={() => { }}
          setLoginSteps={() => { }}
          updateMobile={(isSuccess) => {
            setState(prev => ({ ...prev, showVerifyOtpModal: false }));
            if (isSuccess) {
              updateProfile.mutate({
                name: state.name,
                mobile: state.mobile,
                profile: state.profile,
                subject: state.subject,
                experience: state.experience,
                numberVerify: true
              });
            }
          }}
          credentials={
            {
              type: "web",
              deviceToken: "12345",
              countryCode: "+91",
              mobile: state.mobile
            }
          }
        />
      </CustomModal>
    </>
  );
};

export default TeacherEditProfile;
