import Joi from "joi";

export const updateStudentProfileValidation = Joi.object({
  name: Joi.string().required().label("Name").messages({
    "string.empty": "{{#label}} is required",
  }),
  profile: Joi.object().optional().label("Photo").allow("").messages({
    "string.empty": "{{#label}} is required",
  }),
  mobile: Joi.string()
    .required()
    .label("Mobile Number")
    .min(10)
    .max(10)
    .messages({
      "string.empty": "{{#label}} is required",
    }),
});

export const updateTeacherProfileValidation = Joi.object({
  name: Joi.string().required().label("Name").messages({
    "string.empty": "{{#label}} is required",
  }),
  experience: Joi.number().required().label("Experience").messages({
    "string.empty": "{{#label}} is required",
  }),
  subject: Joi.string().required().label("Subject").messages({
    "string.empty": "{{#label}} is required",
  }),
  profile: Joi.object().optional().label("Profile").allow("").messages({
    "string.empty": "{{#label}} is required",
  }),
  mobile: Joi.string()
    .required()
    .label("Mobile Number")
    .min(10)
    .max(10)
    .messages({
      "string.empty": "{{#label}} is required",
    }),
});

export const addBankValidation = Joi.object({
  accountHolderName: Joi.string()
    .required()
    .label("Account Holder Name")
    .messages({
      "string.empty": "{{#label}} is required",
    }),
  accountNumber: Joi.number().required().label("Account Number").messages({
    "string.empty": "{{#label}} is required",
  }),
  ifscCode: Joi.string().required().label("IFSC Code").messages({
    "string.empty": "{{#label}} is required",
  }),
  bankName: Joi.string().required().label("bankName").messages({
    "string.empty": "{{#label}} is required",
  }),
  email: Joi.string().required().label("Email").messages({
    "string.empty": "{{#label}} is required",
  }),
});

export const addPostalValidation = Joi.object({
  street: Joi.string().required().label("Street").messages({
    "string.empty": "{{#label}} is required",
  }),
  city: Joi.string().required().label("City").messages({
    "string.empty": "{{#label}} is required",
  }),
  state: Joi.string().required().label("state").messages({
    "string.empty": "{{#label}} is required",
  }),
  country: Joi.string().allow("").optional().label("Country").messages({
    "string.empty": "{{#label}} is required",
  }),
  postalCode: Joi.string().required().label("Postal Code").messages({
    "string.empty": "{{#label}} is required",
  }),
});
