import { certificateImage } from "assets/images";
import { LoadingButton } from "components/form";
import React from "react";

const DownloadCertificate = ({ certificate }) => {
  return (
    <>
      <div className="certificate_wrap">
        <div className="certificate_card">
          <img src={certificateImage} alt="" />

          <div className="button_wrap">
            <LoadingButton disabled={certificate ? false : true} onClick={() => window.open(certificate, "_blank")}>
              Download
            </LoadingButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadCertificate;
