import React from "react";
import "./myCart.scss";
import Helpline from "components/helpline/Helpline";
import { cancelCircle, correctCircleIcon, playButton, rejectIcon } from "assets/icons";
import { Link } from "react-router-dom";

const FailedRegistration = () => {
  return (
    <>
      <div className="my_cart spacing">
        <div className="inner_container">
          <div className="_card">
            <div className="_card_content">
              <div className="rejection_content failed">
                <img src={cancelCircle} alt="" />
                <h5>Registration Failed!</h5>
                <p >
                  Lorem ipsum dolor sit amet consectetur. Morbi nunc arcu morbi
                  mollis duis at dignissim. Sit in libero nunc volutpat sagittis
                  tortor.
                </p>

                <div className="button_wrap">
                  <button type="button" className="button">
                  Retry Payment
                  </button>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>

      <Helpline />
    </>
  );
};

export default FailedRegistration;
