import { LoadingButton } from "components/form";
import React from "react";

const GroupInfo = () => {
  return (
    <>
      <div className="group_info">
        <h4>Group Info</h4>
        <ul>
          <li>
            <b>Chat Name</b>
            <span>6pm-Basics-Drawing Class-Radhika Teacher</span>
          </li>
          <li>
            <b>Class Name</b>
            <span>DRAWING</span>
          </li>
          <li>
            <b>Batch</b>
            <span>Jan 2024 to Fab 2024</span>
          </li>
          <li>
            <b>Teacher Name</b>
            <span>Raja Ramesh Reddy</span>
          </li>
        </ul>
        <div className="button_wrap button_left">
          <LoadingButton>Exit</LoadingButton>
        </div>
      </div>
    </>
  );
};

export default GroupInfo;
