import HttpService from "services/http";

const NotificationService = () => {
    const { httpRequest } = HttpService();

    const getNotifications = (search) => new Promise((resolve, reject) => {
        httpRequest("GET", "user/setting/notification_list", {}, search)
            .then(resolve)
            .catch(reject);
    });

    const clearAllNotifications = (search) => new Promise((resolve, reject) => {
        httpRequest("GET", "user/setting/notification_remove", {}, search)
            .then(resolve)
            .catch(reject);
    });


    return { getNotifications, clearAllNotifications };
}
export { NotificationService };