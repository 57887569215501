import useUser from "hooks/useUser";
import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import { AttendanceService } from "services";

const color = {
  "absent": "leave",
  "present": "present",
  "holiday": "holiday",
}

const MyAttendance = ({ classId }) => {
  const { user } = useUser();
  const { getAttendanceList } = AttendanceService();

  const { data: attendanceList, isLoading } = useQuery({
    queryKey: ["attendance-list"],
    queryFn: async () =>
      getAttendanceList({
        studentId: user?._id,
        classId,
        token: localStorage.getItem("fg_token"),
      }),
  });

  return (
    <>
      <div className="attendance_list my_attendance">
        <div className="teacher_attendance">
          <ul>
            {
              attendanceList?.data?.attendanceList?.map(attendance => {
                return <li>
                  <p>{moment(attendance.createdAt).format("Do MMM - dddd")}</p>
                  <span className={color[attendance?.status]}>{attendance?.status}</span>
                </li>
              })

            }
            {/* <li>
              <p>1st Apr - Tuesday</p>
              <span className="present">Present</span>
            </li>
            <li>
              <p>2nd Apr - Wednesday</p>
              <span className="holiday">Holiday</span>
            </li>
            <li>
              <p>2nd Apr - Wednesday</p>
              <span className="leave">Leave</span>
            </li>
            <li>
              <p>4th Apr - Friday</p>
              <span className="present">Present</span>
            </li>
            <li>
              <p>5th Apr - Saturday</p>
              <span className="holiday">Holiday</span>
            </li> */}
          </ul>
          <p className="total">
            <span className="orange">Total Classes : {attendanceList?.data?.totalClasses}</span>
            <span className="green">Attendance : {attendanceList?.data?.totalPresent}</span>
            <span className="red">Absent : {attendanceList?.data?.totalAbsent}</span>
          </p>
        </div>

      </div>
    </>
  );
};

export default MyAttendance;
