import { createSlice } from '@reduxjs/toolkit';

const initialState = { purchasedClasses: [] }

export const classSlice = createSlice({
    name: 'class',
    initialState,
    reducers: {
        updatePurchasedClass: (state, action) => {
            return { ...state, purchasedClasses: action.payload }
        },
    },
})

export const { updatePurchasedClass } = classSlice.actions;

export default classSlice.reducer;