import HttpService from "services/http";

const HelpSupportService = () => {
    const { httpFormRequest } = HttpService();

    const addComplaint = (payload) => new Promise((resolve, reject) => {
        httpFormRequest("POST", "user/help_support/complain_sent", payload)
            .then(resolve)
            .catch(reject);
    });

    return { addComplaint };
}

export { HelpSupportService };