import React from "react";

const StudentWork = () => {
  return (
    <>
      <div className="">
        <h1>StudentWork </h1>
      </div>
    </>
  );
};

export default StudentWork;
