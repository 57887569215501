import HttpService from "services/http";

const DonationService = () => {
    const { httpFormRequest } = HttpService();

    const createDonation = (payload) => new Promise((resolve, reject) => {
        httpFormRequest("POST", "user/donation/create", payload)
            .then(resolve)
            .catch(reject);
    });

    return { createDonation };
}

export { DonationService };