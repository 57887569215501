import { initializeApp } from "firebase/app";
import {
  deleteToken,
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDzpM2fsS1UYZHM_ASUqk7p95Rkvmf-ml0",
  authDomain: "freegurukul-c6a7a.firebaseapp.com",
  databaseURL: "https://freegurukul-c6a7a.firebaseio.com",
  projectId: "freegurukul-c6a7a",
  storageBucket: "freegurukul-c6a7a.appspot.com",
  messagingSenderId: "577265363048",
  appId: "1:577265363048:web:a858fd3680ec49357033dd",
  measurementId: "G-EJ8RWTLHDL",
};

const firebaseApp = initializeApp(firebaseConfig);

const isFirebaseSupported = async () => {
  const supported = await isSupported(); // imported from 'firebase/messaging'
  return supported ? getMessaging(firebaseApp) : null;
};

const messaging = await isFirebaseSupported();

const isBrowserSupported = () => {
  return (
    "Notification" in window &&
    "serviceWorker" in navigator &&
    "PushManager" in window
  );
};

export const fetchToken = async () => {
  //requesting permission using Notification API
  const permission = isBrowserSupported()
    ? await Notification.requestPermission()
    : "";

  if (permission === "granted") {
    const registration = await navigator.serviceWorker.register(
      "../../firebase-messaging-sw.js"
    );

    // Wait for serviceWorker.ready
    await navigator.serviceWorker.ready;

    const token = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      serviceWorkerRegistration: registration,
    });

    return token;
  } else if (permission === "denied") {
    //notifications are blocked
    console.log("You denied for the notification");
  }
};

// Delete FCM token function
export const deleteFcmToken = async () => {
  try {
    // Request the current token
    const currentToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    });

    if (currentToken) {
      // Delete the token
      await deleteToken(messaging);
    } else {
    }
  } catch (error) {
    console.error("Unable to delete FCM token:", error);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
