import {  courseImg2 } from "assets/images";
import React from "react";
import { FaCaretRight } from "react-icons/fa";
import Skeleton from "components/skeleton";

const StudentWorks = ({data, isLoading}) => {
  return (
    <>
      <div className="Syllabus">
      {
          !isLoading
            ?
            <p
              dangerouslySetInnerHTML={{ __html: data }}
            />
            :
            <Skeleton />
        }
      </div>
    </>
  );
};

export default StudentWorks;
