import moment from "moment";

export const charLimit = (char, limit) => {
  char?.length > limit ? (
    <>
      {char?.slice(limit)}...<button>Read more</button>
    </>
  ) : (
    <>{char}</>
  );
};

export const verifyEmail = (value = "") => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

  return emailRegex.test(value);
};

export const provideOnlyNumbers = (value = "") => {
  return value.replace(/\D/g, "");
};

// accept string only
export const provideOnlyStrings = (value = "") => {
  return value.replace(/[^A-Za-z. ]/gi, "").replace(/\s+/g, " ");
};

export const provideIfscString = (value = "") => {
  return value.replace(/[^A-Za-z0-9]/g, "");
};

export const allowAlphaNumDotUnderscore = (value = "") => {
  return value.replace(/[^A-Za-z0-9._@-]/g, "");
};

export const allowAlphaNumSomeSpecial = (value = "") => {
  return value.replace(/[^A-Za-z0-9:/._-]/g, "");
};

export const provideNonSpecialValue = (value = "") => {
  return value.replace(/[^\w\s\u0600-\u06FF]|_/g, "");
};

export const textLimit = (text, limit, isDot = true) => {
  if (text?.length > limit) {
    return `${text?.slice(0, limit)}  ${isDot ? "..." : ""}`;
  } else {
    return text;
  }
};

export function timeFormat(date) {
  return moment(date).format("h:mm a");
}

export function countArray(limit = 0) {
  let arr = [];
  for (let i = 1; i <= limit; i++) {
    arr.push(i);
  }
  return arr;
}
