import { BackButton } from "components/form";
import React, { useState } from "react";
import "./myCart.scss";
import Helpline from "components/helpline/Helpline";
import { Link, useNavigate } from "react-router-dom";
import { updateCartCount, useAppDispatch, useAppSelector } from "../../redux";
import TypographyLimit from "components/TypographyLimit";
import { generateFormDataPayload, get12HourTime } from "utils/helper";
import { useMemo } from "react";
import NoData from "components/no-data";
import { useMutation } from "react-query";
import { CartService } from "services/cart";
import { ClassService } from "services";

const MyCart = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { addToCart } = CartService();
  const { purchaseClass } = ClassService();
  const [useBalance, setUseBalance] = useState(false);
  const cartState = useAppSelector((state) => state.cartSlice?.data);

  // Remove from cart
  const removeFromCartMutation = useMutation(
    (data) => {
      const payload = generateFormDataPayload({
        ...data,
        token: localStorage.getItem("fg_token"),
      });
      return addToCart(payload);
    },
    {
      onSuccess: (data) => {
        dispatch(updateCartCount(data?.data?.cartItems?.totalCartItemCount));
      },
    }
  );

  // Purchase Class
  const purchaseClassMutation = useMutation(
    () => {
      const payload = generateFormDataPayload({
        token: localStorage.getItem("fg_token"),
        useBalance,
      });
      return purchaseClass(payload);
    },
    {
      onSuccess: () => {
        dispatch(updateCartCount(0));
        navigate("/cart/registration");
      },
    }
  );

  const classInfo = useMemo(() => {
    let price = 0;
    let free = 0;
    let paid = 0;
    cartState.forEach((item) => {
      if (item.fees) {
        price += item.fees;
        paid += 1;
      } else {
        free += 1;
      }
    });

    return { price, free, paid };
  }, [cartState]);

  return (
    <>
      <div className="my_cart spacing">
        <div className="inner_container">
          <div className="_card">
            <div className="_card_content">
              <BackButton />
              <h5 className="sub_title black">My Cart</h5>
              {cartState.length ? (
                <>
                  <div className="courses_list">
                    <ul className="list">
                      {cartState.map((classDetail) => {
                        return (
                          <li>
                            <span className="name">
                              <TypographyLimit
                                text={classDetail.classTitleEng}
                                limit={15}
                              />
                            </span>
                            <span className="time">
                              {get12HourTime(classDetail.time)}
                            </span>
                            <span className="level">
                              {classDetail?.isAdvanced ? "Advanced" : "Basic"}
                            </span>
                            <span className="format">
                              <TypographyLimit
                                text={classDetail.group}
                                limit={5}
                              />
                            </span>
                            <span className="duration">
                              {classDetail.durationDay
                                ? `${classDetail.durationDay} Month`
                                : ""}
                            </span>
                            <span className="fees">
                              {classDetail.fees
                                ? `${classDetail.fees}/-`
                                : "Free"}
                            </span>
                            <span>
                              <button
                                disabled={removeFromCartMutation.isLoading}
                                onClick={() =>
                                  removeFromCartMutation.mutate({
                                    classId: classDetail._id,
                                  })
                                }
                              >
                                Remove
                              </button>
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="payOption">
                    <div className="balance">
                      <ul>
                        <li>
                          <label>
                            <input
                              type="checkbox"
                              onChange={(e) => setUseBalance(e.target.checked)}
                              checked={useBalance}
                            />{" "}
                            <span>Use Balance </span>
                          </label>
                        </li>
                        <li>
                          <b>₹300/-</b>
                        </li>
                      </ul>
                    </div>
                    <div className="register">
                      <ul>
                        <li>
                          <small>
                            Total {cartState?.length} Classes({classInfo.paid}{" "}
                            paid + {classInfo.free} free )
                          </small>{" "}
                          <b>{classInfo.price}/-</b>
                        </li>

                        <li>
                          <button
                            className="register"
                            disabled={purchaseClassMutation.isLoading}
                            onClick={purchaseClassMutation.mutate}
                          >
                            Register
                          </button>
                          {/* <Link to="/cart/registration">Register</Link> */}
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              ) : (
                <NoData />
              )}
              {
                cartState.length
                  ?
                  <div className="notes">
                    <p>Notes</p>
                    <ul>
                      <li>
                        Please <span>RE-CHECK</span> Age, Timing, Level of
                        Class(Basics or Advanced), Material Availability, Syllabus
                        before paying amount.
                      </li>
                      <li>
                        If you wish to <span>CHANGE</span> the class, you can change
                        by 31st. But It is depending on the available seats on the
                        day.
                      </li>
                      <li>
                        Every Month we open <span>SAME</span> classes, if the seat
                        not available in this month, try next month early morning of
                        25th.
                      </li>
                      <li>
                        Once registered you can <span>ASSIGN</span> the class to
                        your son, daughter, mother, father Name. Go to My Classes,
                        in the class having details.
                      </li>
                      <li>
                        If you have any <span>DOUBT</span>, call to HELPLINE
                      </li>
                    </ul>
                    <p
                      style={{ margin: 0, textAlign: "center", fontSize: "0.9rem" }}
                    >
                      Thanks for your support and Cooperation
                    </p>
                  </div>
                  :
                  null
              }
            </div>
          </div>
        </div>
      </div>

      <Helpline />
    </>
  );
};

export default MyCart;
