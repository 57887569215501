import React, { useEffect, useState } from "react";
import "./Auth.scss";
import Spinner from "react-bootstrap/Spinner";
import { Input } from "components/form";
import { INDFlagIcon, nameIcon } from "assets/icons";
import { Link, useNavigate } from "react-router-dom";
import { addProfilePic, profilePlaceholderImg } from "assets/images";
import { joiResolver } from "@hookform/resolvers/joi";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";

import useSnackbar from "hooks/useSnackbar";
import { signUpValidation } from "validations";
import { AuthService } from "services";
import { capitalizeString, generateFormDataPayload } from "utils/helper";
import useUser from "hooks/useUser";
import moment from "moment";

const SignUp = ({ credentials, closeAuthModel }) => {
  const { setUser } = useUser();
  const { snackbar } = useSnackbar();
  const { register } = AuthService();
  const navigate = useNavigate();
  const [acceptCondition, setAcceptCondition] = useState(true);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(signUpValidation),
    defaultValues: {
      profile: "",
      name: "",
      mobile: "",
    },
  });
  const profile = watch("profile");
  const getBrowserInfo = () => {
    const userAgent = navigator.userAgent;
    let browserName = "Unknown";
    let browserVersion = "Unknown";
    let os = "Unknown";
    let device = "Unknown";
    if (userAgent.includes("Firefox")) {
      browserName = "Firefox";
      browserVersion = userAgent.match(/Firefox\/([0-9]+(?:\.[0-9]+)?)/)[1];
    } else if (userAgent.includes("Chrome")) {
      browserName = "Chrome";
      browserVersion = userAgent.match(/Chrome\/([0-9]+(?:\.[0-9]+)?)/)[1];
    } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
      browserName = "Safari";
      browserVersion = userAgent.match(/Version\/([0-9]+(?:\.[0-9]+)?)/)[1];
    } else if (userAgent.includes("Edge")) {
      browserName = "Edge";
      browserVersion = userAgent.match(/Edge\/([0-9]+(?:\.[0-9]+)?)/)[1];
    }

    if (userAgent.includes("Windows")) {
      os = "Windows";
    } else if (userAgent.includes("Mac")) {
      os = "macOS";
    } else if (userAgent.includes("Android")) {
      os = "Android";
    } else if (userAgent.includes("iPhone")) {
      os = "iOS";
    } else if (userAgent.includes("Linux")) {
      os = "Linux";
    }

    if (userAgent.includes("Mobile")) {
      device = "Mobile";
    } else {
      device = "Desktop";
    }
    return { browserName, browserVersion, os, device };
  };

  const registerUser = useMutation(
    (data) => {
      const browserDetail = getBrowserInfo();
      const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const payload = generateFormDataPayload(
        {
          countryCode: "+91",
          deviceToken: "123456",
          deviceId: "1234",
          type: "web",
          webBrowserVersion: browserDetail.browserVersion,
          deviceDetail: {
            model: `${browserDetail.os}-${browserDetail.device}`,
          },
          timeZone: timezoneName,
          ...credentials,
          ...data,
          name: capitalizeString(String(data?.name).trim()),
        },
        "profile"
      );

      return register(payload);
    },
    {
      onSuccess: (data) => {
        snackbar(data?.message || "", "info");
        localStorage.setItem("fg_token", data?.data?.token);
        setUser(data?.data?.userData);
        navigate("/");
        closeAuthModel();
      },
      onError: (err) => {
        snackbar(err.data.message || "");
      },
    }
  );

  useEffect(() => {
    if (credentials.mobile) {
      setValue("mobile", credentials.mobile);
    }
  }, [credentials]);

  return (
    <>
      <div className="auth singUp editProfileModal">
        <h4>Create Account</h4>

        <div className="profilePic">
          <img
            src={
              profile?.name
                ? URL.createObjectURL(profile)
                : profilePlaceholderImg
            }
            alt=""
            className="profile"
          />
          <label
            htmlFor="upload_profile"
            type="button"
            className="upload_profile"
          >
            <img src={addProfilePic} alt="" className="add_img" />
            <input
              type="file"
              id="upload_profile"
              className="uploadInput"
              onChange={(e) => setValue("profile", e.target.files[0])}
            />
          </label>
        </div>
        <div className="form_field name_field">
          <Input
            placeholder=" Enter Name"
            name="name"
            control={control}
            error={errors.name?.message}
          />
          <span className="country">
            <img src={nameIcon} alt="" />
          </span>
        </div>
        <div className="form_field">
          <Input
            placeholder=" Enter India  Mobile Number"
            className="mobileNumber"
            disabled
            name="mobile"
            control={control}
            error={errors.mobile?.message}
            maxLength={"10"}
          />
          <span className="country">
            <img src={INDFlagIcon} alt="" />
            +91
          </span>
        </div>
        <label className="checkbox_wrap">
          <input
            type="checkbox"
            onChange={(e) => setAcceptCondition(e.target.checked)}
            checked={acceptCondition}
          />
          I agree to{" "}
          <Link to="terms-condition" target="_blank">
            {" "}
            Terms and Conditions{" "}
          </Link>{" "}
          and{" "}
          <Link to="/privacy-policy" target="_blank">
            {" "}
            Data Privacy policies.
          </Link>
        </label>

        <div className="button_wrap button_center">
          <button
            type="button"
            className="button"
            disabled={!acceptCondition || registerUser.isLoading}
            onClick={handleSubmit(registerUser.mutate)}
          >
            {registerUser.isLoading ? (
              <Spinner
                animation="border"
                role="status"
                variant="primary"
              ></Spinner>
            ) : (
              "Sign Up"
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default SignUp;
