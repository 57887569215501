import { BackButton } from "components/form";
import Skeleton from "components/skeleton";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { JobService } from "services";
import { formatDate } from "utils/helper";

const formatJobType = {
  fullTime: "Full Time",
  partTime: "Part Time",
  internship: "Internship",
  contract: "Contract",
};

const JobDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getJob } = JobService();

  useEffect(() => {
    if (!location.state?.vacancyId) {
      navigate("/jobs");
    }
  }, [location]);

  const { data, isLoading } = useQuery({
    queryKey: ["job-detail"],
    queryFn: async () =>
      getJob({
        token: localStorage.getItem("fg_token"),
        vacancyId: location.state?.vacancyId,
      }),
  });

  const handleNavigation = () => {
    navigate(
      {
        pathname: "/jobs/apply",
      },
      {
        state: {
          ...location.state,
        },
      }
    );
  };

  const job = data?.data?.data;
  console.log({ job });

  return (
    <>
      <div className="job_detail">
        <div className="inner_container">
          <div className="_card">
            <div className="_card_content">
              <BackButton />
              {isLoading ? (
                <>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton button />
                </>
              ) : (
                <div>
                  <div className="has_child">
                    <h5 className="sub_title">Job Details</h5>
                    <button onClick={handleNavigation} className="button">
                      Apply
                    </button>
                  </div>

                  <div className="job_content">
                    <h4 className="vacancy_name">
                      Vacancy Name : <span>{job.vacancyName}</span>
                    </h4>

                    <h4 className="">Description</h4>
                    <p
                      className="job_description"
                      dangerouslySetInnerHTML={{ __html: job.description }}
                    />

                    <ul>
                      <li>
                        <span>Job Type</span>
                        {job?.jobType ? formatJobType[job.jobType] : ""}
                      </li>

                      <li>
                        <span>Work Type</span>
                        {job?.workType ? formatJobType[job.workType] : ""}
                      </li>
                      <li>
                        <span>Honorarium (Salary) </span>
                        {job?.salary}k
                      </li>
                      <li>
                        <span>No. of Vacancies</span>
                        {job?.numberOfVacancies || ""}
                      </li>
                      <li>
                        <span>Last date to apply</span>
                        {formatDate(job?.lastDateToApply) || ""}
                      </li>
                      <li className="">
                        <span>Work Experience</span>
                        {job?.workExperience || ""}
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobDetail;
