
import React, { useState } from "react";
import "./MyClasses.scss";
import Helpline from "components/helpline/Helpline";
import { Link, Outlet, useLocation } from "react-router-dom";
const MyClasses = () => {
  const location = useLocation();
  const tab = location.pathname?.split("/")[2] || "progress";

  return (
    <div className="my_classes">
      <div className="container">
        <div className="tab_button_wrap">
          <ul>
            <li>
              <Link
                to="/my-classes"
                type="button"
                className={`_card ${(!tab || tab !== "completed") ? "active" : ""}`}
              >
                <span className="_card_content"> In Progress</span>
              </Link>
            </li>
            <li>
              <Link to="completed"
                className={`_card ${tab === "completed" ? "active" : ""}`}
              >
                <span className="_card_content">Completed</span>
              </Link>
            </li>
          </ul>
        </div>

        <Outlet />

        <Helpline />
      </div>
    </div>
  );
};

export default MyClasses;
