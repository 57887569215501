import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import Spinner from "react-bootstrap/Spinner";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";

// custom import
import useSnackbar from "hooks/useSnackbar";
import { AuthService } from "services";
import { callIcon, whatsAppIcon2 } from "assets/icons";
import useUser from "hooks/useUser";
import GradientCircularProgressbar from "components/CircularProgressbar/GradientCircularProgressbar ";
import { useSelector } from "react-redux";
import { fetchToken } from "config/firebase";

const AuthOTP = ({
  updateMobile,
  closeAuthModel,
  setLoginSteps,
  credentials,
}) => {
  const { verifyOtp, resendOtp } = AuthService();
  const { snackbar } = useSnackbar();
  const [otp, setOtp] = useState("");
  const { setUser } = useUser();
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(60);
  const percentage1 = (seconds / 60) * 100;

  const { helplineList } = useSelector((state) => state.helpline);

  const handleVerifyOtp = useMutation(
    async (data) => {
      const fcmToken = await fetchToken();

      const payload = {
        type: "web",
        deviceToken: fcmToken || "1234",
        ...credentials,
        ...data,
      };
      return verifyOtp(payload);
    },
    {
      onSuccess: (data) => {
        if (!data.data.isRegister) {
          setLoginSteps("singUp");
          updateMobile && updateMobile(true);
        } else {
          snackbar(data?.message || "", "info");
          localStorage.setItem("fg_token", data?.data?.token);
          setUser(data?.data?.user);
          if (updateMobile) {
            updateMobile(true);
            return;
          }
          navigate("/");
          closeAuthModel();
        }
      },
      onError: (err) => {
        updateMobile && updateMobile(false);
        snackbar(err.data.message || "");
      },
    }
  );

  const handleResendOtp = useMutation(() => resendOtp(credentials), {
    onSuccess: (data) => {
      snackbar(data?.message || "", "info");
      setSeconds(60);
    },
    onError: (err) => {
      snackbar(err.data.message || "");
    },
  });

  useEffect(() => {
    if (seconds < 60) {
      return;
    }

    const intervalId = setInterval(() => {
      setSeconds((prevTime) => {
        if (prevTime === 0) {
          clearInterval(intervalId);
          return 0;
        }

        return prevTime - 1;
      });
    }, 1000);
  }, [seconds]);

  useEffect(() => {
    if (otp.length === 4 && !handleVerifyOtp.isLoading) {
      handleVerifyOtp.mutate({ otp });
    }
  }, [otp]);

  return (
    <>
      <div className="auth otp">
        <h4>Enter OTP</h4>

        <div className="otp_wrap">
          <OtpInput
            value={otp}
            onChange={(e) => !handleVerifyOtp.isLoading && setOtp(e)}
            className={"input form-control"}
            numInputs={4}
            renderInput={(props) => <input {...props} />}
          />
        </div>

        <div className="progress_wrap">
          {/* <CircularProgressbar
            value={percentage1}
            text={`${seconds}`}
            styles={buildStyles({
              rotation: 0.25,
              strokeLinecap: "butt",
              textSize: "2.8rem",
              pathTransitionDuration: 0.5,
              pathColor: `rgba(62, 152, 199, ${percentage1 / 100})`,
              textColor: "#10B981",
              trailColor: "#d6d6d6",
              backgroundColor: "10B981",
            })}
          /> */}

          <GradientCircularProgressbar
            id={"two"}
            percentageValue={percentage1}
            textValue={`${seconds}`}
            color1={"#B0FF4B"}
            color2={"#11876B"}
            color3={"#11876B"}
            textColor={"#11876B"}
          />
        </div>
        <div className="button_wrap button_center">
          <button
            type="button"
            className="button"
            disabled={
              handleVerifyOtp.isLoading ||
              handleResendOtp.isLoading ||
              percentage1
            }
            onClick={handleResendOtp.mutate}
          >
            {handleResendOtp.isLoading ? (
              <Spinner
                animation="border"
                role="status"
                variant="primary"
              ></Spinner>
            ) : (
              "Resend OTP"
            )}
          </button>
        </div>
        <div className="bottom_content">
          <p>Having trouble in login / signup, Contact us</p>
          <ul className="get_touch">
            <li>
              <a href="tel:+916542345">
                <img src={callIcon} alt="" />
              </a>
            </li>
            <li>
              <Link to="" target="_blank">
                <img src={whatsAppIcon2} alt="" />
              </Link>
            </li>
          </ul>
          <ul>
            {helplineList?.map((it) => {
              return (
                <li key={it._id}>
                  <span>{it.number} </span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default AuthOTP;
