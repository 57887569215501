import React from "react";
import "../../TeacherProfile.scss";
import { BackButton } from "components/form";
import Helpline from "components/helpline/Helpline";
import { certificateImage } from "assets/images";
import useUser from "hooks/useUser";

const ExperienceCertificate = () => {
  const { user } = useUser();

  return (
    <>
      <div className="experience_certificate ">
        <div className="inner_container spacing">
          <div className="_card">
            <div className="_card_content">
              <div className="_certificates">
                <BackButton />
                <div className="sub_title">Experience Certificate</div>

                <div className="card_wrap">
                  <div className="item">
                    <img src={user?.certificate} alt="" />
                  </div>
                  <button onClick={()=>window.open(user?.certificate, "_blank")} className="button">
                    Download
                  </button>
                </div>


              </div>
            </div>
          </div>
        </div>
        <Helpline />
      </div>
    </>
  );
};

export default ExperienceCertificate;
