import { BackButton, Button } from "components/form";
import React, { useEffect, useState } from "react";
import "./AboutTeacher.scss";
import TeacherInfo from "./section/TeacherInfo";
import TeacherSyllabus from "./section/TeacherSyllabus";
import StudentWorks from "./section/StudentWorks";
import RequiredTools from "./section/RequiredTools";
import Helpline from "components/helpline/Helpline";
import { useQuery } from "react-query";

import { ClassService } from "services";
import { useLocation, useNavigate } from "react-router-dom";
import useUser from "hooks/useUser";
import StudyMaterial from "./section/StudyMaterial";
import StudentGuideline from "./section/StudentGuideline";
import CallPrincipal from "./section/CallPrincipal";
import ApplyLeave from "./section/ApplyLeave";
import StudentsList from "./section/StudentsList";
import ZoomLink from "./section/ZoomLink";
import Attendance from "./section/Attendance.jsx";
import Certificate from "./section/Certificate";
import DownloadCertificate from "./section/Certificate";
import MyAttendance from "./section/MyAttendance";
import MyWorks from "./section/MyWorks";
import GiveFeedback from "./section/GiveFeedback";

const AboutTeacher = () => {
  const { isLoggedIn, user } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const { getClassTeacherDetail } = ClassService();
  const {
    classId,
    classTitle,
    certificate,
    currentTab,
    currentMenuTab,
    backUrl,
    redirectionScreen,
    isTeacher,
  } = location.state || {};
  const [tab, setTab] = useState("teacher");
  const { data, isLoading } = useQuery({
    queryKey: ["about_teacher"],
    queryFn: () =>
      getClassTeacherDetail({
        classId,
        userId: user?._id,
        ...(isTeacher && { type: "student" }),
      }),
    enabled: classId?.length ? true : false,
    onError: () => {
      navigate("/");
    },
  });

  useEffect(() => {
    if (!classId) {
      navigate("/");
    }
  }, [classId]);

  useEffect(() => {
    const tabs = [
      "teacher",
      "Syllabus",
      "Student",
      "Required",
      "study-material",
      "student-guideline",
      "call-principal",
      "apply-leave",
      "students-list",
      "zoom-link",
      "Attendance",
      "download-certificate",
      "my-attendance",
      "my-work",
      "give-feedback",
    ];
    setTab(tabs.includes(currentTab) ? currentTab : "teacher");
  }, [currentTab]);

  const handleNavigation = (name) => {
    navigate(
      {
        pathname: "",
      },
      {
        state: {
          ...location.state,
          currentTab: name,
        },
      }
    );
  };

  const isSyllabus = data?.data?.classDetail?.syllabus?.isSyllabus;
  const isStudentWorks =
    data?.data?.classDetail?.studentWorkes?.isStudentWorkes;
  const isRequiredTools =
    data?.data?.classDetail?.requiredTools?.isRequiredTools;
  const isStudyMaterial =
    data?.data?.classDetail?.studyMaterial?.isStudyMaterial;
  const isStudentGuideline = data?.data?.classDetail?.content;
  const review = data?.data?.reviewData?.reviews;

  return (
    <>
      <div className="about_teacher spacing">
        <div className="inner_container">
          <div className="_card">
            <div className="_card_content">
              <div className="teacher_content">
                <div className="sub_title">{classTitle}</div>
                <BackButton
                  route={backUrl}
                  state={
                    redirectionScreen && redirectionScreen === "HOME"
                      ? { classId }
                      : undefined
                  }
                />

                <div className="tab_button_wrap">
                  <ul>
                    {currentMenuTab !== "complete" ? (
                      <li>
                        <button
                          type="button"
                          className={`${tab === "teacher" ? "active" : ""}`}
                          onClick={() => handleNavigation("teacher")}
                        >
                          <span className="">About Teacher</span>
                        </button>
                      </li>
                    ) : null}
                    {isSyllabus && currentMenuTab !== "complete" ? (
                      <li>
                        <button
                          type="button"
                          className={` ${tab === "Syllabus" ? "active" : ""}`}
                          onClick={() => handleNavigation("Syllabus")}
                        >
                          <span className="">Syllabus</span>
                        </button>
                      </li>
                    ) : null}
                    {isStudentWorks && currentMenuTab !== "complete" ? (
                      <li>
                        <button
                          type="button"
                          className={` ${tab === "Student" ? "active" : ""}`}
                          onClick={() => handleNavigation("Student")}
                        >
                          <span className="">Student Works</span>
                        </button>
                      </li>
                    ) : null}
                    {isRequiredTools && currentMenuTab !== "complete" ? (
                      <li>
                        <button
                          type="button"
                          className={` ${tab === "Required" ? "active" : ""}`}
                          onClick={() => handleNavigation("Required")}
                        >
                          <span className="">Required Tools</span>
                        </button>
                      </li>
                    ) : null}
                    {isLoggedIn &&
                    location.pathname === "/my-classes/about-teacher" ? (
                      <>
                        {isStudyMaterial && currentMenuTab !== "complete" ? (
                          <li>
                            <button
                              type="button"
                              className={` ${
                                tab === "study-material" ? "active" : ""
                              }`}
                              onClick={() => handleNavigation("study-material")}
                            >
                              <span className="">Study Material</span>
                            </button>
                          </li>
                        ) : null}
                        {isStudentGuideline &&
                        !isTeacher &&
                        currentMenuTab !== "complete" ? (
                          <li>
                            <button
                              type="button"
                              className={` ${
                                tab === "student-guideline" ? "active" : ""
                              }`}
                              onClick={() =>
                                handleNavigation("student-guideline")
                              }
                            >
                              <span className="">Student Guidelines</span>
                            </button>
                          </li>
                        ) : null}
                        {!isTeacher && currentMenuTab !== "complete" && (
                          <li>
                            <button
                              type="button"
                              className={` ${
                                tab === "my-attendance" ? "active" : ""
                              }`}
                              onClick={() => handleNavigation("my-attendance")}
                            >
                              <span className="">My Attendance</span>
                            </button>
                          </li>
                        )}
                        {!isTeacher && currentMenuTab !== "complete" ? (
                          <li>
                            <button
                              type="button"
                              className={` ${
                                tab === "my-work" ? "active" : ""
                              }`}
                              onClick={() => handleNavigation("my-work")}
                            >
                              <span className="">My Works</span>
                            </button>
                          </li>
                        ) : null}
                        {!isTeacher && (
                          <li>
                            <button
                              type="button"
                              className={` ${
                                tab === "download-certificate" ? "active" : ""
                              }`}
                              onClick={() =>
                                handleNavigation("download-certificate")
                              }
                            >
                              <span className="">Download Certificate</span>
                            </button>
                          </li>
                        )}
                        {!isTeacher && currentMenuTab !== "complete" && (
                          <li>
                            <button
                              type="button"
                              className={` ${
                                tab === "give-feedback" ? "active" : ""
                              }`}
                              onClick={() => handleNavigation("give-feedback")}
                            >
                              <span className="">Give Feedback</span>
                            </button>
                          </li>
                        )}

                        {isTeacher && currentMenuTab !== "complete" ? (
                          <>
                            <li>
                              <button
                                type="button"
                                className={` ${
                                  tab === "Attendance" ? "active" : ""
                                }`}
                                onClick={() => handleNavigation("Attendance")}
                              >
                                <span className="">Attendance</span>
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                className={` ${
                                  tab === "zoom-link" ? "active" : ""
                                }`}
                                onClick={() => handleNavigation("zoom-link")}
                              >
                                <span className="">Zoom Link</span>
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                className={` ${
                                  tab === "students-list" ? "active" : ""
                                }`}
                                onClick={() =>
                                  handleNavigation("students-list")
                                }
                              >
                                <span className="">Students List</span>
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                className={` ${
                                  tab === "apply-leave" ? "active" : ""
                                }`}
                                onClick={() => handleNavigation("apply-leave")}
                              >
                                <span className="">Apply Leave</span>
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                className={` ${
                                  tab === "call-principal" ? "active" : ""
                                }`}
                                onClick={() =>
                                  handleNavigation("call-principal")
                                }
                              >
                                <span className="">Call Principal</span>
                              </button>
                            </li>
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </ul>
                </div>

                {tab === "teacher" && (
                  <TeacherInfo
                    data={data?.data?.classDetail?.teachers}
                    isLoading={isLoading}
                  />
                )}
                {tab === "Syllabus" && (
                  <TeacherSyllabus
                    data={data?.data?.classDetail?.syllabus?.data}
                    isLoading={isLoading}
                  />
                )}
                {tab === "Student" && (
                  <StudentWorks
                    data={data?.data?.classDetail?.studentWorkes?.data}
                    isLoading={isLoading}
                  />
                )}
                {tab === "Required" && (
                  <RequiredTools
                    data={data?.data?.classDetail?.requiredTools?.data}
                    isLoading={isLoading}
                  />
                )}
                {tab === "study-material" && (
                  <StudyMaterial
                    data={data?.data?.classDetail?.studyMaterial?.data}
                    isLoading={isLoading}
                  />
                )}
                {tab === "student-guideline" && (
                  <StudentGuideline
                    data={data?.data?.content}
                    isLoading={isLoading}
                  />
                )}
                {tab === "call-principal" && <CallPrincipal />}
                {tab === "apply-leave" && <ApplyLeave />}
                {tab === "students-list" && <StudentsList classId={classId} />}
                {tab === "zoom-link" && <ZoomLink />}
                {tab === "Attendance" && <Attendance classId={classId} />}
                {tab === "download-certificate" && (
                  <DownloadCertificate certificate={certificate} />
                )}
                {tab === "my-attendance" && <MyAttendance classId={classId} />}
                {tab === "my-work" && <MyWorks classId={classId} />}
                {tab === "give-feedback" && (
                  <GiveFeedback classId={classId} review={review} />
                )}

                {/* <div className="price">
                  <b>200/-</b> <span>(100 seats available)</span>
                  <Button>Add</Button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutTeacher;
