import HttpService from "services/http";
const SettingService = () => {
  const { httpRequest } = HttpService();

  // Protected API
  const getReasonsList = (search) =>
    new Promise((resolve, reject) => {
      httpRequest("GET", "user/setting/reason", {}, search)
        .then(resolve)
        .catch(reject);
    });
  const deleteAccount = (payload) =>
    new Promise((resolve, reject) => {
      httpRequest("POST", "user/setting/account_remove", payload)
        .then(resolve)
        .catch(reject);
    });

  // public API
  const getBanners = (search) =>
    new Promise((resolve, reject) => {
      httpRequest("GET", "user/setting/banner_web", {}, search)
        .then(resolve)
        .catch(reject);
    });
  const getFaqs = (search) =>
    new Promise((resolve, reject) => {
      httpRequest("GET", "user/setting/get_faq", {}, search)
        .then(resolve)
        .catch(reject);
    });
  const getStudentWorks = (search) =>
    new Promise((resolve, reject) => {
      httpRequest("GET", "user/home/work_feedback", {}, search)
        .then(resolve)
        .catch(reject);
    });
  const getTeachers = (search) =>
    new Promise((resolve, reject) => {
      httpRequest("GET", "user/home/teacher", {}, search)
        .then(resolve)
        .catch(reject);
    });
  return {
    getBanners,
    getFaqs,
    deleteAccount,
    getReasonsList,
    getStudentWorks,
    getTeachers,
  };
};
export { SettingService };
