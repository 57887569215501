import HttpService from "services/http";

const ManageProfileService = () => {
    const { httpRequest, httpFormRequest } = HttpService();

    const UpdateUserProfile = (payload) => new Promise((resolve, reject) => {
        httpFormRequest("POST", "user/manage/profile", payload)
            .then(resolve)
            .catch(reject);
    });

    const changeUserProfile = (payload) => new Promise((resolve, reject) => {
        httpFormRequest("PUT", "user/manage/class_profile", payload)
            .then(resolve)
            .catch(reject);
    });

    const getUserProfile = (search) => new Promise((resolve, reject) => {
        httpRequest("GET", "user/manage/profile_list", {}, search)
            .then(resolve)
            .catch(reject);
    });

    return { UpdateUserProfile, changeUserProfile, getUserProfile };
}
export { ManageProfileService };