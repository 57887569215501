import { contactIcon2 } from "assets/icons";
import React from "react";

const CallPrincipal = () => {
  return (
    <>
      <div className="call_principal">
        <p>
          Lorem ipsum dolor sit amet consectetur. Diam diam amet risus
          consectetur etiam magna. Aliquet integer semper senectus quis etiam.
          Amet suspendisse mi tellus pharetra. Ornare faucibus mauris
        </p>
        <div className="phone_number_wrap">
          <div className="phone_number">
            <img src={contactIcon2} alt="" />
            <p>
              <span>Phone number</span>
              <b>+91 9560 256 258</b>
            </p>
          </div>
          <a href="tel:+91 9560 256 258" className="button">Call Now</a>
        </div>
      </div>
    </>
  );
};

export default CallPrincipal;
