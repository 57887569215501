import { onMessageListener } from "config/firebase";
import React from "react";
import { IoCloseSharp } from "react-icons/io5";
import { Link } from "react-router-dom";

import { logoImg } from "assets/images";
import { useSnackbar as snackbarHook } from "notistack";
import { notificationIcon } from "assets/icons";

const Notification = () => {
  const { enqueueSnackbar, closeSnackbar } = snackbarHook();

  // this function triggered when new message arrive
  onMessageListener()
    .then((payload) => {
      const { title, body, image } = payload.data;

      enqueueSnackbar(
        <div className="common_notification">
          <img src={logoImg} alt="" />
          <div className="content">
            <h4>{"title"}</h4>
            <p>{"body"}</p>
          </div>
        </div>,
        {
          style: { backgroundColor: "#fff" },
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          persist: false,
          action: (key) => (
            <button className="cancel_btn" onClick={() => closeSnackbar(key)}>
              <IoCloseSharp />
            </button>
          ),
        }
      );
      // dispatch(getUserProfileAction());
      // await getAllNotifications();
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <Link
      to="/notifications"
      className="avatar_button notification_icon  _card"
    >
      <span className="_card_content">
        <img src={notificationIcon} alt="" />
      </span>
    </Link>
  );
};

export default Notification;
