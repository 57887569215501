import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import { AttendanceService } from "services";

const color = {
  "Absent": "leave",
  "Present": "present",
  "Holiday": "holiday",
}

const Attendance = ({ classId }) => {
  const { getAttendanceList } = AttendanceService();

  const { data, isLoading } = useQuery({
    queryKey: ["attendance-list"],
    queryFn: async () =>
      getAttendanceList({
        classId,
        token: localStorage.getItem("fg_token"),
      }),
  });

  const teacherAttendanceList = data?.data?.attendanceList;
  const studentsAttendanceList = data?.data?.studentsAttendanceList;

  console.log({studentsAttendanceList});
  

  return (
    <>
      <div className="attendance_list">
        <div className="teacher_attendance">
          <h5>Teacher Attendence</h5>
          <ul>
            {
              teacherAttendanceList?.map(attendance => {
                return <li>
                  <p>{moment(attendance.createdAt).format("Do MMM - dddd")}</p>
                  <span className={color[attendance?.status]}>{attendance?.status}</span>
                </li>
              })
            }
          </ul>
        </div>
        <div className="student_attendance">
          <h5>Students Attendence</h5>
          <ul>
            <li>
              <p>
                <b>Date</b>
                <b>Day</b>
              </p>
              <p>
                <b>Zoom Login</b>
                <b>Chat Login</b>
              </p>
            </li>

            {

              studentsAttendanceList?.map(attendance => {
                return <li>
                  <p>
                    <span>{moment(attendance.createdAt).format("Do MMM")}</span> - <span>{moment(attendance.createdAt).format("dddd")}</span>
                  </p>
                  <p>
                    <span className={attendance?.zoomCount ? "present" : ""}>{attendance?.zoomCount ? attendance?.zoomCount : "X"}</span>
                    <span className={color[attendance?.status]}>100</span>
                  </p>
                </li>
              })
            }
          </ul>
        </div>
      </div>
    </>
  );
};

export default Attendance;
