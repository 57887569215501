import { courseImg, ProfileImg } from "assets/images";
import React from "react";
import "../MyClasses.scss";
import Faqs from "components/faqs/FAQs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ClassService } from "services";
import { useQuery } from "react-query";
import { useAppSelector } from "../../../../redux";
import Skeleton from "components/skeleton";
import { get12HourTime } from "utils/helper";
import moment from "moment";
import useUser from "hooks/useUser";
import NoData from "components/no-data";
import ClassProcess from "components/class-process";

const CompleteClasses = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useUser();
  const { getMyClassList } = ClassService();
  const search = useAppSelector((state) => state.sharedSlice.search);

  const { data: classList, isLoading } = useQuery({
    queryKey: ["my-class-completed-list", search.text],
    queryFn: async () =>
      getMyClassList({
        token: localStorage.getItem("fg_token"),
        type: "complete",
        ...(search.text?.length && { search: search.text }),
      }),
  });

  const getTrainerName = (teachers) => {
    if (Array.isArray(teachers) && teachers?.length) {
      const teacher = teachers.find((teacher) => teacher.isPrimary)?.name;
      return teacher;
    }

    return null;
  };

  return (
    <div>
      <div className="classes_card_wrap spacing">
        {isLoading ? (
          [1, 2].map(() => (
            <div className="item">
              <div className="class_detail_wrap">
                <div className="class_detail">
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton button />
                </div>
              </div>
            </div>
          ))
        ) : classList && classList.data?.data?.length ? (
          classList?.data?.data?.map((classDetail) => (
            <div className="item">
              <div className="class_detail_wrap">
                <div className="class_detail">
                  <ul className="class_Feature">
                    <li className="blue">{get12HourTime(classDetail?.time)}</li>
                    <li className="green">
                      {moment(classDetail?.batchInfo?.batchStart).format(
                        "MMMM YYYY"
                      )}
                    </li>
                    {classDetail?.new ? <li className="red">New</li> : null}
                    {classDetail?.isAdvanced ? (
                      <li className="purple">Advanced</li>
                    ) : null}
                    <li className="pink">{classDetail?.group}</li>
                  </ul>
                  <div className="course_name">
                    <img
                      src={
                        classDetail?.icon?.length ? classDetail.icon : courseImg
                      }
                      alt=""
                    />
                    <div className="content">
                      <h4>{classDetail?.classTitleEng}</h4>
                      <p>{classDetail?.tagline}</p>
                      <ul>
                        <li></li>
                        {getTrainerName(classDetail?.teachers) ? (
                          <li className="trainer">
                            <span>Trainer: </span>{" "}
                            <b> {getTrainerName(classDetail?.teachers)}</b>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </div>

                  <div className="download_certificate">
                    <button
                      type="button"
                      className="button"
                      disabled={!classDetail?.certificate}
                      onClick={() =>
                        // navigate(
                        //   {
                        //     pathname: "/my-classes/about-teacher",
                        //   },
                        //   {
                        //     state: {
                        //       currentTab: "download-certificate",
                        //       classId: classDetail._id,
                        //       classTitle: classDetail?.classTitleEng,
                        //       currentMenuTab: "complete",
                        //       backUrl: location.pathname,
                        //     },
                        //   }
                        // )
                        window.open(classDetail?.certificate)
                      }
                    >
                      Download Certificate
                    </button>
                  </div>

                  <div className="profile_name">
                    <div className="profile_content">
                      <h6>
                        <span>Profile Name: </span>{" "}
                        <b>{classDetail?.profileInfo?.name ?? user.name}</b>
                      </h6>
                      {/* <p>
                          <img src={editColorButton} alt="" />
                          <span>
                            If you wish to ASSIGN this class on your son,daughter,
                            mother,father name to get certificate, then {" "}
                            <Link
                              to="/my-classes/profile-course"
                              state={{ classId: classDetail._id, profileId: classDetail?.profileInfo?._id }}
                              style={{ color: "inherit" }}
                            >
                              click HERE
                            </Link>
                          </span>
                        </p> */}
                    </div>
                    {/* <div className="profile_image">
                        <img style={{ height:"53px", borderRadius:"12px" }} src={classDetail?.profileInfo?.profile ? classDetail?.profileInfo?.profile : user?.profile ? user?.profile : ProfileImg} alt="" />
                      </div> */}
                  </div>

                  {/* <p
                      className="text-center"
                      style={{ color: "#000", margin: 0, fontWeight: 500 }}
                    >
                      {" "}
                      If you wish to change class by 31st,{" "}
                      <Link to="/my-classes/change-class">CLICK HERE</Link>{" "}
                    </p> */}
                </div>
              </div>
            </div>
          ))
        ) : (
          <>
            <NoData
              title="It looks, You haven't Completed any class yet"
              desc=" "
            />
            {/* <ClassProcess /> */}
          </>
        )}
      </div>

      <Faqs screenType="myClasses" />
    </div>
  );
};

export default CompleteClasses;
