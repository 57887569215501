import HttpService from "services/http";

const UserService = () => {
    const { httpRequest, httpFormRequest } = HttpService();

    const userProfile = (search) => new Promise((resolve, reject) => {
        httpRequest("GET", "user/user_profile/detail", {}, search)
            .then(resolve)
            .catch(reject);
    });

    const updateProfile = (payload) => new Promise((resolve, reject) => {
        httpFormRequest("PUT", "user/user_profile/update_user", payload)
            .then(resolve)
            .catch(reject);
    });

    const updateTeacherDetail = (payload) => new Promise((resolve, reject) => {
        httpFormRequest("PUT", "user/user_profile/update_teacher_detail", payload)
            .then(resolve)
            .catch(reject);
    });

    return { userProfile, updateProfile, updateTeacherDetail };
}

export { UserService };