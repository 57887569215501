import React, { useState, useEffect } from "react";
import {
  searchIcon
} from "assets/icons";

const TypingPlaceholder = ({ value, onChange  }) => {
  const placeHolder = [`Search for "Drawing"`, `Search for "Yoga"`, `Search for "Tailoring"`, `Search for "Video Editing"`, `Search for "Zumba"`];
  const [currentText, setCurrentText] = useState("");
  const [wordIndex, setWordIndex] = useState(0);
  const typingSpeed = 200; // speed of typing each letter
  const delayBetweenWords = 2000; // delay before starting the next word

  useEffect(() => {
    let typingTimeout, wordTimeout;

    const typeWord = (text, index) => {
      if (index < text.length) {
        // Type one letter at a time
        setCurrentText(text.substring(0, index + 1));
        typingTimeout = setTimeout(() => {
          typeWord(text, index + 1);
        }, typingSpeed);
      } else {
        // After finishing typing the word, wait and start the next word
        wordTimeout = setTimeout(() => {
          setWordIndex((prevIndex) => (prevIndex + 1) % placeHolder.length);
        }, delayBetweenWords);
      }
    };

    typeWord(placeHolder[wordIndex], 9);

    // Cleanup timeouts on unmount or word change
    return () => {
      clearTimeout(typingTimeout);
      clearTimeout(wordTimeout);
    };
  }, [wordIndex]);

  return <div className="search_wrap">
    <input
      type="search"
      placeholder={currentText}
      value={value}
      onChange={onChange}
    />
    <img src={searchIcon} alt="" />
  </div>

};

export default TypingPlaceholder;
