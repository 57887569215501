import { contactIcon2, emailIcon } from "assets/icons";
import { BackButton, Input, TextArea } from "components/form";
import Helpline from "components/helpline/Helpline";
import Spinner from "react-bootstrap/Spinner";
import useSnackbar from "hooks/useSnackbar";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import { HelpSupportService } from "services";
import { generateFormDataPayload } from "utils/helper";

const Help = () => {
  const { snackbar } = useSnackbar();
  const { addComplaint } = HelpSupportService();
  const [state, setState] = useState({
    message: "",
    messageError: ""
  });

  const sendComplaint = useMutation(() => {

    if (!state.message?.length) {
      setState(prev => ({ ...prev, messageError: "Please enter your complaint message" }));
      return;
    }
    const payload = generateFormDataPayload({
      comment: state.message,
      token: localStorage.getItem("fg_token")
    });
    return addComplaint(payload)
  }, {
    onSuccess: (data) => {
      if (data) {
        console.log({ data });
        snackbar((data?.message || ""), "info");
        setState(prev => ({ ...prev, message: "", messageError: "" }))
      }
    },
    onError: (err) => {
      snackbar((err?.data?.message || ""));
    }
  });

  return (
    <>
      <div className="help_section spacing">
        <div className="inner_container">
          <div className="_card">
            <div className="_card_content">
              <BackButton />
              <h5 className="sub_title">Help</h5>
              <div className="_help">
                <div className="contact">
                  <div className="contact_with_us">
                    <h5>Contact with us</h5>
                    <p>
                      We are happy to hear from you. we are available from 9 AM
                      to 7 PM on Monday to Friday.
                    </p>
                    <ul>
                      <li>
                        <img src={contactIcon2} alt="" />
                        <p>
                          <span>Phone number</span>
                          <b>+91 9560 256 258</b>
                        </p>
                      </li>
                      <li>
                        <img src={emailIcon} alt="" />
                        <p>
                          <span>Email Address</span>
                          <b>help@freegurukul.com</b>
                        </p>
                      </li>
                    </ul>
                  </div>
                  <h3>FAQ’s</h3>
                  <p>
                    To get more help in classes, please read commnly asked
                    questions
                  </p>
                  <Link to="/faq" className="button">
                    View
                  </Link>
                </div>
                <div className="submit_form">
                  <h4>Any Feedback or Complaints? Just write us a message.</h4>

                  <div className="form_field">
                    <TextArea
                      type="textarea"
                      label={"Message"}
                      placeholder="Write here..."
                      onChange={e => setState(prev => ({ ...prev, message: e.target.value }))}
                      value={state.message}
                      error={state.messageError?.length ? state.messageError : undefined}
                    />
                  </div>
                  <div className="button_wrap">
                    <button type="button" className="button w-100" onClick={sendComplaint.mutate} disabled={sendComplaint.isLoading}>
                      {
                        sendComplaint.isLoading
                          ?
                          <Spinner
                            animation="border"
                            role="status"
                            variant="primary"
                          />
                          :
                          "Submit"
                      }
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Helpline />
    </>
  );
};

export default Help;
