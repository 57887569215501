import { heartIcon } from "assets/icons";
import { Button, Input } from "components/form";
import React, { useState } from "react";
import "./Donation.scss";
import Donation80G from "./section/Donation80G";
import DonationAnonymous from "./section/DonationAnonymous";
import Helpline from "components/helpline/Helpline";
const DonationForm = () => {
  const [tab, setTab] = useState("80GDonation");
  return (
    <>
      <div className="donation_form spacing">
        <div className="inner_container">
          <div className="_card">
            <div className="_card_content">
              <div className="form_content">
                <h5 className="sub_title">Donation</h5>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Scelerisque enim nisi
                  vivam us facilisis. Mauris fermentum dictumst viverra
                  adipiscing at. Hac e gestas praesent facilisi quis lobortis
                  nam dolor risus. Rutrum inter dum sit etiam in quam amet sed
                  integer.
                </p>

                <div className="tab_button_wrap">
                  <ul>
                    <li>
                      <button
                        type="button"
                        onClick={() => setTab("80GDonation")}
                        className={`_card ${
                          tab === "80GDonation" ? "active" : ""
                        }`}
                      >
                        <span className="_card_content">80G Donation</span>
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        onClick={() => setTab("Anonymous")}
                        className={`_card ${
                          tab === "Anonymous" ? "active" : ""
                        }`}
                      >
                        <span className="_card_content">
                          Anonymous Donation
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
                <ul className="instructions">
                  <li>
                    Lorem ipsum dolor sit amet consectetur. Scelerisque enim
                    nisi vivam
                  </li>
                  <li>
                    us facilisis. Mauris fermentum dictumst viverra adipiscing
                    at. Hac e
                  </li>
                  <li>
                    gestas praesent facilisi quis lobortis nam dolor risus.
                    Rutrum inter
                  </li>
                  <li>dum sit etiam in quam amet sed integer.</li>
                </ul>

                {tab === "80GDonation" && <Donation80G />}
                {tab === "Anonymous" && <DonationAnonymous />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Helpline />
    </>
  );
};

export default DonationForm;
