import HttpService from "services/http";

const JobService = () => {
    const { httpRequest, httpFormRequest } = HttpService();

    const getJobs = (payload) => new Promise((resolve, reject) => {
        httpRequest("GET", "user/vacancy/list", {}, payload)
            .then(resolve)
            .catch(reject);
    });

    const getJob = (payload) => new Promise((resolve, reject) => {
        httpRequest("GET", "user/vacancy/detail", {}, payload)
            .then(resolve)
            .catch(reject);
    });

    const applyJob = (payload) => new Promise((resolve, reject) => {
        httpFormRequest("POST", "user/vacancy/apply", payload)
            .then(resolve)
            .catch(reject);
    });

    return { getJobs, getJob, applyJob };
}
export { JobService };