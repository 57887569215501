import Joi from "joi";

export const updateManageProfileValidation = Joi.object({
  name: Joi.string().allow().label("Name").messages({
    "string.empty": "{{#label}} is required",
  }),
  profile: Joi.any().required().label("Photo").messages({
    "string.empty": "{{#label}} is required",
  }),
});
