import useUser from 'hooks/useUser';
import React, { useEffect } from 'react';

import { Outlet, useLocation } from 'react-router-dom';

const AuthGuard = () => {
    const { isLoggedIn } = useUser();
    const location = useLocation();

    useEffect(() => {
        if (!isLoggedIn) {
            window.location.replace("/#/")
        }
    }, [location.pathname])

    return (
        <div>
            <Outlet />
        </div>
    )
}

export default AuthGuard