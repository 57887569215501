import React from "react";
import Helpline from "components/helpline/Helpline";
import Faqs from "components/faqs/FAQs";

const FAQPage = () => {
  return (
    <>
      <div className="faqPage spacing">
        <div className="inner_container">
          <div className="_card">
            <div className="_card_content">
              <div className="cms_content">
                <h5 className="sub_title">FAQs</h5>
                <Faqs type="teacher" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Helpline />
    </>
  );
};

export default FAQPage;
