import { BackButton } from "components/form";
import { CiLocationOn } from "react-icons/ci";

import React, { useState } from "react";
import { locationIcon } from "assets/icons";
import Helpline from "components/helpline/Helpline";
import DeleteModal from "components/modals/DeleteModal";
import { Link, useNavigate } from "react-router-dom";
import useUser from "hooks/useUser";
import { useMutation } from "react-query";
import { generateFormDataPayload } from "utils/helper";
import { UserService } from "services";
import useSnackbar from "hooks/useSnackbar";

const TeacherAddressListing = () => {
  const { user, setUser } = useUser();
  const { snackbar } = useSnackbar();
  const { updateTeacherDetail } = UserService();
  const [deleteModal, setDeleteModal] = useState(false);
  const navigate = useNavigate();
  const address = user?.address;

  const deleteAddress = useMutation(
    () => {
      return updateTeacherDetail(
        generateFormDataPayload({
          type: "address",
          deleteFlag: true,
          token: localStorage.getItem("fg_token"),
        })
      );
    },
    {
      onSuccess: (data) => {
        setUser({
          ...user,
          address: {},
        });
        snackbar(data?.message || "", "info");
        navigate("/profile");
      },
      onError: (err) => {
        snackbar(err.data.message || "");
      },
    }
  );

  return (
    <>
      <div className="teacher_my_profile ">
        <div className="inner_container spacing">
          <div className="_card">
            <div className="_card_content">
              <BackButton />
              <div className="sub_title">Postal Address</div>

              {address?.state ? (
                <div className="address_listing">
                  <div className="address_card">
                    <div className="content_wrap">
                      <img src={locationIcon} alt="" />
                      <div className="content">
                        <h5>{user.name}</h5>
                        <p>
                          {address?.street}, {address?.city}, {address?.state},{" "}
                          {address?.postalCode}
                        </p>
                      </div>
                    </div>

                    <div className="button_wrap">
                      <ul>
                        <li>
                          <button type="button">
                            <Link to="add">Edit</Link>
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            onClick={() => setDeleteModal(true)}
                          >
                            Delete
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="edit_from_details">
                  <div className="button_wrap">
                    <Link to="add" className="button">
                      Add Postal Address
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Helpline />
      </div>

      <DeleteModal
        show={deleteModal}
        handleClose={() => setDeleteModal(false)}
        handleSubmit={deleteAddress.mutate}
      />
    </>
  );
};

export default TeacherAddressListing;
