import React, { useEffect, useState } from 'react'
import CustomModal from 'components/modals/CustomModal'

const InternetConnection = ({ children }) => {
  const [isOnline, setIsOnline] = useState(true);

    const onlineListener = () => setIsOnline(true);
    const offlineListener = () => setIsOnline(false);
  
    useEffect(() => {
      window.addEventListener('online', onlineListener);
      window.addEventListener('offline', offlineListener);
  
      return () => {
        window.removeEventListener('online', onlineListener);
        window.removeEventListener('offline', offlineListener);
      }
    }, [])

    return (
        <>
            {children}
            <CustomModal show={!isOnline} closeButton={false}>
                Offline
            </CustomModal>
        </>
    )
}

export default InternetConnection;