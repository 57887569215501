import { BackButton, Button } from "components/form";

import React, { useState } from "react";
import { locationIcon, SBILogo } from "assets/icons";
import Helpline from "components/helpline/Helpline";
import DeleteModal from "components/modals/DeleteModal";
import { Link } from "react-router-dom";
import useUser from "hooks/useUser";

const TeacherBankDetails = () => {
  const { user } = useUser();
  const [deleteModal, setDeleteModal] = useState(false);
  const bankDetail = user?.bankDetails;

  console.log({ bankDetail });

  return (
    <>
      <div className="teacher_my_profile ">
        <div className="inner_container spacing">
          <div className="_card">
            <div className="_card_content">
              <BackButton route={"/profile/teacher"} />
              <div className="sub_title">Bank Details</div>

              <div className="bank_detail_wrap edit_from_details">
                <div className="bank_detail">
                  <h6 className="bankName">
                    {bankDetail?.bankName || "Bank Detail"}
                  </h6>
                  <div className="content_wrap">
                    {/* <img src={SBILogo} alt="" /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="38"
                      height="38"
                      fill="currentColor"
                      className="bi bi-bank"
                      viewBox="0 0 16 16"
                    >
                      <path d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.5.5 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89zM3.777 3h8.447L8 1zM2 6v7h1V6zm2 0v7h2.5V6zm3.5 0v7h1V6zm2 0v7H12V6zM13 6v7h1V6zm2-1V4H1v1zm-.39 9H1.39l-.25 1h13.72z" />
                    </svg>
                    <div className="content">
                      <h5>{bankDetail?.holderName || "Account Holder Name"}</h5>
                      <span>
                        xxxx xxxx{" "}
                        {bankDetail?.accountNumber
                          ? String(bankDetail?.accountNumber).slice(-4)
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="button_wrap">
                  <Link to="add" className="button">
                    {bankDetail?.accountNumber ? "Edit" : "Add"} Bank Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Helpline />
      </div>

      <DeleteModal
        show={deleteModal}
        handleClose={() => setDeleteModal(false)}
      />
    </>
  );
};

export default TeacherBankDetails;
