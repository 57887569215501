import React, { useEffect, useState } from "react";
import "../MyClasses.scss";
import AllClassesCategories from "./AllClassesCategories";
import CustomModal from "components/modals/CustomModal";
import { LoadingButton } from "components/form";
import { partyPopperImg, rupeeImg } from "assets/images";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { generateFormDataPayload } from "utils/helper";
import { ClassService } from "services";

const ChangeClass = () => {
  const location = useLocation();
  const navigate = useNavigate(false);
  const { changeClassApi } = ClassService();
  const [changeCLassModal, setChangeCLassModal] = useState(false);
  const [changeCLassSuccessModal, setChangeCLassSuccessModal] = useState(false);
  const [payNowModal, setPayNowModal] = useState(false);
  const [selectedClassDetail, setSelectedClassDetail] = useState({});

  useEffect(() => {
    if (location.state) {
      const { classId } = location.state;
      if (!classId) {
        navigate("/my-classes");
      }
    } else {
      navigate("/my-classes");
    }
  }, [location.state]);

  const updateClass = useMutation(
    () => {
      const payload = generateFormDataPayload({
        currentClassId: location.state?.classId,
        newClassId: selectedClassDetail?._id,
        token: localStorage.getItem("fg_token"),
      });
      return changeClassApi(payload);
    },
    {
      onSuccess: (data) => {
        setPayNowModal(false);
        setChangeCLassSuccessModal(true);
      },
    }
  );

  const handleSelectedClassDetail = (data) => {
    const { classFees } = location.state;
    setSelectedClassDetail(data);
    if (data?.fees > classFees) {
      setChangeCLassModal(true);
    } else {
      setPayNowModal(true)
    }
  };

  return (
    <>
      <div className="change_class">
          <AllClassesCategories
            currentClassId={location.state?.classId}
            handleSelectedClassDetail={handleSelectedClassDetail}
          />
      </div>

      <CustomModal
        show={changeCLassModal}
        handleClose={() => setChangeCLassModal(false)}
        className={"change_class_charges"}
      >
        <h3>Change Class</h3>
        <h6>Your new class price is less than your previous one.</h6>
        <ul>
          <li>New class price is Rs. {selectedClassDetail?.fees}</li>
          <li>Old Class price was Rs. {location.state?.classFees}</li>
        </ul>
        <p>
          Please on confirm button to purchase this new class. We will add this
          extra amount of Rs.{" "}
          {selectedClassDetail?.fees - location.state?.classFees} in your
          balance account. You will be able to use this balance for another
          class purchase.
        </p>
        <div className="button_wrap">
          <LoadingButton
            loading={updateClass.isLoading}
            onClick={updateClass.mutate}
          >
            Confirm
          </LoadingButton>
        </div>
      </CustomModal>
      {/* Sikka Modal */}
      <CustomModal
        show={payNowModal}
        handleClose={() => setPayNowModal(false)}
        className={"change_class_charges payNow"}
      >
        <img src={rupeeImg} alt="" />
        <h3>Pay Now</h3>
        <p>
          Lorem ipsum dolor sit amet consectetur. Scelerisque enim nisi vivam us
          facilisis. Mauris fermentum dictumst viverra adipiscing at. Hac e
          gestas praesent facilisi quis lobortis nam.
        </p>
        <label id="balance">
          <input type="checkbox" id="balance" />
          Use Balance ₹ 300
        </label>
        <div className="button_wrap">
          <LoadingButton loading={updateClass.isLoading} onClick={updateClass.mutate}>
            Pay Now ₹ 300
          </LoadingButton>
        </div>
      </CustomModal>
      {/* End */}
      <CustomModal
        show={changeCLassSuccessModal}
        handleClose={() => {
          navigate("/my-classes");
          setChangeCLassSuccessModal(false)
        }}
        className={"change_class_charges"}
      >
        <img src={partyPopperImg} alt="" />
        <h3>Congratulations</h3>
        <h6>Your course has been successfully changed.</h6>

        <div className="button_wrap">
          <LoadingButton onClick={() => navigate("/my-classes")}>
            View Course
          </LoadingButton>
        </div>
      </CustomModal>
    </>
  );
};

export default ChangeClass;
