import HttpService from "services/http";

const RatingService = () => {
    const { httpRequest, httpFormRequest } = HttpService();

    const getRatings = (search) => new Promise((resolve, reject) => {
        httpRequest("GET", "user/rating/list", {}, search)
            .then(resolve)
            .catch(reject);
    });

    const deleteRating = (search) => new Promise((resolve, reject) => {
        httpRequest("GET", "user/rating/delete", {}, search)
            .then(resolve)
            .catch(reject);
    });

    const addRating = (payload) => new Promise((resolve, reject) => {
        httpFormRequest("POST", "user/rating", payload)
            .then(resolve)
            .catch(reject);
    });

    return { getRatings, addRating, deleteRating };
}
export { RatingService };