import useSnackbar from "hooks/useSnackbar";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useMutation } from "react-query";

import useUser from "hooks/useUser";
import AuthOTP from "pages/auth/AuthOTP";
import CustomModal from "components/modals/CustomModal";
import { Input } from "components/form";
import { AuthService, UserService } from "services";
import { updateStudentProfileValidation } from "validations";
import { addProfilePic, ProfileImg } from "assets/images";
import { generateFormDataPayload } from "utils/helper";
import { contactIcon, IdIcon, nameIcon } from "assets/icons";
import ImageCropper from "components/image-cropper";

const UpdateProfile = ({ editProfileModal, setEditProfileModal }) => {
  const { user, setUser } = useUser();
  const { sendOtp } = AuthService();
  const { snackbar } = useSnackbar();
  const { updateProfile: updateProfileApi } = UserService();
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(updateStudentProfileValidation),
    defaultValues: {
      name: "",
      mobile: "",
    },
  });
  const [state, setState] = useState({
    name: "",
    mobile: "",
    profile: "",
    showVerifyOtpModal: false,
  });

  const profile = watch("profile");
  const updateProfile = useMutation(
    (data) => {
      const { numberVerify, ...other } = data;

      const payload = generateFormDataPayload(
        {
          token: localStorage.getItem("fg_token"),
          countryCode: "+91",
          ...other,
        },
        "profile"
      );

      if (data.mobile !== user.mobile && !numberVerify) {
        setState((prev) => ({
          ...prev,
          ...data,
          countryCode: "+91",
          showVerifyOtpModal: true,
        }));
        return sendOtp({
          countryCode: "+91",
          mobile: data.mobile,
        });
      }

      return updateProfileApi(payload);
    },
    {
      onSuccess: (data) => {
        if (data) {
          const { name, mobile, profile } = data.data.userData;
          setUser({ name, mobile, profile });
          snackbar(data?.message || "", "info");
          setEditProfileModal(false);
        }
      },
      onError: (err) => {
        snackbar(err.data.message || "");
      },
    }
  );

  useEffect(() => {
    if (user?._id?.length) {
      setValue("name", user?.name);
      setValue("mobile", user?.mobile);
      setValue("mobile", user?.mobile);
    }
  }, [user]);

  return (
    <>
      <CustomModal
        show={editProfileModal}
        handleClose={() => setEditProfileModal(false)}
        className={"editProfileModal auth singUp "}
      >
        <h3>Edit Profile</h3>
        <form
          onSubmit={handleSubmit((data) =>
            updateProfile.mutate({ ...data, numberVerify: false })
          )}
        >
          {/* <div className="profilePic">
            <img
              src={
                profile?.name
                  ? URL.createObjectURL(profile)
                  : user.profile?.length
                    ? user.profile
                    : ProfileImg
              }
              alt=""
              className="profile"
            />
            <label htmlFor="upload_profile" type="button">
              <img src={addProfilePic} alt="" className="add_img" />
              <input
                type="file"
                id="upload_profile"
                className="uploadInput"
                onChange={(e) => setValue("profile", e.target.files[0])}
              />
            </label>
          </div> */}

          <ImageCropper
            prevImage={
              profile?.name
                ? profile
                : user.profile?.length
                ? user.profile
                : null
            }
            imageSelect={(e) => setValue("profile", e)}
          />

          <div className="form_field ">
            <Input
              placeholder=" Enter Name"
              control={control}
              name="name"
              error={errors?.name?.message}
            />
            <span className="country">
              <img src={nameIcon} alt="" />
            </span>
          </div>
          <div className="form_field ">
            <Input placeholder="Enter Id" disabled value={user.studentNumber} />
            <span className="country">
              <img src={IdIcon} alt="" />
            </span>
          </div>
          <div className="form_field">
            <Input
              placeholder=" Enter India  Mobile Number"
              type="number"
              maxLength={10}
              control={control}
              name="mobile"
              error={errors?.mobile?.message}
            />
            <span className="country">
              <img src={contactIcon} alt="" />
            </span>
          </div>

          <div className="button_wrap button_center">
            <button
              type="submit"
              className="button w-100"
              disabled={updateProfile.isLoading}
            >
              Update
            </button>
          </div>
        </form>
      </CustomModal>

      {/* verify Otp  */}
      <CustomModal
        show={state.showVerifyOtpModal}
        handleClose={() =>
          setState((prev) => ({ ...prev, showVerifyOtpModal: false }))
        }
        className={"authModal"}
      >
        <AuthOTP
          closeAuthModel={() => {}}
          setLoginSteps={() => {}}
          updateMobile={(isSuccess) => {
            setState((prev) => ({ ...prev, showVerifyOtpModal: false }));
            if (isSuccess) {
              updateProfile.mutate({
                name: state.name,
                mobile: state.mobile,
                profile: state.profile,
                numberVerify: true,
              });
            }
          }}
          credentials={{
            type: "web",
            deviceToken: "12345",
            countryCode: "+91",
            mobile: state.mobile,
          }}
        />
      </CustomModal>
    </>
  );
};

export default UpdateProfile;
