import {
  studentFeedbackImg1,
  studentFeedbackImg2,
  studentFeedbackImg3,
  studentFeedbackImg4,
} from "assets/images";
import NoData from "components/no-data";
import React from "react";
import { FaCaretRight } from "react-icons/fa";
import { useQuery } from "react-query";
import Slider from "react-slick";
import { SettingService } from "services";
const settings = {
  dots: true,
  arrows: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 2000,
  infinite: false,
};

const StudentFeedbackSlider = () => {
  const { getStudentWorks } = SettingService();

  const { data } = useQuery({
    queryKey: ["student_feedback"],
    queryFn: async () =>
      getStudentWorks({
        token: localStorage.getItem("fg_token"),
        type: "feedback",
      }),
  });

  const feedbackList = data?.data?.list || [];

  return (
    <>
      <div className="student_works spacing">
        <div className="container">
          <div className="page_title">
            <h3>Students Feedback</h3>
          </div>
          <div className="student_works_slider student_feedback">
            {feedbackList?.length > 0 ? (
              <Slider {...settings}>
                {feedbackList?.map((it) => {
                  return (
                    <div className="item" key={it.id}>
                      <img src={it?.feedback?.imageUrl} alt="" />
                      <span className="nameTag">{it?.feedback?.name}</span>
                      <button
                        type="button"
                        className="video-play-button"
                        onClick={() => window.open(it?.work?.youtubeUrl)}
                      >
                        <FaCaretRight />
                      </button>
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <NoData desc="No Student Feedback Available" />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentFeedbackSlider;
