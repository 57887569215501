import { courseImg2 } from "assets/images";
import React from "react";
import { FaCaretRight } from "react-icons/fa";
import Skeleton from "components/skeleton";

const TeacherSyllabus = ({ data, isLoading }) => {

  return (
    <>
      <div className="Syllabus">
        {
          !isLoading
            ?
            <p
              dangerouslySetInnerHTML={{ __html: data }}
            />
            :
            <Skeleton />
        }

        {/* <div className="item">
          <p>1. Basic List Of Material and tools Required for Stitching</p>
          <img src={courseImg2} alt="" />
        </div>

        <div className="item">
          <p>. Basic List Of Material and tools Required for Stitching</p>

          <div className="video_thumbnail">
            <img src={courseImg2} alt="" />
            <button type="button" className="video-play-button">
              <FaCaretRight />
            </button>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default TeacherSyllabus;
