import React from 'react';
import Card from 'react-bootstrap/Card';
import Placeholder from 'react-bootstrap/Placeholder';

const Skeleton = ({ button }) => {
    return (
        <div style={{ marginBottom: "10px" }}>
            <Placeholder as={Card.Title} animation="glow">
                <Placeholder xs={6} />
            </Placeholder>
            <Placeholder as={Card.Text} animation="glow">
                <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                <Placeholder xs={6} /> <Placeholder xs={8} />
            </Placeholder>

            {
                button
                    ?
                    < Placeholder as={Card.Text} animation="glow">
                        <Placeholder.Button xs={4} aria-hidden="true" />
                    </Placeholder>
                    :
                    null
            }
        </div >
    )
}

export default Skeleton