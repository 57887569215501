import Joi from "joi";

export const ratingValidation = Joi.object({
    rating: Joi
        .number()
        .required()
        .label("Rating")
        .messages({
            "string.empty": "{{#label}} is required"
        }),
    comment: Joi
        .string()
        .required()
        .label("Comment")
        .messages({
            "string.empty": "{{#label}} is required"
        }),
    video: Joi
        .object()
        .required()
        .label("Video")
        .messages({
            "string.empty": "{{#label}} is required"
        })
});