import FlatList from "components/FlatList";
import React from "react";

const CHAT_TABS = [
  { name: "Chat", key: "chat" },
  { name: "Important", key: "important" },
  { name: "Student Guideline", key: "student_guideline" },
  { name: "Syllabus", key: "syllabus" },
  { name: "Required Tools", key: "required_tools" },
  { name: "Student Works", key: "student_work" },
  { name: "Study Material", key: "study_material" },
  { name: "Group Info", key: "group_info" },
];

const Tabs = ({ selectedTab, setSelectedTab }) => {
  return (
    <div className="tab_button_wrap">
      <ul>
        <FlatList
          data={CHAT_TABS}
          renderItem={(it) => {
            return (
              <li key={it.key} onClick={() => setSelectedTab(it.key)}>
                <button className={selectedTab === it.key ? "active" : ""}>
                  <span>{it.name}</span>
                </button>
              </li>
            );
          }}
        />
      </ul>
    </div>
  );
};

export default Tabs;
