import { useSnackbar as snackbarHook } from "notistack";

const useSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = snackbarHook();

  const action = (key) => (
    <div
      style={{ cursor: "pointer", paddingRight: "13px" }}
      onClick={() => {
        closeSnackbar(key);
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="currentColor"
        className="bi bi-x-circle-fill"
        viewBox="0 0 16 16"
      >
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
      </svg>
    </div>
  );

  const snackbar = (
    message = "Please enter",
    variant = "warning",
    persist = false
  ) =>
    enqueueSnackbar(
      <div
        style={{
          colo: "#fff",
          fontSize: "14px",
          fontWeight: 600,
          paddingBottom: "4px",
        }}
      >
        {message}
      </div>,
      {
        persist,
        variant,
        style: { backgroundColor: variant === "info" ? "#118CFC" : "default" },
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        action: !persist && action,
      }
    );

  return { snackbar, closeSnackbar };
};

export default useSnackbar;
