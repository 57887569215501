import ManageProfile from "pages/student-access/section/ManageProfile";
import React from "react";

const TeacherManageProfile = () => {
  return (
    <>
      <ManageProfile />
    </>
  );
};

export default TeacherManageProfile;
