import { Input, LoadingButton, TextArea } from "components/form";
import CustomModal from "components/modals/CustomModal";
import TypographyLimit from "components/TypographyLimit";
import useUser from "hooks/useUser";
import React, { useState } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { MyWorkService } from "services/my-work";
import { generateFormDataPayload } from "utils/helper";

const MyWorks = ({ classId }) => {
  const file = React.useRef(null);
  const { user } = useUser();
  const [moreContent, setMoreContent] = useState(false);
  const [workDescription, setWorkDescription] = useState({});
  const { addWork, getWorkListApi } = MyWorkService();
  const [state, setState] = useState({
    showAddWorkModal: false,
    description: "",
    file: null,
    thumbnailFile: undefined,
  });
  const [error, setError] = useState({});

  const getWorkList = useQuery({
    queryKey: ["my-work"],
    queryFn: async () =>
      getWorkListApi({
        classId,
        studentId: user._id,
        token: localStorage.getItem("fg_token"),
      }),
  });

  const uploadWork = useMutation(
    (data) => {
      return addWork(data);
    },
    {
      onSuccess: (data) => {
        getWorkList.refetch();
        setState((prev) => ({
          ...prev,
          showAddWorkModal: false,
          file: null,
          description: "",
          thumbnailFile: undefined,
        }));
      },
    }
  );

  const handleAddWork = () => {
    let isValidate = true;
    if (!state.description.length) {
      setError((prev) => ({
        ...prev,
        description: "Please enter description",
      }));
      isValidate = false;
    }
    if (!state.file?.name) {
      setError((prev) => ({
        ...prev,
        file: "Please select an image or video",
      }));
      isValidate = false;
    }

    if (!isValidate) return;

    // Prepare FormData
    const payload = generateFormDataPayload({
      classId,
      studentId: user._id,
      description: state.description,
      token: localStorage.getItem("fg_token"),
    });
    payload.append("file", state.file);

    if (state.thumbnailFile) {
      payload.append("videoThumb", state.thumbnailFile);
    }

    uploadWork.mutate(payload);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("video/")) {
      const videoURL = URL.createObjectURL(file);

      // Create a video element
      const video = document.createElement("video");
      video.src = videoURL;
      video.crossOrigin = "anonymous";

      video.onloadeddata = () => {
        // Set the time for the thumbnail capture
        video.currentTime = 1; // Captures a frame 1 second into the video
      };

      video.onseeked = () => {
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Convert the canvas to a data URL and then to a Blob
        canvas.toBlob((blob) => {
          if (blob) {
            const thumbnailFile = new File([blob], "thumbnail.jpg", {
              type: "image/jpeg",
            });

            // Prepare FormData
            // const payload = generateFormDataPayload({
            //   classId,
            //   studentId: user._id,
            //   token: localStorage.getItem("fg_token")
            // })
            // payload.append("file", file);
            // payload.append("videoThumb", thumbnailFile);

            // Send the FormData to the server
            setState((prev) => ({ ...prev, file: file, thumbnailFile }));
          }
        }, "image/jpeg");

        URL.revokeObjectURL(videoURL);
      };
    } else {
      // const payload = generateFormDataPayload({
      //   classId,
      //   studentId: user._id,
      //   token: localStorage.getItem("fg_token")
      // })
      // payload.append("file", file);

      // uploadWork.mutate(payload);
      setState((prev) => ({ ...prev, file: file }));
    }
  };

  return (
    <>
      <div className="my_works">
        <div className="work_card_wrap">
          {getWorkList?.data?.data?.data?.work?.map((work, index) => {
            if (work.type === "image") {
              return (
                <div className="item" key={index}>
                  <img src={work?.file} alt="" width={200} height={200} />
                  <p>
                    <TypographyLimit text={work?.description} limit={230} />{" "}
                    <button
                      type="button"
                      className="read_more"
                      onClick={() => {
                        setMoreContent(true);
                        setWorkDescription(work?.description);
                      }}
                    >
                      Read more
                    </button>
                  </p>
                </div>
              );
            } else if (work.type === "video") {
              return (
                <div className="item" key={index}>
                  <video controls width={200} height={200}>
                    <source src={work?.file} type="video/mp4" />
                  </video>
                  <p>{work?.description}</p>
                </div>
              );
            }
          })}
        </div>
        <div className="button_wrap">
          <button
            type="button"
            className="button"
            onClick={() =>
              setState((prev) => ({ ...prev, showAddWorkModal: true }))
            }
          >
            Upload Work
          </button>
        </div>
      </div>

      {/* upload work  */}
      <CustomModal
        show={state.showAddWorkModal}
        handleClose={() => {
          setState((prev) => ({ ...prev, showAddWorkModal: false }));
        }}
      >
        <h4>Upload Works <span className="blue">(Image or Video)</span></h4>
        <form>
          <div className="form_field">
            <TextArea
              name="name"
              label="Description"
              payload="Enter description"
              onChange={(e) =>
                setState((prev) => ({ ...prev, description: e.target.value }))
              }
              value={state.description}
              error={error?.description}
            />
          </div>
          <div className="form_field">
            <label className="upload_file input form-control">
              <input
                type="file"
                name="file"
                label="Image or Video"
                onChange={handleFileChange}
                error={error?.file}
              />
              <span className="upload_icon">
                <MdOutlineFileUpload /> Upload file
              </span>
              <span className="fileName">
                {" "}
                <TypographyLimit
                  text={state?.file ? state?.file?.name : ""}
                  limit={"25"}
                />{" "}
              </span>
            </label>

            {error?.file && <div>{error?.file}</div>}
          </div>

          <div className="button_wrap button_center">
            <LoadingButton
              type="button"
              className="button pink"
              onClick={handleAddWork}
              loading={uploadWork.isLoading}
            >
              <input
                ref={file}
                type="file"
                hidden
                onChange={handleFileChange}
              />
              Upload Work
            </LoadingButton>
          </div>
        </form>
      </CustomModal>

      <CustomModal show={moreContent} handleClose={() => setMoreContent(false)}>
        <p>{workDescription}</p>
      </CustomModal>
    </>
  );
};

export default MyWorks;
