import Joi from "joi";

export const applyJobValidation = Joi.object({
    name: Joi
        .string()
        .required()
        .label("Name")
        .messages({
            "string.empty": "{{#label}} is required"
        }),
    mobile: Joi
        .string()
        .required()
        .min(10)
        .label("Phone Number")
        .messages({
            "string.empty": "{{#label}} is required"
        }),
    email: Joi
        .string()
        .required()
        .email({ tlds: { allow: false } })
        .label("Email")
        .messages({
            "string.empty": "{{#label}} is required"
        }),
    coverLetter: Joi
        .string()
        .required()
        .label("Message")
        .messages({
            "string.empty": "{{#label}} is required"
        }),
    resume: Joi
        .object()
        .required()
        .label("Resume")
        .messages({
            "string.empty": "{{#label}} is required"
        })
});