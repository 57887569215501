import { logoImg2 } from "assets/images";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaBarsStaggered } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";
import "./Header.scss";
import CustomModal from "components/modals/CustomModal";
import AuthLogin from "pages/auth/AuthLogin";
import SignUp from "pages/auth/SignUp";
import AuthOTP from "pages/auth/AuthOTP";
import DeactivatedAccount from "pages/auth/Deactivated";
import Avatar from "./Avatar";
import useUser from "hooks/useUser";
import { useAppDispatch, useAppSelector, toggleLoginModal } from "../../redux";
import { Button } from "components/form";

const Header = () => {
  const shared = useAppSelector((state) => state?.sharedSlice);
  const dispatch = useAppDispatch();
  const { isLoggedIn } = useUser();
  const location = useLocation();
  const isLogin = isLoggedIn;
  const [loginSteps, setLoginSteps] = useState("login");
  const [credentials, setCredentials] = useState({});
  const tab = location.pathname?.split("/")[1];

  const closeAuthModel = () => {
    dispatch(toggleLoginModal());
    setLoginSteps("login");
  };

  return (
    <>
      <header className="header">
        <div className="container">
          <div className="logo">
            <Link to="/">
              <img src={logoImg2} alt="" />
            </Link>
          </div>
          <div className="header_nav_wrap">
            <nav className="header_nav">
              <ul>
                <li>
                  <Link to="" className={tab !== "donation" && "classes"}>
                    Online Classes
                  </Link>
                </li>
                <li>
                  {isLoggedIn ? (
                    <Link
                      to="/donation"
                      className={tab === "donation" && "classes"}
                    >
                      Donate
                    </Link>
                  ) : (
                    <Button
                      style={{ boxShadow: "none" }}
                      className={tab === "donation" && "classes"}
                      onClick={() => dispatch(toggleLoginModal())}
                    >
                      Donate
                    </Button>
                  )}
                </li>
                {!isLogin && (
                  <li>
                    <button
                      type="button"
                      className="login_button"
                      onClick={() => dispatch(toggleLoginModal())}
                    >
                      Login / Signup
                    </button>
                  </li>
                )}
              </ul>
            </nav>

            {isLogin && <Avatar />}

            <div className="mobile_button">
              <button type="button">
                <FaBarsStaggered />
                <FaTimes />
              </button>
            </div>
          </div>
        </div>
      </header>

      <CustomModal
        show={shared.showLoginModal}
        handleClose={closeAuthModel}
        className={"authModal"}
      >
        {loginSteps === "login" && (
          <AuthLogin
            setCredentials={(data) =>
              setCredentials((prev) => ({ ...prev, ...data }))
            }
            setLoginSteps={(step) => setLoginSteps(step)}
          />
        )}
        {loginSteps === "singUp" && (
          <SignUp
            onClick={() => setLoginSteps("otp")}
            credentials={credentials}
            closeAuthModel={closeAuthModel}
          />
        )}
        {loginSteps === "otp" && (
          <AuthOTP
            closeAuthModel={closeAuthModel}
            setLoginSteps={(step) => setLoginSteps(step)}
            credentials={credentials}
          />
        )}
        {loginSteps === "suspend" && <DeactivatedAccount />}
      </CustomModal>
    </>
  );
};

export default Header;
