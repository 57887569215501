import { searchIcon } from "assets/icons";
import React, { useState } from "react";
import Tabs from "./section/Tabs";
import ChatList from "./section/ChatList";
import Important from "./section/Important";
import StudentGuideline from "./section/StudentGuideline";
import Syllabus from "./section/Syllabus";
import RequiredTools from "./section/RequiredTools";
import StudentWork from "./section/StudentWork";
import StudyMaterial from "./section/StudyMaterial";
import GroupInfo from "./section/GroupInfo";
import { useSelector } from "react-redux";

const MessageSection = () => {
  const { selectedClass, socket } = useSelector((state) => state.chat);

  const [search, setSearch] = useState("");

  const [selectedTab, setSelectedTab] = useState("chat");

  return (
    <div className="chat_section_content">
      <div className="chat_search_container">
        <div className="name_width_chat">
          <div className="profile_dp">
            {/* <img src="" alt="" /> */}
            <span className="profileName">{selectedClass.chatName?.at(0)}</span>
            {+selectedClass.is_online === 1 && (
              <span className="active_Icon"></span>
            )}
          </div>
          <p>
            <b>{selectedClass.chatName}</b>
          </p>
        </div>
        <div className="search_wrap">
          <input
            type="search"
            placeholder="Search for class name"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <img src={searchIcon} alt="" />
        </div>
      </div>
      <div className="_card">
        <div className="_card_content">
          {/* tabs section */}
          <Tabs {...{ selectedTab, setSelectedTab }} />

          {/* tab content */}
          {selectedTab === "chat" && <ChatList />}
          {selectedTab === "important" && (
            <Important {...{ socket, selectedClass }} />
          )}
          {selectedTab === "student_guideline" && <StudentGuideline />}
          {selectedTab === "required_tools" && <RequiredTools />}
          {selectedTab === "syllabus" && <Syllabus />}
          {selectedTab === "student_work" && <StudentWork />}
          {selectedTab === "study_material" && <StudyMaterial />}
          {selectedTab === "group_info" && <GroupInfo />}
        </div>
      </div>
    </div>
  );
};

export default MessageSection;
