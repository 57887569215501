import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "../slices/cart";
import classSlice from "../slices/class";
import sharedSlice from "../slices/shared";
import helplineSlice from "../slices/helpline";
import chatSlice from "../slices/chat";

export const store = configureStore({
  reducer: {
    cartSlice,
    classSlice,
    sharedSlice,
    helpline: helplineSlice,
    chat: chatSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
