import { searchIcon } from "assets/icons";
import { SwitchButton } from "components/form";
import useDebounce from "hooks/useDebounce";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { ClassService } from "services";
import { generateFormDataPayload } from "utils/helper";

const StudentsList = ({ classId }) => {
  const [search, setSearch] = useState("")
  const { purchaseClassMembers, updateStudentPassFailStatus } = ClassService();

  const debounce = useDebounce(search);
  const { data, refetch } = useQuery({
    queryKey: ["class-student-list", debounce],
    queryFn: async () =>
      purchaseClassMembers({
        token: localStorage.getItem("fg_token"),
        classId,
        search: debounce
      }),
  });

  const updateStatus = useMutation(data => {
    console.log({
      ...data,
      classId,
      token: localStorage.getItem("fg_token")
    });

    const payload = generateFormDataPayload({
      ...data,
      classId,
      token: localStorage.getItem("fg_token")
    })
    return updateStudentPassFailStatus(payload);
  }, {
    onSuccess: (data) => {
      console.log({ data });
      refetch();
    },
    onError: (err) => {
      console.log({ err })
    }
  });

  return (
    <>
      <div className="students_list_wrap">
        <h4 className="sub_title">Student List</h4>
        <div className="search_wrap">
          <input type="search" placeholder="Search for class name" value={search} onChange={e => setSearch(e.target.value)} />
          <img src={searchIcon} alt="" />
        </div>
        <div className="student_list">
          <ul>
            <li>
              <b className="_student">Student No.</b>
              <b className="_name">Name</b>
              <b className="_pass">Pass</b>
              <b className="_attendance">Attendance</b>
              <b className="_work">Work</b>
            </li>
            {
              data?.data?.data?.map(student => {
                return <li>
                  <span className="_student">{student?.studentId}</span>
                  <span className="_name">{student?.studentName}</span>
                  <span className="_pass">
                    <SwitchButton checked={student?.isPass} onChange={() => updateStatus.mutate({ studentId: student?._id, isPass: !student?.isPass })} />
                  </span>
                  <span className="_attendance">{student?.attendanceCount}/{student?.batchDuration}</span>
                  <span className="_work">Works ({student?.studentWorkCount})</span>
                </li>
              })
            }
          </ul>

          <div className="attendance_summery">
            <ul>
              <li>Total Students : {data?.data?.total}</li>
              <li>Passed : {data?.data?.passTotal}</li>
              <li>Failed : {data?.data?.failTotal}</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentsList;
