import React from "react";

const Syllabus = () => {
  return (
    <>
      <div className="">
        <h1>Syllabus </h1>
      </div>
    </>
  );
};

export default Syllabus;
