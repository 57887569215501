import {
  appImg,
  bannerSliderImg,
  fixedSlide1Img,
  fixedSlide2Img,
  fixedSlide3Img,
  fixedSlide4Img,
  objectImg,
  onlineCLasses1,
  onlineCLasses2,
  onlineCLasses3,
  onlineCLasses4,
  onlineCLasses5,
  onlineClassImg1,
  onlineClassImg2,
  onlineClassImg3,
  onlineClassImg4,
  onlineClassImg5,
  onlineClassImg6,
  onlineClassStepImg1,
  onlineClassStepImg2,
  onlineClassStepImg3,
  onlineClassStepImg4,
  onlineClassStepImg5,
} from "assets/images";
import { Button } from "components/form";
import React from "react";
import "./HomePage.scss";
import {
  appStoreIcon,
  bannerRatingIcon,
  downArrowIcon,
  liveClassesGIF,
  playStoreIcon,
} from "assets/icons";
import { FaCirclePlay } from "react-icons/fa6";
import { useQuery } from "react-query";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import AllClassesCategories from "components/all-classes-categories/AllClassesCategories";
import Faqs from "components/faqs/FAQs";
import useUser from "hooks/useUser";
import moment from "moment";

import { SettingService } from "services";
import { isDateBefore25 } from "utils/helper";
import HeaderMenu from "components/header-menu";
import CountUp, { useCountUp } from "react-countup";
import StudentFeedbackSlider from "./StudentFeedbackSlider";
import TeacherSlider from "./TeacherProfile";
import Helpline from "components/helpline/Helpline";
import { TypeAnimation } from "react-type-animation";
import StudentWorks from "./StudentWorks";

const BANNER_REDIRECTION = {
  applyForJob: "/jobs",
  allClasses: "/",
  myClasses: "/my-classes",
  donation: "/donation",
  others: "/",
};

const HomePage = () => {
  const { getBanners } = SettingService();
  const { isLoggedIn } = useUser();

  const navigate = useNavigate();

  useCountUp({
    ref: "counter",
    end: 1234567,
    enableScrollSpy: true,
    scrollSpyDelay: 1000,
  });

  const bannerSlider = {
    dots: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    infinite: true,
    fade: true,
    cssEase: "linear",
  };

  const registrationDate =
    moment().endOf("month").format("D") === "31"
      ? moment().endOf("month").subtract(1, "days").format("DD")
      : moment().endOf("month").format("DD");

  const { data } = useQuery({
    queryKey: ["class_banners"],
    queryFn: () =>
      getBanners({
        type: "web",
        // selectScreen: "allClasses",
      }),
  });

  const { getTeachers } = SettingService();

  const { data: teachersData } = useQuery({
    queryKey: ["teachers"],
    queryFn: async () =>
      getTeachers({
        token: localStorage.getItem("fg_token"),
      }),
  });

  const teacherList = teachersData?.data?.list || [];

  // banner navigation handler
  const bannerNavigationHandler = (key) => {
    if (isLoggedIn) {
      navigate(BANNER_REDIRECTION[key]);
    }
  };

  return (
    <>
      {isLoggedIn ? <HeaderMenu /> : null}

      <div className={`homePage ${isLoggedIn ? "withLogin" : ""}`}>
        {!isDateBefore25() && isLoggedIn ? null : (
          <div className="home_banner">
            <div className="container">
              <div className="content_left">
                <div className="tag_img">
                  <img
                    src={liveClassesGIF}
                    alt="class-image"
                    className="classIcon"
                  />
                  <img
                    src={bannerRatingIcon}
                    alt="class-image"
                    className="ratingIcon"
                  />
                </div>

                <h1>
                  Getting <span>Quality</span> Education is Now More{" "}
                  {/* <span className="underline">Easy</span> */}
                  <TypeAnimation
                    sequence={["Easy", 1000, "", 1000]}
                    wrapper="span"
                    // className="underline"
                    speed={10}
                    deletionSpeed={10}
                    repeat={Infinity}
                    cursor={false}
                  />
                </h1>
                <p>
                  Free Gurukul Foundation providing online classes at affordable
                  fees.{" "}
                </p>

                <div className="button_wrap">
                  <Button>Get Started</Button>
                  <Button className={"has_icon"}>
                    <FaCirclePlay /> Watch Student Works{" "}
                  </Button>
                </div>
              </div>
              <div className="slider_section">
                <div className="image_wrap">
                  <Slider {...bannerSlider}>
                    {data?.data?.list?.length ? (
                      data.data.list?.map((banner) => {
                        return (
                          <div
                            className="item"
                            key={banner._id}
                            onClick={() =>
                              bannerNavigationHandler(banner.selectScreen)
                            }
                          >
                            <img
                              key={banner._id}
                              src={banner.imageUrl}
                              alt=""
                            />
                          </div>
                        );
                      })
                    ) : (
                      <div className="item">
                        <img src={bannerSliderImg} alt="" />
                      </div>
                    )}
                  </Slider>
                  <img src={objectImg} alt="" className="object_img" />
                </div>
                <ul className="fixed_images">
                  <li>
                    <img src={fixedSlide1Img} alt="" />
                  </li>
                  <li>
                    <img src={fixedSlide2Img} alt="" />
                  </li>
                  <li>
                    <img src={fixedSlide3Img} alt="" />
                  </li>
                  <li>
                    <img src={fixedSlide4Img} alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}

        {!isDateBefore25() && isLoggedIn ? null : (
          <div className="information">
            <div className="container">
              <ul>
                <li className="online">
                  <span>Online Classes</span>
                </li>
                <li className="registration">
                  <span>registration</span>
                </li>
                <li className="closed">
                  <span>{isDateBefore25() ? "closed" : "opened"}</span>
                </li>
                <li className="reOpen">
                  <span>
                    {isDateBefore25() ? (
                      "Re Open On 25th"
                    ) : (
                      <>
                        Close on {registrationDate}
                        <sup>th</sup>
                      </>
                    )}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        )}

        {/* Categories */}
        <AllClassesCategories
          title={!isDateBefore25() && isLoggedIn ? false : true}
        />

        {!isDateBefore25() && isLoggedIn ? null : (
          <div className="online_class_process spacing">
            <div className="container">
              <div className="page_title">
                <h3>Online Classes Process</h3>
              </div>

              <div className="classes_process">
                <div className="image_left">
                  <img src={onlineClassImg1} alt="" className="first" />
                  <img
                    src={onlineClassImg2}
                    alt=""
                    className="centerImg second"
                  />
                  <img src={onlineClassImg3} alt="" className="third" />
                </div>
                <ul>
                  <li>
                    <img src={onlineClassStepImg1} alt="" />
                    <p>
                      Every month registration starts on{" "}
                      <b className="purple">25th</b> to{" "}
                      <b className="purple">30th</b>
                    </p>
                  </li>
                  <li>
                    <img src={onlineClassStepImg2} alt="" />
                    <p>
                      Join the <b className="blue">LIVE</b>class on{" "}
                      <b className="blue">1st</b>
                    </p>
                  </li>
                  <li>
                    <img src={onlineClassStepImg3} alt="" />
                    <p>
                      Ask doubt in <b className="red">Group Chat</b>
                    </p>
                  </li>
                  <li>
                    <img src={onlineClassStepImg4} alt="" />
                    <p>
                      <b className="orange">Complete</b> given home work
                    </p>
                  </li>
                  <li>
                    <img src={onlineClassStepImg5} alt="" />
                    <p>
                      Get <b className="green">Certificate</b>
                    </p>
                  </li>
                </ul>
                <div className="image_left image_right">
                  <img src={onlineClassImg4} alt="" className="first" />
                  <img
                    src={onlineClassImg5}
                    alt=""
                    className="centerImg second"
                  />
                  <img src={onlineClassImg6} alt="" className="first third" />
                </div>
              </div>
            </div>
          </div>
        )}

        {/* student works */}
        <StudentWorks {...{ isLoggedIn }} />

        {!isDateBefore25() && isLoggedIn ? null : <StudentFeedbackSlider />}

        {/* Teachers */}

        {!isDateBefore25() && isLoggedIn ? null : (
          <TeacherSlider {...{ teacherList }} />
        )}

        {/* About ONline Classes */}

        {!isDateBefore25() && isLoggedIn ? null : (
          <div className="about_online_classes spacing">
            <div className="container">
              <div className="page_title">
                <h3>About Online Classes</h3>
              </div>
              <div className="classes_feature card_wrap">
                <ul>
                  <li>
                    <div className="item_wrap">
                      <div className="item">
                        <div className="content">
                          <b>
                            <CountUp
                              start={2000}
                              end={38000}
                              enableScrollSpy
                              duration={2}
                            />
                            +
                          </b>

                          <span>Students</span>
                        </div>
                        <img src={onlineCLasses1} alt="" />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="item_wrap">
                      <div className="item">
                        <div className="content">
                          <b>
                            <CountUp
                              start={3000}
                              end={31000}
                              enableScrollSpy
                              duration={2.5}
                            />
                            +
                          </b>
                          <span>Certified</span>
                        </div>
                        <img src={onlineCLasses2} alt="" />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="item_wrap">
                      <div className="item">
                        <div className="content">
                          <b>
                            {" "}
                            <CountUp
                              start={200}
                              end={1100}
                              enableScrollSpy
                              duration={2.9}
                            />
                            +
                          </b>
                          <span>Batches</span>
                        </div>
                        <img src={onlineCLasses3} alt="" />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="item_wrap">
                      <div className="item">
                        <div className="content">
                          <b>
                            <CountUp
                              start={20}
                              end={120}
                              enableScrollSpy
                              duration={2.9}
                            />
                            +
                          </b>
                          <span>Courses</span>
                        </div>
                        <img src={onlineCLasses4} alt="" />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="item_wrap">
                      <div className="item">
                        <div className="content">
                          <b>
                            <CountUp end={2} enableScrollSpy duration={2.9} />+
                          </b>
                          <span>Years</span>
                        </div>
                        <img src={onlineCLasses5} alt="" />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}

        {/* Faq */}
        <Faqs screenType="allClasses" />

        {/* Helpline */}
        <Helpline />

        {!isDateBefore25() && isLoggedIn ? null : (
          <div className="aboutApp spacing">
            <div className="container">
              <div className="content_section">
                <strong>
                  <img src={downArrowIcon} alt="" /> Download App
                </strong>
                <h2>
                  Learn from <span>anywhere</span>
                </h2>
                <p>
                  We’re available on Android, iOS devices. Learn from anywhere
                  at your convenience.
                </p>
                <div className="button_wrap">
                  <Link to="" target="_blank">
                    <img src={playStoreIcon} alt="" />
                  </Link>
                  <Link to="" target="_blank">
                    <img src={appStoreIcon} alt="" />
                  </Link>
                </div>
              </div>
              <div className="image_section">
                <img src={appImg} alt="" />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default HomePage;
