import {
  chatAttached,
  chatCamera,
  chatSendButton,
  trashIcon,
} from "assets/icons";
import FlatList from "components/FlatList";
import NoData from "components/no-data";
import useSnackbar from "hooks/useSnackbar";
import React, { useEffect, useState } from "react";
import { Placeholder } from "react-bootstrap";
import { useSelector } from "react-redux";
import Message from "./media/Message";
import useUser from "hooks/useUser";
import CustomModal from "components/modals/CustomModal";
import { Input, Select } from "components/form";
import { errorImg } from "assets/images";

const ChatList = () => {
  const { selectedClass, socket } = useSelector((state) => state.chat);

  const { user } = useUser();

  const { snackbar } = useSnackbar();
  const [chatList, setChatList] = useState([]);
  const [chatData, setChatData] = useState({});
  const [loading, setLoading] = useState(false);

  const [selectedMessageData, setSelectedMessageData] = useState({
    messageId: "",
    roomId: "",
    reason: "",
    message: "",
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);

  const [inputValue, setInputValue] = useState("");

  // send message handler
  const sendMessageHandler = (e) => {
    e.preventDefault();
    if (!inputValue) return false;

    // send message
    socket.emit("sendMessage", {
      roomId: selectedClass?.roomId,
      message: inputValue,
      mediaType: "text",
    });
    socket.on("sendMessageResponse", (data) => {
      const allMessages = JSON.parse(JSON.stringify(chatList));
      allMessages.push({
        _id: Date.now(),
        mediaType: "text",
        message: inputValue,
        senderId: {
          _id: user?._id,
          name: user?.name,
          roles: user?.roles,
          studentNumber: user?.studentNumber,
        },
      });
      setChatList(allMessages);
    });

    setInputValue("");
  };

  // delete message
  const deleteMessageHandler = () => {
    const { messageId } = selectedMessageData;
    if (messageId) {
      socket.emit("deleteMessage", {
        messageId,
      });
      // Listen for server response
      socket.on("deleteMessageResponse", (data) => {
        setDeleteModal(false);
      });
      setChatList((prev) => {
        return prev.filter((it) => it._id !== messageId);
      });
    }
  };

  // report message
  const reportMessageHandler = () => {
    const { messageId, roomId, reason, message } = selectedMessageData;

    if (messageId && roomId) {
      socket.emit("reportMessage", {
        messageId,
        roomId,
        reason,
        details: message,
      });
      // Listen for server response
      socket.on("reportMessageResponse", (data) => {
        setReportModal(false);
      });
    }
  };

  const reportOnchangeHandler = (e) => {
    const { name, value } = e.target;

    setSelectedMessageData({ ...selectedMessageData, [name]: value });
  };

  // open delete popup
  const openDeleteModal = (messageId) => {
    setDeleteModal(true);
    setSelectedMessageData({ messageId });
  };

  // open report popup
  const openReportModal = (message = {}) => {
    const { roomId, Id: messageId } = message;
    setReportModal(true);
    setSelectedMessageData({ messageId, roomId });
  };

  useEffect(() => {
    socket.on("newMessage", (data) => {
      console.log("🌹 ~ socket.on ~ data:", data);
    });
  }, []);

  useEffect(() => {
    if (selectedClass?.roomId) {
      const fetchChatList = () => {
        setLoading(true);
        socket.emit("getChatMessages", { roomId: selectedClass?.roomId });
        socket.on("getChatMessagesResponse", (data) => {
          try {
            if (data?.status === 200) {
              setChatList(data?.result?.messages || []);
              setChatData(data?.result?.ChatData || {});
            } else {
              snackbar(data?.message || "", "error");
            }
          } catch (e) {
            snackbar(e?.message || "", "error");
          } finally {
            setLoading(false);
          }
        });
      };

      fetchChatList();
    }
    return () => {
      socket.off("getChatMessages");
    };
    // eslint-disable-next-line
  }, [selectedClass?.roomId]);

  return (
    <>
      <div className="chatting">
        <FlatList
          data={chatList}
          renderItem={(list) => {
            return (
              <Message
                key={list._id}
                deleteHandler={() => openDeleteModal(list._id)}
                reportHandler={() => openReportModal(list)}
                {...{ message: list, selectedClass }}
              />
            );
          }}
          placeholder={<NoData />}
          loading={loading}
          numLoading={4}
          loadingItem={(it) => {
            return (
              <div className="chat_sender_wrap loading" key={it}>
                <div className="chat_sender">
                  <div className="profile_dp">
                    <span className="profileName">
                      <Placeholder className="shimmer" />
                    </span>
                  </div>
                  <div className="_card">
                    <div className="_card_content">
                      <Placeholder className="shimmer" />
                      <Placeholder className="shimmer" />
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        />

        <form className="chat_input" onSubmit={sendMessageHandler}>
          <div className="input_wrap">
            <textarea
              className="input"
              placeholder="Type a message..."
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
            <div className="action_button">
              <button type="button">
                <img src={chatAttached} alt="" />
              </button>
              <button type="button">
                <img src={chatCamera} alt="" />
              </button>
            </div>
          </div>
          <button type="submit" disabled={!inputValue}>
            <img src={chatSendButton} alt="" />
          </button>
        </form>
      </div>

      <CustomModal
        show={deleteModal}
        handleClose={() => setDeleteModal(false)}
        className={"chatModal delete"}
      >
        <div className="image_wrap">
          <img src={trashIcon} alt="" />
          <h3>Delete Message</h3>
        </div>
        <p className="text-center my-3">
          Lorem ipsum dolor sit amet consectetur. Scelerisque enim nisi vivam us
          facilisis. Mauris fermentum dictumst viverra adipiscing at. Hac e
          gestas praesent facilisi quis lobortis nam.
        </p>
        <div className="button_wrap">
          <button className="button pink w-100" onClick={deleteMessageHandler}>
            Delete
          </button>
        </div>
      </CustomModal>

      <CustomModal
        show={reportModal}
        handleClose={() => setReportModal(false)}
        className={"chatModal"}
      >
        <div className="image_wrap">
          <img src={errorImg} alt="" />
          <h3>Report Chat</h3>
        </div>
        <div className="form_field_wrap">
          <div className="form_field mw-100">
            <Select
              name="reason"
              label="Select Reason"
              placeholder="Reason"
              value={selectedMessageData?.reason}
              onChange={reportOnchangeHandler}
            >
              <option value="">Select</option>
              <FlatList
                data={[]}
                renderItem={(it) => {
                  return <option key={it.id}>{it.name}</option>;
                }}
              />
            </Select>
          </div>
        </div>
        <div className="form_field_wrap">
          <div className="form_field mw-100">
            <Input
              placeholder="Message"
              name="message"
              value={selectedMessageData?.message}
              onChange={reportOnchangeHandler}
              label="Enter Message"
            />
          </div>
        </div>
        <div className="button_wrap">
          <button
            className="button pink w-100"
            onClick={reportMessageHandler}
            disabled={
              !selectedMessageData?.reason || !selectedMessageData?.message
            }
          >
            Report
          </button>
        </div>
      </CustomModal>
    </>
  );
};

export default ChatList;
