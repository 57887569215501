import React from "react";
import "./JobList.scss";
import { useNavigate } from "react-router-dom";
import { JobService } from "services";
import { useQuery } from "react-query";
import Skeleton from "components/skeleton";
import NoData from "components/no-data";
const JobList = () => {
  const navigate = useNavigate();
  const { getJobs } = JobService();

  const { data, isLoading } = useQuery({
    queryKey: ["job-list"],
    queryFn: async () =>
      getJobs({
        token: localStorage.getItem("fg_token"),
      }),
  });
  console.log("🌹 ~ JobList ~ data:", data);

  const handleNavigation = (vacancyId) => {
    navigate(
      {
        pathname: "/jobs/details",
      },
      {
        state: {
          vacancyId,
        },
      }
    );
  };

  return (
    <>
      <div className="myJobs spacing">
        <div className="container">
          <div className="page_title">
            <h3>Jobs</h3>
          </div>

          <div className="jobListing">
            {isLoading ? (
              <div className="item">
                <div className="job_card">
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </div>
              </div>
            ) : data?.data?.list?.length > 0 ? (
              data?.data?.list?.map((job) => {
                return (
                  <div className="item">
                    <div className="job_card">
                      <h4 className="job_title">{job.vacancyName}</h4>
                      <p
                        className="job_description"
                        dangerouslySetInnerHTML={{ __html: job.description }}
                      />
                      <div className="content_bottom">
                        <p>
                          <b>{job.numberOfVacancies}</b> {""}
                          <small>Vacancies </small>
                        </p>
                        <button
                          className="button"
                          onClick={() =>
                            job.isApplied ? null : handleNavigation(job._id)
                          }
                          disabled={job.isApplied}
                        >
                          {job.isApplied ? "Applied" : "Apply"}
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <NoData title={"Currently don't have openings"} desc={" "} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default JobList;
