import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import { MdOutlineCopyright } from "react-icons/md";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="content_item _about">
            <h5>Free Gurukul</h5>
            <p>
              Free Gurukul is an online learning platform that offers anyone,
              anywhere access to online classes with hundred of varieties for
              creative and curious people, on topics including Yoga, Tailoring,
              Beautician, Chess, Drawing, Video Editing and more.
            </p>
          </div>
          <div className="content_item _resource">
            <h5>Resources</h5>
            <ul>
              <li>
                <Link to="/aboutUs">About Us</Link>
              </li>
              <li>
                <Link to="/faq">FAQ’s</Link>
              </li>
              <li>
                <Link to="">Testimonials</Link>
              </li>
              <li>
                <Link to="/jobs">Apply for Job</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-condition">Terms & Conditions</Link>
              </li>
            </ul>
          </div>
          <div className="content_item _batches">
            <h5>Recent Completed Batches</h5>
            <ul>
              <li>April 2024 - List of Classes</li>
              <li>March 2024 - List of Classes</li>
              <li>February 2024 - List of Classes</li>
              <li>January 2024 - List of Classes</li>
            </ul>
          </div>
          <div className="content_item _contact">
            <h5>Contact Us</h5>
            <p>
              Free Gurukul Education Foundation <br />
              H.No:7-1-218/1, Flat: 303,
              <br />
              JVR Apartment,Opp: Nature Cure Hospital,Balkampet,
              <br />
              Hyderabad, India - 500016
            </p>
            <a href="mailto:support@freegurukul.org">
              Mail: support@freegurukul.org
            </a>
          </div>
        </div>
        <div className="copyRight">
          <div className="container">
            <p>
              <span className="me-1">
                <MdOutlineCopyright />
              </span>
              Free Gurukul, All rights reserved
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
