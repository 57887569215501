import HttpService from "services/http";

const MyWorkService = () => {
    const { httpRequest, httpFormRequest } = HttpService();

    const addWork = (payload) => new Promise((resolve, reject) => {
        httpFormRequest("POST", "user/student_work/upload", payload)
            .then(resolve)
            .catch(reject);
    });

    const getWorkListApi = (search) => new Promise((resolve, reject) => {
        httpRequest("GET", "user/student_work", {}, search)
            .then(resolve)
            .catch(reject);
    });

    return { addWork, getWorkListApi };
}
export { MyWorkService };