import React from "react";
import {
  onlineClassStepImg1,
  onlineClassStepImg2,
  onlineClassStepImg3,
  onlineClassStepImg4,
  onlineClassStepImg5,
} from "assets/images";

const ClassProcess = () => {
  return (
    <div className="online_class_process spacing">
      <div className="container">
        <div className="page_title">
          <h3>Online Classes Process</h3>
        </div>

        <div className="classes_process">
          <ul>
            <li>
              <img src={onlineClassStepImg1} alt="" />
              <p>
                Every month registration starts on{" "}
                <b className="purple">25th</b> to <b className="purple">30th</b>
              </p>
            </li>
            <li>
              <img src={onlineClassStepImg2} alt="" />
              <p>
                Join the <b className="blue">LIVE</b>class on{" "}
                <b className="blue">1st</b>
              </p>
            </li>
            <li>
              <img src={onlineClassStepImg3} alt="" />
              <p>
                Ask doubt in <b className="red">Group Chat</b>
              </p>
            </li>
            <li>
              <img src={onlineClassStepImg4} alt="" />
              <p>
                <b className="orange">Complete</b> given home work
              </p>
            </li>
            <li>
              <img src={onlineClassStepImg5} alt="" />
              <p>
                Get <b className="green">Certificate</b>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ClassProcess;
