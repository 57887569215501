import { verifiedIcon } from "assets/icons";
import { courseImg, ProfileImg } from "assets/images";
import Skeleton from "components/skeleton";
import React from "react";
import { FaCaretRight } from "react-icons/fa";

const TeacherInfo = ({ data, isLoading }) => {
  const teacher = data?.find(ele => ele.isPrimary)

  return (
    <>
      {
        !isLoading
          ?
          <div>
            <div className="_profile">
              <img src={ProfileImg} alt="" className="profile_img" />
              <h5>
                <b>
                  {teacher?.name || ""} <img src={verifiedIcon} alt="" />
                </b>{" "}
                <small>{teacher?.subject || ""}</small>
              </h5>
            </div>
            <p className="experience">
              <span>{teacher?.experience} yrs Experience</span>
              <span>{teacher?.students} Students Trained</span>
              <span>{teacher?.batches} Batches Completed</span>
            </p>
            <p className="teacher_msg">
              {teacher?.about}
            </p>
          </div>
          :
          <Skeleton />
      }

      <div className="rating_review">
        <b>Rating</b>
      </div>
      <div className="teacher_videos">
        <div className="item">
          <img src={courseImg} alt="" />
          <button type="button" className="video-play-button">
            <FaCaretRight />
          </button>
        </div>
        <div className="item">
          <img src={courseImg} alt="" />
          <button type="button" className="video-play-button">
            <FaCaretRight />
          </button>
        </div>
      </div>
    </>
  );
};

export default TeacherInfo;
