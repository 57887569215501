import { logoImg, logoImg2 } from "assets/images";
import { BackButton, Input, LoadingButton, TextArea } from "components/form";
import Helpline from "components/helpline/Helpline";
import CustomModal from "components/modals/CustomModal";
import NoData from "components/no-data";
import useUser from "hooks/useUser";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { UserService } from "services";
import { generateFormDataPayload } from "utils/helper";

const AboutMe = () => {
  const { user } = useUser();
  const queryClient = useQueryClient();
  const { updateProfile } = UserService();
  const [state, setState] = useState({
    showEditModal: false,
    aboutMe: "",
  });
  const [errors, setError] = useState({
    aboutMe: "",
  });

  useEffect(() => {
    setState(prev => ({ ...prev, aboutMe: user?.about || "" }));
  }, [state.showEditModal])

  const updateDetail = useMutation(
    () => {
      if (!state.aboutMe?.length) {
        setError(prev => ({ ...prev, aboutMe: "Please enter about me" }));
        return;
      }

      const payload = generateFormDataPayload(
        {
          aboutMe: state.aboutMe,
          type: "about",
          token: localStorage.getItem("fg_token"),
        });


      return updateProfile(payload);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('userProfile');
        if (data) {
          setState(prev => ({ ...prev, showEditModal: false, aboutMe: "" }));
        }
      },
    }
  );


  return (
    <>
      <div className="about_me spacing">
        <div className="inner_container">
          <div className="_card">
            <div className="_card_content">
              <BackButton />
              <div className="sub_title">About Me</div>
                
              <div className="about_me_content">
              {/* <img src={logoImg2} alt="" className="logo_image"/> */}
                {
                  user?.about
                    ?
                    <p style={{ color:"#000" }}>{user?.about}</p>
                    :
                    <NoData
                      desc="Add your information to show here!"
                    />
                }
                <div className="button_wrap">
                  <button type="button" className="button" onClick={() => setState(prev => ({ ...prev, showEditModal: true }))}>
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit About Me */}
      <CustomModal
        show={state.showEditModal}
        handleClose={() => setState(prev => ({ ...prev, showEditModal: false, aboutMe: "" }))}
      >
        <h3>About Me</h3>

        <form onSubmit={e => {
          e.preventDefault()
          updateDetail.mutate();
        }}>
          <div className="form_field ">
            <TextArea
              placeholder=" Enter Name"
              name="name"
              onChange={(e) => setState(prev => ({ ...prev, aboutMe: e.target.value }))}
              value={state?.aboutMe}
              error={errors?.aboutMe}
            />
          </div>


          <div className="button_wrap button_center">
            <LoadingButton
              type="submit"
              className="button w-100 pink"
              disabled={updateDetail.isLoading}
            >
              Update
            </LoadingButton>
          </div>
        </form>
      </CustomModal>

      <Helpline />
    </>
  );
};

export default AboutMe;
