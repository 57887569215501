import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  socket: null,
  selectedClass: {
    roomId: "",
    chatName: "",
    chatNumber: "",
    chatType: "",
    is_online: "",
  },
  loading: false,
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setSelectedClass: (state, action) => {
      state.selectedClass = action.payload || initialState.selectedClass;
    },
    setSocket: (state, action) => {
      state.socket = action.payload || initialState.socket;
    },
  },
});

export const { setSelectedClass, setSocket } = chatSlice.actions;

export default chatSlice.reducer;
