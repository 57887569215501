import React from "react";
import Skeleton from "components/skeleton";

const StudentGuideline = ({ data, isLoading }) => {

  return (
    <>
      <div className="Syllabus">
        {
          !isLoading
            ?
            <p
              dangerouslySetInnerHTML={{ __html: data }}
            />
            :
            <Skeleton />
        }
      </div>
    </>
  );
};

export default StudentGuideline;
