import React from "react";
import Skeleton from "components/skeleton";

const StudyMaterial = ({ data, isLoading }) => {

  return (
    <>
      <div className="Syllabus">
        {
          !isLoading
            ?
            <p
              dangerouslySetInnerHTML={{ __html: data }}
            />
            :
            <Skeleton />
        }
      </div>
    </>
  );
};

export default StudyMaterial;
