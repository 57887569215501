import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showLoginModal: false,
    search: {
        text: "",
        pathname: ""
    }
}

export const sharedSlice = createSlice({
    name: 'shared',
    initialState,
    reducers: {
        updateSearch: (state, action) => {
            return { ...state, search: action.payload }
        },
        toggleLoginModal: (state, action) => {
            return { ...state, showLoginModal: !state.showLoginModal }
        },
    },
})

export const { updateSearch, toggleLoginModal } = sharedSlice.actions;

export default sharedSlice.reducer;