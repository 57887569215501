import FlatList from "components/FlatList";
import NoData from "components/no-data";
import useSnackbar from "hooks/useSnackbar";
import React, { useEffect, useState } from "react";
import { Placeholder } from "react-bootstrap";
import Message from "./media/Message";

const Important = ({ socket, selectedClass = {} }) => {
  const { snackbar } = useSnackbar();
  const [importantList, setImportantList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchImportantList = () => {
      setLoading(true);
      socket.emit("getImportantMessages", { roomId: selectedClass?.roomId });
      socket.on("getImportantMessagesResponse", (data) => {
        console.log("🌹 ~ socket.on ~ data:", data);
        try {
          if (data?.status === 200) {
            setImportantList(data?.result || []);
          } else {
            snackbar(data?.message || "", "error");
          }
        } catch (e) {
          snackbar(e?.message || "", "error");
        } finally {
          setLoading(false);
        }
      });
    };
    if (selectedClass?.roomId) {
      fetchImportantList();
    }
    return () => {
      socket.off("getImportantMessages");
    };
    // eslint-disable-next-line
  }, [selectedClass?.roomId]);

  return (
    <>
      <div className="chatting">
        <FlatList
          data={importantList}
          renderItem={(list) => {
            return <Message key={list._id} {...{ message: list }} />;
          }}
          placeholder={<NoData />}
          loading={true}
          numLoading={4}
          loadingItem={(it) => {
            return (
              <div className="chat_receive_wrap loading" key={it}>
                <div className="chat_receive">
                  <div className="profile_dp">
                    <span className="profileName">
                      <Placeholder className="shimmer" />
                    </span>
                  </div>
                  <div className="_card">
                    <div className="_card_content">
                      <Placeholder className="shimmer" />
                      <Placeholder className="shimmer" />
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        />
      </div>
    </>
  );
};

export default Important;
