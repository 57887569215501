import React from "react";
import { countArray } from "utils/common";

const FlatList = ({
  data = [],
  loading = false,
  renderItem,
  loadingItem,
  numLoading = 5,
  placeholder = null,
}) => {
  return (
    <>
      {loading ? (
        <>
          {countArray(numLoading).map((item, index, arr) =>
            loadingItem(item, index, arr)
          )}
        </>
      ) : (
        <>
          {data?.length > 0
            ? data.map((item, index, arr) => renderItem(item, index, arr))
            : placeholder}
        </>
      )}
    </>
  );
};

export default FlatList;
