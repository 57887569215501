import "./Auth.scss";
import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { joiResolver } from "@hookform/resolvers/joi";
import { useMutation } from "react-query";

// Custom components
import { Input } from "components/form";
import { INDFlagIcon } from "assets/icons";
import { loginValidation } from "validations";
import useSnackbar from "hooks/useSnackbar";
import { AuthService } from "services";

const AuthLogin = ({ setCredentials, setLoginSteps }) => {
  const { snackbar } = useSnackbar();
  const { sendOtp } = AuthService();
  const [acceptCondition, setAcceptCondition] = useState(true);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(loginValidation),
    defaultValues: {
      mobile: "",
    },
  });

  const handleSendOtp = useMutation(
    (data) => {
      const payload = {
        countryCode: "+91",
        ...data,
      };
      setCredentials(payload);
      return sendOtp(payload);
    },
    {
      onSuccess: (data) => {
        setLoginSteps("otp");
        snackbar(data?.message || "", "info");
      },
      onError: (err) => {
        snackbar(err.data.message || "");
      },
    }
  );

  return (
    <>
      <div className="auth login">
        <h4>Login</h4>

        <form onSubmit={handleSubmit(handleSendOtp.mutate)}>
          <div className="form_field">
            <Input
              placeholder=" Enter India  Mobile Number"
              name="mobile"
              control={control}
              error={errors.mobile?.message}
              type="number"
              maxLength={10}
              className="mobileNumber"
            />
            <span className="country">
              <img src={INDFlagIcon} alt="" />
              +91
            </span>
          </div>
          <label className="checkbox_wrap">
            <input
              type="checkbox"
              onChange={(e) => setAcceptCondition(e.target.checked)}
              checked={acceptCondition}
            />
            I agree to{" "}
            <Link to="/terms-condition" target="_blank">
              {" "}
              Terms and Conditions{" "}
            </Link>{" "}
            and{" "}
            <Link to="/privacy-policy" target="_blank">
              {" "}
              Data Privacy policies.
            </Link>
          </label>

          <div className="button_wrap button_center">
            <button
              type="submit"
              className="button"
              disabled={handleSendOtp.isLoading || !acceptCondition}
            >
              {handleSendOtp.isLoading ? (
                <Spinner
                  animation="border"
                  role="status"
                  variant="primary"
                ></Spinner>
              ) : (
                "Login / Signup "
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AuthLogin;
