import axios from "axios";

import { BASE_URL, HEADERS } from "../endpoints";
import { encryptData, decryptData } from "utils/helper";
import useUser from "hooks/useUser";

const HttpService = () => {
    const { logout } = useUser()
    const httpRequest = (
        method,
        url,
        data = {},
        params,
    ) => new Promise((resolve, reject) => {
        data = encryptData(data);
        if(params){
            params = encryptData(params);
        }
        axios({
            method,
            url: `${BASE_URL}/${url}`,
            data: { reqData: data },
            params: { reqData: params },
            headers: HEADERS
        })
            .then((response) => {
                resolve(decryptData(response.data));
            })
            .catch((err) => {
                if (err.response?.status === 401 || err.response?.status === 403) {
                    logout();
                }
                if (err.response?.data) {
                    reject({ ...err.response, data: decryptData(err.response.data) });
                } else {
                    reject({ data: { message: err?.message || "Unknown Error Occurred" } });
                }
            });
    });

    const httpFormRequest = (
        method,
        url,
        data,
    ) =>
        new Promise((resolve, reject) => {
            axios(
                {
                    method,
                    url: `${BASE_URL}/${url}`,
                    data,
                    headers: {
                        ...HEADERS,
                        "Content-Type": "multipart/form-data"
                    }
                }
            )
                .then((response) => {
                    resolve(decryptData(response.data));
                })
                .catch((err) => {
                    if (err.response?.status === 401) {
                        localStorage.removeItem("token");
                    }
                    if (err.response?.data) {
                        reject({ ...err.response, data: decryptData(err.response.data) });
                    } else {
                        reject({ data: { message: err?.message || "Unknown Error Occurred" } });
                    }
                });
        });

    return { httpRequest, httpFormRequest };
};

export default HttpService;
