import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-circular-progressbar/dist/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import "./App.scss";

import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import Routing from "routing";
import ScrollToTop from "routing/ScrollTop";
import { UserProvider } from "hooks/useUser";
import InternetConnection from "components/internet-connection";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter base="/">
          <UserProvider>
            <InternetConnection>
              <ScrollToTop />
              <Routing />
            </InternetConnection>
          </UserProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
}

export default App;
