import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import HomePage from "pages/homePage/HomePage";
import StudentAccess from "pages/student-access/StudentAccess";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import AuthGuard from "./AuthGuard";
import DonationForm from "pages/donation-form/DonationForm";
import DonationTransaction from "pages/donation-form/DomationTransaction";
import ManageProfile from "pages/student-access/section/ManageProfile";
import AboutTeacher from "pages/about-teacher/AboutTeacher";
import Layout from "components/layout";
import MyProfile from "pages/student-access/section/MyProfile";
import MyClasses from "pages/student-access/my-classes/MyClasses";
import TeacherMyProfile from "pages/teacher-access/my-profile/TeacherProfile/TeacherProfile";
import useUser from "hooks/useUser";
import TeacherEditProfile from "pages/teacher-access/my-profile/TeacherProfile/EditProfile";
import TeacherAddressListing from "pages/teacher-access/my-profile/TeacherProfile/TeacherAddressListing";
import AddTeacherAddress from "pages/teacher-access/my-profile/TeacherProfile/TeacherAddressAdd";
import TeacherBankDetails from "pages/teacher-access/my-profile/TeacherProfile/TeacherBankDetail";
import TeacherAddBankDetails from "pages/teacher-access/my-profile/TeacherProfile/TeacherAddBankDetails";
import ProgressClasses from "pages/student-access/my-classes/sections/ProgressClasses";
import CompleteClasses from "pages/student-access/my-classes/sections/CompleteClasses";
import NotificationsList from "pages/notifications/Notifications";
import TermsCondition from "pages/CMSPages/TermsCondition";
import AboutUs from "pages/CMSPages/AboutUs";
import PrivacyPolicy from "pages/CMSPages/PrivacyPolicy";
import FAQPage from "pages/CMSPages/FAQPage";
import MyCart from "pages/myCart/MyCart";
import CartRegistration from "pages/myCart/CartRegistration";
import FailedRegistration from "pages/myCart/FailedRegistration";
import Help from "pages/student-access/section/Help";
import JobList from "pages/jobs/JobList";
import JobDetail from "pages/jobs/jobDetail";
import AboutMe from "pages/teacher-access/my-profile/TeacherProfile/AboutMe";
import ApplyForJob from "pages/jobs/ApplyForJob";
import CertificatesList from "pages/certificates/CertificatesList";
import ExperienceCertificate from "pages/teacher-access/my-profile/TeacherProfile/ExperienceCertificate";
import Signature from "pages/teacher-access/my-profile/TeacherProfile/Signature";
import TeacherManageProfile from "pages/teacher-access/my-profile/TeacherProfile/ManageProfile";
import StudentsFeedback from "pages/teacher-access/my-profile/TeacherProfile/StudentsFeedbacks";
import ChangeClass from "pages/student-access/my-classes/sections/ChangeCLass";
import ChangeProfileCourse from "pages/student-access/my-classes/sections/ChangeProfileCourse";
import Chats from "pages/chats/Chats";

const HeaderFooter = () => {
  return (
    <>
      <div className="home_page_wrap">
        <Header />
        <Outlet />
        <Footer />
      </div>
    </>
  );
};

const Routing = () => {
  const { isTeacher } = useUser();

  return (
    <>
      <Routes>
        <Route element={<HeaderFooter />}>
          {/* Public Routes */}
          <Route path="/" element={<HomePage />} />
          <Route path="/about-teacher" element={<AboutTeacher />} />
         

          {/* Protected Routes */}
          <Route element={<AuthGuard />}>
            <Route path="/donation" element={<DonationForm />} />
            <Route element={<Layout />}>
              <Route path="/home/about-teacher" element={<AboutTeacher />} />

              {/* My Classes */}
              <Route path="/my-classes" element={<MyClasses />}>
                <Route path="" element={<ProgressClasses />} />
                <Route path="completed" element={<CompleteClasses />} />
                <Route path="about-teacher" element={<AboutTeacher />} />

                <Route path="change-class" element={<ChangeClass />} />
                <Route
                  path="profile-course"
                  element={<ChangeProfileCourse />}
                />
              </Route>

              {/* Chat */}
              <Route path="/chat">
                <Route path="" element={<Chats />} />
              </Route>

              {/* Profile */}
              <Route path="/profile">
                <Route
                  index
                  element={<Navigate to={isTeacher ? "teacher" : "student"} />}
                />

                <Route path="student" element={<MyProfile />} />
                <Route
                  path="student/certificates"
                  element={<CertificatesList />}
                />
                <Route
                  path="teacher/certificates"
                  element={<CertificatesList />}
                />
                <Route
                  path="student/manage-profile"
                  element={<ManageProfile />}
                />

                {/* Teacher */}
                <Route path="teacher" element={<TeacherMyProfile />} />
                <Route
                  path="teacher/edit-profile"
                  element={<TeacherEditProfile />}
                />
                <Route
                  path="teacher/address"
                  element={<TeacherAddressListing />}
                />
                <Route
                  path="teacher/address/add"
                  element={<AddTeacherAddress />}
                />
                <Route
                  path="teacher/bank-detail"
                  element={<TeacherBankDetails />}
                />
                <Route
                  path="teacher/bank-detail/add"
                  element={<TeacherAddBankDetails />}
                />

                <Route path="teacher/about-me" element={<AboutMe />} />
                <Route
                  path="teacher/students-feedbacks"
                  element={<StudentsFeedback />}
                />
                <Route
                  path="teacher/manage-profile"
                  element={<TeacherManageProfile />}
                />
                <Route
                  path="teacher/experience-certificate"
                  element={<ExperienceCertificate />}
                />
                <Route path="teacher/signature" element={<Signature />} />
                <Route path="help" element={<Help />} />

                <Route
                  path="donation/transaction"
                  element={<DonationTransaction />}
                />
                <Route
                  path="teacher/terms-condition"
                  element={<TermsCondition />}
                />
                <Route
                  path="teacher/privacy-policy"
                  element={<PrivacyPolicy />}
                />
              </Route>

              <Route path="/cart" element={<MyCart />} />
              <Route path="/cart/registration" element={<CartRegistration />} />
            </Route>
            <Route path="/notifications" element={<NotificationsList />} />
          </Route>

          {/* <Route path="*" element={<Navigate to="/" />} /> */}
          <Route path="/terms-condition" element={<TermsCondition />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/faq" element={<FAQPage />} />

          <Route
            path="/cart/registration/failed"
            element={<FailedRegistration />}
          />
          <Route path="/jobs" element={<JobList />} />
          <Route path="/jobs/details" element={<JobDetail />} />
          <Route path="/jobs/apply" element={<ApplyForJob />} />
        </Route>
      </Routes>
    </>
  );
};

export default Routing;
