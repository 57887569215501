import { useContext, useEffect } from "react";
import { createContext, useState } from "react";
import { TOKEN, UserService } from "services";
import { useQuery } from 'react-query';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from "react-router-dom";


const LoaderContext = createContext({});

const defaultDetails = {
    _id: "",
    name: "",
    mobile: "",
    profile: ""
}

const UserProvider = ({ children }) => {
    const [user, setUserData] = useState(defaultDetails);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [enable, setEnable] = useState(false);
    const { userProfile } = UserService();
    useQuery({
        queryKey: ['userProfile'],
        queryFn: async () => userProfile({
            token: TOKEN
        }),
        enabled: enable,
        onError: () => {
            setLoading(false);
        },
        onSuccess: (data) => {
            setUser(data.data)
        },
        cacheTime: 0
    });

    const setUser = (data) => {
        if (data) {
            setUserData(prev => ({ ...prev, ...data }));
            setLoading(false);
        } else if (data === null) {
            setUserData(defaultDetails);
        }
    }

    const isLoggedIn = user._id.length ? true : false;
    const isTeacher = user?.roles?.includes("teacher") ? true : false;

    const logout = () => {
        setUserData(defaultDetails);
        localStorage.removeItem("fg_token");
        navigate("/");
    }

    useEffect(() => {
        if (TOKEN) {
            setEnable(true);
        } else {
            setLoading(false);
        }
    }, []);

    return (
        <LoaderContext.Provider value={{ user, setUser, isLoggedIn, isTeacher, logout }}>
            {
                loading
                    ?
                    <div style={{ width: "100%", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <Spinner animation="border" role="status" variant="primary"></Spinner>
                        <div>
                            Loading...
                        </div>
                    </div>
                    :
                    children
            }
        </LoaderContext.Provider>
    );
};

const useUser = () => useContext(LoaderContext);

export default useUser;
export { UserProvider };