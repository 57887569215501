import {
  calendarIcon,
  calenderCheckedIcon,
  chatIcon,
  editColorButton,
  languageIcon,
  medalIcon,
  monthIcon,
  userIcon,
  videoCameraIcon,
  watchIcon,
} from "assets/icons";
import { courseImg, ProfileImg } from "assets/images";
import React, { useEffect } from "react";
import { GoStarFill } from "react-icons/go";
import "../MyClasses.scss";
import Faqs from "components/faqs/FAQs";
import {
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { AttendanceService, ClassService } from "services";
import Skeleton from "components/skeleton";
import NoData from "components/no-data";
import { generateFormDataPayload, get12HourTime } from "utils/helper";
import moment from "moment";
import { updateSearch, useAppDispatch, useAppSelector } from "../../../../redux";
import useUser from "hooks/useUser";
import TypographyLimit from "components/TypographyLimit";
import ClassProcess from "components/class-process";

const ProgressClasses = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { getMyClassList } = ClassService();
  const { addAttendance } = AttendanceService();
  const search = useAppSelector(state => state.sharedSlice.search);

  const { data: classList, isLoading } = useQuery({
    queryKey: ["my-class-list", search.text],
    queryFn: async () =>
      getMyClassList({
        token: localStorage.getItem("fg_token"),
        type: "inProgress",
        ...(search.text?.length && { search: search.text })
      }),
    cacheTime: 0
  });

  const markAttendance = useMutation(data => {
    console.log({ data });

    const payload = generateFormDataPayload({
      ...data,
      type: "inProgress",
      token: localStorage.getItem("fg_token")
    })
    return addAttendance(payload);
  }, {
    onSuccess: (data) => {
      console.log({ data });
    }
  });

  const getTrainerName = (teachers) => {
    if (Array.isArray(teachers) && teachers?.length) {
      const teacher = teachers.find((teacher) => teacher.isPrimary);
      return teacher;
    }

    return null;
  };

  const handleNavigation = (search, classDetail) => {
    navigate(
      {
        pathname: "/my-classes/about-teacher",
      },
      {
        state: {
          ...search,
          classTitle: classDetail?.classTitleEng,
          currentMenuTab: "progress",
          backUrl: location.pathname,
          isTeacher: classDetail?.role === "teacher" ? true : false
        },
      }
    );
  };

  useEffect(() => {
    return () => {
      dispatch(updateSearch({
        text: "",
        pathname: ""
      }));
    }
  }, []);

  return (
    <div>
      <div className="classes_card_wrap spacing">
        {isLoading ? (
          [1, 2].map(() => (
            <div className="item">
              <div className="class_detail_wrap">
                <div className="class_detail">
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton button />
                </div>
              </div>
            </div>
          ))
        ) : classList && classList.data?.data?.length ? (
          classList.data?.data?.map((classDetail) => {
            const isTeacher = classDetail?.role === "teacher";
            return (
              <div className="item">
                <div className="class_detail_wrap">
                  <div className="class_detail">
                    <ul className="class_Feature">
                      <li className="blue">
                        {get12HourTime(classDetail?.time)}
                      </li>
                      <li className="green">
                        {moment(classDetail?.batchInfo?.batchStart).format(
                          "MMMM YYYY"
                        )}
                      </li>
                      {classDetail?.new ? <li className="red">New</li> : null}
                      {classDetail?.isAdvanced ? (
                        <li className="purple">Advanced</li>
                      ) : null}
                      <li className="pink">{classDetail?.group}</li>
                    </ul>
                    <div className="course_name">
                      <img
                        src={
                          classDetail?.icon?.length
                            ? classDetail.icon
                            : courseImg
                        }
                        alt=""
                      />
                      <div className="content">
                        <h4><TypographyLimit text={classDetail?.classTitleEng} limit={30} /> </h4>
                        <p><TypographyLimit text={classDetail?.tagline} limit={30} />  </p>
                        <ul>
                          <li className="_rating">
                            {getTrainerName(classDetail?.teachers) ? <span>{getTrainerName(classDetail?.teachers)?.averageRating ? Number(getTrainerName(classDetail?.teachers)?.averageRating).toFixed(2) : "0"}</span> : null}
                            <GoStarFill />
                            {getTrainerName(classDetail?.teachers) ? <span>({getTrainerName(classDetail?.teachers)?.totalReviews ? getTrainerName(classDetail?.teachers)?.totalReviews : "0"})</span> : null}
                          </li>
                          {getTrainerName(classDetail?.teachers) ? (
                            <li className="trainer">
                              <span>Trainer: </span>{" "}
                              <b> {getTrainerName(classDetail?.teachers)?.name || ""}</b>
                            </li>
                          ) : null}
                        </ul>
                      </div>
                    </div>
                    <ul className="course_info">
                      <li>
                        <img src={userIcon} alt="" />
                        {classDetail?.age} yrs
                      </li>
                      <li>
                        <img src={calendarIcon} alt="" />
                        {
                          classDetail?.days.map((day) => moment(day, "dddd").format("ddd"))
                            .join(", ")
                        }
                      </li>
                      <li>
                        <img src={medalIcon} alt="" />
                        Certificate
                      </li>
                      {
                        classDetail?.language
                          ?
                          <li>
                            <img src={languageIcon} alt="" />
                            {classDetail?.language ? classDetail?.language?.join("/") : ""}
                          </li>
                          :
                          null
                      }
                      <li>
                        <img src={calenderCheckedIcon} alt="" />
                        {moment(classDetail?.batchInfo?.batchStart).format(
                          "Do MMMM"
                        )}
                      </li>
                      <li>
                        <img src={monthIcon} alt="" />
                        Month
                      </li>
                      <li>
                        <img src={videoCameraIcon} alt="" />
                        Live
                      </li>
                      <li>
                        <img src={watchIcon} alt="" />
                        {get12HourTime(classDetail?.time)}
                      </li>
                      <li>
                        <img src={chatIcon} alt="" />
                        Chat
                      </li>
                    </ul>

                    <div className="button_wrap">
                      <button
                        // onClick={
                        // ["Holiday", "Started", "Not Started", "Leave",].
                        //   () =>
                        //   classDetail.sessionStatus === "start"
                        //     ? classDetail.zoomLink
                        //     : undefined
                        // }
                        disabled={
                          (classDetail.sessionStatus === "Started") ?
                            (moment().isSameOrAfter(moment(classDetail?.time, "HH:mm")) && moment().isSameOrBefore(moment(classDetail?.time, "HH:mm").add(classDetail?.zoomLock, "minutes")))
                              ? false :
                              true
                            : true
                        }
                        onClick={() => {
                          if (classDetail.sessionStatus === "Started") {
                            const status = classDetail.sessionStatus === "Started" ? "Present" : classDetail.sessionStatus;
                            markAttendance.mutate({ classId: classDetail?._id, status });
                          }
                        }}
                        className="button join"
                      >
                        {["Holiday", "Leave"].includes(classDetail.sessionStatus) ? (
                          <span className="tag">Today {classDetail.sessionStatus}</span>
                        ) : null}
                        {classDetail?.role === "student" ? "Join" : "Start"} LIVE Class @ {get12HourTime(classDetail?.time)}
                      </button>
                      <button type="button" className="button chat">
                        Chat
                      </button>
                    </div>

                    <ul className="urls">
                      {classDetail?.aboutTeacher ? (
                        <li
                          onClick={() =>
                            handleNavigation({
                              currentTab: "teacher",
                              classId: classDetail._id,
                              certificate: classDetail.certificate,
                            }, classDetail)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          About Teacher
                        </li>
                      ) : null}
                      {classDetail?.syllabus?.isSyllabus ? (
                        <li
                          onClick={() =>
                            handleNavigation({
                              currentTab: "Syllabus",
                              classId: classDetail._id,
                              certificate: classDetail.certificate,
                            }, classDetail)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          Syllabus
                        </li>
                      ) : null}
                      {classDetail?.studentWorkes?.isStudentWorkes ? (
                        <li
                          onClick={() =>
                            handleNavigation({
                              currentTab: "Student",
                              classId: classDetail._id,
                              certificate: classDetail.certificate,
                            }, classDetail)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          Student Works
                        </li>
                      ) : null}
                      {classDetail?.requiredTools?.isRequiredTools
                        ?
                        <li
                          onClick={() =>
                            handleNavigation({
                              currentTab: "Required",
                              classId: classDetail._id,
                              certificate: classDetail.certificate,
                            }, classDetail)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          Required Tools
                        </li>
                        :
                        null
                      }
                      {
                        (classDetail?.studyMaterial?.isStudyMaterial)
                          ?
                          <li
                            onClick={() =>
                              handleNavigation({
                                currentTab: "study-material",
                                classId: classDetail._id,
                                certificate: classDetail.certificate,
                              }, classDetail)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            Study Material
                          </li>
                          :
                          null
                      }

                      {/* Students Button */}
                      {
                        (!isTeacher && classDetail?.studentGuidelines)
                          ?
                          <li
                            onClick={() =>
                              handleNavigation({
                                currentTab: "student-guideline",
                                classId: classDetail._id,
                                certificate: classDetail.certificate,
                              }, classDetail)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            Student Guidelines
                          </li>
                          :
                          null
                      }
                      {
                        (!isTeacher)
                          ?
                          <li
                            onClick={() =>
                              handleNavigation({
                                currentTab: "my-attendance",
                                classId: classDetail._id,
                                certificate: classDetail.certificate,
                              }, classDetail)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            My Attendance
                          </li>
                          :
                          null
                      }
                      {
                        (!isTeacher)
                          ?
                          <li
                            onClick={() =>
                              handleNavigation({
                                currentTab: "my-work",
                                classId: classDetail._id,
                                certificate: classDetail.certificate,
                              }, classDetail)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            My Works
                          </li>
                          :
                          null
                      }
                      {
                        (!isTeacher && classDetail?.certificate)
                          ?
                          <li
                            onClick={() =>
                              handleNavigation({
                                currentTab: "download-certificate",
                                classId: classDetail._id,
                                certificate: classDetail.certificate,
                              }, classDetail)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            Download Certificate
                          </li>
                          :
                          null
                      }
                      {
                        (!isTeacher)
                          ?
                          <li
                            onClick={() =>
                              handleNavigation({
                                currentTab: "give-feedback",
                                classId: classDetail._id,
                                certificate: classDetail.certificate,
                              }, classDetail)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            Give Feedback
                          </li>
                          :
                          null
                      }


                      {/* Teachers Button  */}
                      {
                        (isTeacher && classDetail?.teacherGuidelines)
                          ?
                          <li
                            onClick={() =>
                              handleNavigation({
                                currentTab: "student-guideline",
                                classId: classDetail._id,
                                certificate: classDetail.certificate,
                              }, classDetail)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            Teacher Guideline
                          </li>
                          :
                          null
                      }
                      {
                        (isTeacher)
                          ?
                          <li
                            onClick={() =>
                              handleNavigation({
                                currentTab: "Attendance",
                                classId: classDetail._id,
                                certificate: classDetail.certificate,
                              }, classDetail)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            Attendance
                          </li>
                          :
                          null
                      }
                      {
                        (isTeacher)
                          ?
                          <li
                            onClick={() =>
                              handleNavigation({
                                currentTab: "zoom-link",
                                classId: classDetail._id,
                                certificate: classDetail.certificate,
                              }, classDetail)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            Zoom link
                          </li>
                          :
                          null
                      }
                      {
                        (isTeacher)
                          ?
                          <li
                            onClick={() =>
                              handleNavigation({
                                currentTab: "students-list",
                                classId: classDetail._id,
                                certificate: classDetail.certificate,
                              }, classDetail)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            Student List
                          </li>
                          :
                          null
                      }
                      {
                        (isTeacher)
                          ?
                          <li
                            onClick={() =>
                              handleNavigation({
                                currentTab: "apply-leave",
                                classId: classDetail._id,
                                certificate: classDetail.certificate,
                              }, classDetail)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            Apply Leave
                          </li>
                          :
                          null
                      }
                      {
                        (isTeacher)
                          ?
                          <li
                            onClick={() =>
                              handleNavigation({
                                currentTab: "call-principal",
                                classId: classDetail._id,
                                certificate: classDetail.certificate,
                              }, classDetail)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            Call Principal
                          </li>
                          :
                          null
                      }

                    </ul>



                    <div className="profile_name">
                      {
                        (!isTeacher)
                          ?
                          <div className="profile_content">
                            <h6>
                              <span>Profile Name: </span> <b>{classDetail?.profileInfo?.name ?? user.name}</b>
                            </h6>
                            <p>
                              <img src={editColorButton} alt="" />
                              <span>
                                If you wish to ASSIGN this class on your
                                son,daughter, mother,father name to get certificate,
                                then{" "}
                                <Link
                                  to="/my-classes/profile-course"
                                  state={{ classId: classDetail._id, profileId: classDetail?.profileInfo?._id }}
                                  style={{ color: "inherit" }}
                                >
                                  click HERE
                                </Link>
                              </span>
                            </p>

                          </div>
                          :
                          null
                      }
                      {
                        (!isTeacher)
                          ?
                          <div className="profile_image">
                            <img style={{ height: "53px", borderRadius: "12px" }} src={classDetail?.profileInfo?.profile ? classDetail?.profileInfo?.profile : user?.profile ? user?.profile : ProfileImg} alt="" />
                          </div>
                          :
                          null
                      }
                    </div>

                    {
                      (!isTeacher && moment().isSameOrBefore(moment(classDetail?.batchInfo?.classChangeBefore)))
                        ?
                        <p
                          className="text-center"
                          style={{ color: "#000", margin: 0, fontWeight: 500 }}
                        >
                          {" "}
                          If you wish to change class by {moment(classDetail?.batchInfo?.classChangeBefore).format('Do')},{" "}
                          <Link to="/my-classes/change-class" state={{ classId: classDetail._id, classFees: classDetail.fees }}>CLICK HERE</Link>{" "}
                        </p>
                        :
                        null
                    }
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <>
            <NoData
              title="It looks, You haven't registered class!"
              desc="Please follow the below process to registered a class"
            />
            <ClassProcess />
          </>
        )}
      </div>

      <Faqs screenType="myClasses" />
    </div>
  );
};

export default ProgressClasses;
