import React from "react";
import { heartIcon } from "assets/icons";
import { Button, Input } from "components/form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useForm } from 'react-hook-form';
import { useMutation } from "react-query";

import { donation80G } from "validations";
import { DonationService } from "services";
import useSnackbar from "hooks/useSnackbar";
import { generateFormDataPayload } from "utils/helper";


const Donation80G = () => {
  const { snackbar } = useSnackbar();
  const { createDonation } = DonationService();
  const { control, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: joiResolver(donation80G),
    defaultValues: {
      name: "",
      amount: "",
      phone: "",
      email: "",
      panAadharPassport: "",
      address: "",
      pincode: "",
    }
  });

  const handleDonation = useMutation(data => {
    const payload = {
      donationType: "8OG",
      platformType: "web",
      token: localStorage.getItem("fg_token"),
      ...data
    }
    return createDonation(generateFormDataPayload(payload))
  }, {
    onSuccess: (data) => {
      snackbar((data?.message || ""), "info");
      reset();

    },
    onError: (err) => {
      snackbar((err.data.message || ""));
    }
  });

  return (
    <>
      <form onSubmit={handleSubmit(handleDonation.mutate)}>
        <Input placeholder="Amount(In Rupees)" type="number" name="amount" control={control} error={errors.amount?.message} />
        <Input placeholder="Full Name" name="name" control={control} error={errors.name?.message} />
        <Input placeholder="Phone" name="phone" type="number" maxLength={10} control={control} error={errors.phone?.message} />
        <Input placeholder="Email" name="email" control={control} error={errors.email?.message} />
        <Input placeholder="PAN/Aadhar/Passport/Driving License" name="panAadharPassport" control={control} error={errors.amount?.message} />
        <Input placeholder="Address" name="address" control={control} error={errors.address?.message} />
        <Input placeholder="Pincode" type="number" name="pincode" control={control} error={errors.pincode?.message} />

        <div className="button_wrap button_center">
          <Button type="submit">
            <img src={heartIcon} alt="" />
            DONATE
          </Button>
        </div>
      </form>
    </>
  );
};

export default Donation80G;
