import { joiResolver } from "@hookform/resolvers/joi";
import { videoCameraIcon } from "assets/icons";
import DocUpload from "components/docUploader/DocUploader";
import { LoadingButton, RatingStar } from "components/form";
import DeleteModal from "components/modals/DeleteModal";
import useSnackbar from "hooks/useSnackbar";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaRegEdit } from "react-icons/fa";
import { FaRegTrashCan } from "react-icons/fa6";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { RatingService } from "services";
import { formatDate, generateFormDataPayload } from "utils/helper";
import { ratingValidation } from "validations";

const GiveFeedback = ({ classId, review }) => {
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();
  const { addRating, deleteRating } = RatingService();
  const [deletePost, setDeletePost] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(ratingValidation),
    defaultValues: {
      rating: "",
      comment: "",
    },
  });
  const rating = watch("rating");
  const video = watch("video");

  const markAttendance = useMutation(
    (data) => {
      const payload = generateFormDataPayload(
        {
          ...data,
          classId,
          token: localStorage.getItem("fg_token"),
        },
        "video"
      );
      return addRating(payload);
    },
    {
      onSuccess: (data) => {
        navigate("/my-classes");
        snackbar(data?.message || "", "info");
      },
    }
  );

  const deleteRatingMutation = useMutation(
    (data) => {
      const payload = {
        ratingId: review?._id,
        token: localStorage.getItem("fg_token"),
      };
      return deleteRating(payload);
    },
    {
      onSuccess: (data) => {
        navigate("/my-classes");
        snackbar(data?.message || "", "info");
      },
    }
  );

  return (
    <>
      {/* Feedback Preview */}
      {review ? (
        <div className="feedback_form preview">
          <div className="head">
            <h5>Date {formatDate(review?.createdAt)}</h5>
            <div className="button_wrap">
              {/* <button type="button" className="edit">
              <FaRegEdit />
            </button> */}
              <button type="button" onClick={() => setDeletePost(true)}>
                <FaRegTrashCan />
              </button>
            </div>
          </div>
          <ul>
            <li>
              <RatingStar rating={review.rating} readonly />
            </li>
            <li>{review.comment}</li>
            <li>
              <video
                src={review.video}
                source={videoCameraIcon}
                type="video/mp4"
                controls
              />
            </li>
          </ul>
          <p className="note">
            Note: One time only allowed to submit feedback. if you wish to
            submit again, delete it.
          </p>
        </div>
      ) : (
        <form
          className="feedback_form"
          onSubmit={handleSubmit(markAttendance.mutate)}
        >
          <div className="form_field">
            <label className="label">Feedback to teacher in rating</label>
            <RatingStar
              rating={rating}
              onChange={(value) => setValue("rating", value)}
            />
          </div>

          <div className="form_field">
            <label className="label">Feedback to teacher in Text</label>
            <textarea
              className="input"
              placeholder="Message"
              {...register("comment")}
            />
          </div>
          <div className="form_field">
            <label className="label">Feedback to teacher in Video</label>
            <DocUpload
              accept={"video/*"}
              fileName={video?.name ? video?.name : "Upload an  Video"}
              onChange={(e) => setValue("video", e.target.files[0])}
            />
          </div>
          <div className="button_wrap">
            <LoadingButton
              loading={markAttendance.isLoading}
              disabled={markAttendance.isLoading}
              type="submit"
              className="button"
            >
              Submit
            </LoadingButton>
          </div>
        </form>
      )}
      <DeleteModal
        show={deletePost}
        handleClose={() => setDeletePost(false)}
        handleSubmit={deleteRatingMutation.mutate}
        content={"Are you sure you want to delete?"}
      />
    </>
  );
};

export default GiveFeedback;
